import React, { useContext, useEffect } from "react";
import { AppContext } from "../context/AppContext";
import { Container, Row, Col } from 'react-bootstrap';

const NotFound = () => {
  const { setBackgroundClassList } = useContext(AppContext);

  useEffect(() => {
    setBackgroundClassList(["background-1", "bg-cover"]);
  });
  return (
    <Container fluid='md mt-5'>
      <Row>
        <Col>
          <div className='card'>
            <div className='card-header '>503 - Serviço temporariamente indísponivel</div>
            <div className='card-body'>
            <Row>
                <Col className="d-flex flex-column text-center">
                  <h5 className='card-title align-self-center'>O portal está em manuntenção!!</h5>
                  <p className='card-text text-center p-0 m-0'>Estaremos de volta em breve! Enquanto isso, confira a seção informativa do portal retornando à <a href="/">página inicial.</a></p>
                </ Col>
              </Row>
              {/**<LinkContainer to='/'>
                <button className='btn btn-primary'>Ir para o Início</button>
  </LinkContainer>**/}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  )
}
export default NotFound