import React, { useContext, useEffect } from "react";
import { Col, Container, Form, Row, Button } from "react-bootstrap";
import { AppContext } from "../context/AppContext";
import TextInput from "../components/UI/Formik/TextInput/TextInput";
import useApiClient, { apiRoutes } from "../hooks/apiClient";
import * as yup from "yup";
import { useFormik } from "formik";
import { useHistory } from "react-router";
import SpinnerLabel from "../components/UI/SpinnerLabel";
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer, Bounce } from "react-toastify";

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email("E-mail inválido, verifique seu email.")
    .required("Informe um e-mail válido."),
});

const initialValues = {
  email: "",
};

const PaginaEnvioLinkRedefinirSenha = () => {
  const { setBackgroundClassList } = useContext(AppContext);
  const { request, data, errors, loading } = useApiClient();
  const history = useHistory();

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: (values, actions) => {
      request(apiRoutes.main.usuario.solicitar, values);
    },
  });

  const { setSubmitting } = formik;

  useEffect(() => {
    if (data) {
      toast.success('Enviamos um e-mail com as instruções para redefinição de senha. Por favor, verifique sua caixa de entrada.', {
        position: "top-center",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
        onClose: () => {
          setSubmitting(false);
          history.push("/login");
        }
      });}
    
    if (errors) {
      toast.error(errors.message || "Oops, algo deu errado, tente novamente mais tarde.", {
        position: "top-center",
        autoClose: 7000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
        onClose: () => {
          setSubmitting(false);
        }
        });
    }

    setSubmitting(false);
  }, [data, errors, setSubmitting, history]);

  const readOnly = loading || formik.isSubmitting;

  useEffect(() => {
    setBackgroundClassList(["bg-half", "background-1"]);
  });

  return (
    <>
      <Container id="cabecalho-pagina">
        <Row>
          <Col className="d-flex flex-column text-center mt-5">
            <h1 className="text-white text-shadow-orange mt-5">
              Redefinir Senha
            </h1>
          </Col>
        </Row>
      </Container>
      <div id="conteudo-pagina-w100">
        <Container>
          <Row>
            <Col className="d-flex justify-content-center">
              <Col className="col-md-6 p-2">
                <Form onSubmit={formik.handleSubmit}>
                  <Col lg="12">
                    <p className="text-center">
                      Para redefinir sua senha digite seu e-mail no campo
                      abaixo. Enviaremos para seu e-mail um link com os próximos
                      passos para redefinir sua senha.
                    </p>
                  </Col>

                  <Col lg="12">
                    <TextInput
                      formik={formik}
                      field="email"
                      label="E-mail:"
                      placeholder="Informe o seu e-mail"
                      readOnly={readOnly}
                      disabled = {formik.isSubmitting}
                    />
                  </Col>
                  <Col lg="10" md="6" sm="12" xs="12" className="d-flex justify-content-center mt-5">
                    <Button
                      variant="primary"
                      onClick={formik.handleSubmit}
                      disabled = {formik.isSubmitting}
                    >
                      <SpinnerLabel label="Enviar" loading={readOnly} />
                    </Button>
                  </Col>
                </Form>
              </Col>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default PaginaEnvioLinkRedefinirSenha;
