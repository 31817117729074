import { useFormik } from "formik";
import React, { useEffect } from "react";
import { Container, Row, Col, Alert, Table, OverlayTrigger, Tooltip } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useHistory, useParams } from "react-router-dom";
import * as yup from "yup";
import SelectInputCached from "../../../components/UI/Formik/SelectInput/SelectInput.cached";
import TextareaInput from "../../../components/UI/Formik/TextareaInput/TextareaInput";
import SpinnerLabel from "../../../components/UI/SpinnerLabel";
import useApiClient, { apiRoutes } from "../../../hooks/apiClient";
import TextInput from "../../../components/UI/Formik/TextInput/TextInput";
import { toast, ToastContainer, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GrDownload } from "react-icons/gr";
import Inputmask from "inputmask";
import appConfig from "../../../config/app.config";

const validationSchema = yup.object().shape({
  IdAndamentoProposta: yup.string().required("Informe um status para a proposta."),
  JustificativaAndamento: yup.string().required("Informe uma justificativa de acordo com o andamento escolhido."),
});

const initialValues = {
  IdProposta: "",
  IdAndamentoProposta: "",
  JustificativaAndamento: "",

};

const PropostaDarAndamento = () => {
  const { request, loading, requestId, data, errors } = useApiClient();
  const history = useHistory();
  const { id } = useParams();
  initialValues.IdProposta = id;

  const handleSubmitAndamento = (values) => {
    request(apiRoutes.main.propostas.propostaDarAndamento, values, {
      requestId: "salvar",
    });
  };

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: handleSubmitAndamento,
  });

  useEffect(() => {
    if (id) {
      request(
        apiRoutes.main.propostas.listarPorID,
        { routeParams: { id } },
        { requestId: "carregar" }
      );
    }
  }, [request, id]);

  const { setSubmitting, isSubmitting } = formik;

  useEffect(() => {
    if (data && requestId === "carregar") {
      formik.setValues(() => ({
        IdArea: data.id_area,
        Area: data.outrasareas,
        Titulo: data.tx_titulo,
        Descricao: data.tx_descricao,
        InteresseSocial: data.tx_interesse,
        Diagnostico: data.tx_diagnostico,
        Viabilidade: data.tx_viabilidade,
        Beneficios: data.tx_beneficios,
        EstimativaMesesPrazo: data.nu_estimativa_prazo,
        EstimativaCusto: data.vl_estimativa_custo,
        Arquivo: data.bl_arquivo,
        ArquivoNome: data.nm_arquivo,
        Nome: data.tx_nome,
        CPF: data.tx_cpf,
        CEP: data.tx_cep,
        Email: data.tx_login,
        TelefonePrincipal: data.tx_tel1,
        TelefoneSecundário: data.tx_tel2,
        Logradouro: data.tx_logradouro,
        Numero: data.tx_numero,
        Cidade: data.tx_cidade,
        Bairro: data.tx_bairro,
        IdProposta: id,
      }));
    }

  }, [id, requestId, data]);


  const handleDownloadFile = (IdAquivo, NomeArquivo) => {
    fetch(`${appConfig.host}/api/proposta/arquivo/download/${IdAquivo}`)
      .then((res) => res.blob())
      .then((data) => {
        var a = document.createElement("a");
        a.href = window.URL.createObjectURL(data);
        a.download = NomeArquivo;
        a.click();
      });
  }


  useEffect(() => {

    const CEPMask = Inputmask({
      mask: "99999-999",
      placeholder: " ",
      numericInput: true,
      rightAlign: false
    });


    const QuantidadeDeMessesMask = Inputmask({
      alias: "numeric",
      suffix: " Meses",
      digits: 0,
      radixPoint: " ",
      numericInput: true,
      leftAlign: true,
    });

    const valorMonetarioMask = Inputmask({
      alias: "numeric",
      prefix: "R$ ",
      radixPoint: ",",
      digits: 2,
      rightAlign: false,
      positionCaretOnClick: "none",
      numericInput: true,
      allowMinus: false,
      groupSeparator: ".",
    });

    const telefoneMask = Inputmask({
      mask: ["(99) 9999-9999", "9999-9999"],
      placeholder: " ",
      numericInput: true,
    });


    QuantidadeDeMessesMask.mask(document.getElementById("EstimativaMesesPrazo"));
    valorMonetarioMask.mask(document.getElementById("EstimativaCusto"));
    CEPMask.mask(document.getElementById("CEP"));
    telefoneMask.mask(document.getElementById("TelefonePrincipal"));
    telefoneMask.mask(document.getElementById("TelefoneSecundário"));

  }, []);


  useEffect(() => {
    if (data && requestId === "salvar") {
      toast.success("Andamento feito com sucesso!!!!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
        onClose: () => {
          setSubmitting(false);
          window.location.reload();
        }
      });
    }

    if (errors) {
      toast.error(errors.message || "algo deu errado!!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
        onClose: () => {
          setSubmitting(false);
          window.location.reload();

        }
      });

    }
  }, [data, errors, history, setSubmitting]);



  const readOnly = loading


  return (
    <>
      <Container id="cabecalho-pagina" fluid>
        <Row>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
          <Col className="d-flex flex-column text-center mt-5">
            <h1 className="text-white text-shadow-orange mt-5">Manifestação de interesse Social</h1>
          </Col>
        </Row>
      </Container>
      <Container id="conteudo-pagina-interna">
        <Row>
          <Col className="mt-5">
            <Row className="d-flex g-4">
              <Row>
                <Col md="12" sm="12" xs="12">
                  <h3 className="my-3">Dados do proponente</h3>
                </Col>
                <Col className="mt-4" lg="12" md="12" sm="12" xs="12">
                  <TextInput formik={formik} field="Nome" label="Nome do proponente:" disabled={true} />
                </Col>
                <Col className="mt-4" lg="6" md="6" sm="12" xs="12">
                  <TextInput formik={formik} field="CPF" label="CPF do proponente:" disabled={true} />
                </Col>
                <Col className="mt-4" lg="6" md="6" sm="12" xs="12">
                  <TextInput formik={formik} field="CEP" label="CEP do proponente:" disabled={true} />
                </Col>
                <Col className="mt-4" lg="12" md="12" sm="12" xs="12">
                  <TextInput formik={formik} field="Email" label="E-mail do proponente:" disabled={true} />
                </Col>
                <Col className="mt-4" lg="6" md="6" sm="12" xs="12">
                  <TextInput formik={formik} field="TelefonePrincipal" label="Telefone Principal do Proponente:" disabled={true} />
                </Col>
                <Col className="mt-4" lg="6" md="6" sm="12" xs="12">
                  <TextInput formik={formik} field="TelefoneSecundário" label="Telefone Secundário do Proponente:" disabled={true} />
                </Col>

                <Col className="mt-3" lg="10" md="10" sm="12" xs="12">
                  <TextInput formik={formik} field="Logradouro" label="Logradouro:" disabled={true} />
                </Col>

                <Col className="mt-3" lg="2" md="2" sm="12" xs="12">
                  <TextInput formik={formik} field="Numero" label="Número:" disabled={true} />
                </Col>

                <Col className="mt-3" lg="6" md="6" sm="12" xs="12">
                  <TextInput formik={formik} field="Cidade" label="Cidade:" disabled={true} />
                </Col>
                <Col className="mt-3" lg="6" md="6" sm="12" xs="12">
                  <TextInput formik={formik} field="Bairro" label="Bairro:" disabled={true} />
                </Col>
              </Row>
              <Col md="12" sm="12" xs="12">
                <h3 className="my-3">Dados da proposta</h3>
              </Col>
              <Col lg="12" md="12" sm="12" xs="12">
                <TextInput formik={formik} field="Titulo" label="Título da Proposta:" disabled={true} />
              </Col>
              <Col lg="12" md="12" sm="12" xs="12">
                <TextareaInput
                  formik={formik}
                  field="Descricao"
                  label="Descrição Resumida da Proposta:"
                  rows={4}
                  disabled={true}
                />
              </Col>

              <Col lg="12" md="12" sm="12" xs="12">
                <SelectInputCached
                  formik={formik}
                  field="IdArea"
                  label="Área de Atuação"
                  defaultText="Selecione uma área de atuação:"
                  cacheKey="areas"
                  rows={4}
                  disabled={true}

                />
              </Col>
              {formik.values.Area && (
                <Col lg="12" md="12" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    field="Area"
                    label="Área de atuação especificada pelo usuário:"
                    disabled={true}
                    rows={4}
                    maxLength={250}
                  />
                </Col>
              )}

              <Col lg="12" md="12" sm="12" xs="12">
                <TextareaInput
                  formik={formik}
                  field="InteresseSocial"
                  label="Indicação do interesse público envolvido:"
                  placeholder="Descreva aqui"
                  rows={4}
                  disabled={true}
                />
              </Col>

              <Col lg="12" md="12" sm="12" xs="12">
                <TextareaInput
                  formik={formik}
                  field="Diagnostico"
                  label="Diagnóstico da realidade que se quer modificar, aprimorar
                        ou desenvolver:"
                  placeholder="Descreva aqui"
                  rows={4}
                  disabled={true}
                />
              </Col>

              <Col lg="12" md="12" sm="12" xs="12">
                <TextareaInput
                  formik={formik}
                  field="Viabilidade"
                  label="Indicação da viablidade:"
                  placeholder="Descreva aqui"
                  rows={4}
                  disabled={true}
                />
              </Col>

              <Col lg="12" md="12" sm="12" xs="12">
                <TextareaInput
                  formik={formik}
                  field="Beneficios"
                  label="Indicação dos beneficios:"
                  placeholder="Descreva aqui"
                  rows={4}
                  disabled={true}
                />
              </Col>

              <Col lg="6" md="6" sm="12" xs="12">
                <TextInput
                  formik={formik}
                  field="EstimativaCusto"
                  label="Estimativa de custo:"
                  disabled={true}
                />
              </Col>

              <Col lg="6" md="6" sm="12" xs="12">
                <TextInput
                  formik={formik}
                  field="EstimativaMesesPrazo"
                  label="Estimativa do Prazo de entrega:"
                  placeholder="Informe a quandidade de messes nesseários para concluir seu projeto."
                  disabled={true}
                />
              </Col>

              {data && data.nm_arquivo && (
                <Row >
                  <Col className="mt-5" lg="12" md="12" sm="12" xs="12">
                    <div style={{ overflowX: 'auto' }}>
                      <Table striped bordered hover>

                        <thead>
                          <tr >
                            <th>Arquivo</th>
                            <th className="text-center align-middle">Baixar</th>
                          </tr>
                        </thead>

                        <tbody>

                          <tr>
                            <td>
                              {data.nm_arquivo}
                            </td>
                            <td className="text-center align-middle">
                              <GrDownload
                                size={20}
                                color="orange"
                                onClick={() => handleDownloadFile(data.idarquivo, data.nm_arquivo)}
                                style={{ cursor: "pointer" }}
                              />
                            </td>
                          </tr>

                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>)}

              <Row>
                <Row className="mt-5">
                  <Col lg="12" md="12" sm="12" xs="12">
                    <h3 className="my-3">Dar Andamento</h3>
                  </Col>
                  <Col lg="12" md="12" sm="12" xs="12">
                    <p>
                      Após revisar os dados da proposta, você estará apto a dar prosseguimento à mesma. Para tal, basta selecionar sua decisão (rejeitada, em análise ou aceita) e adicionar uma descrição.
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col className="mt-4" lg="12" md="12" sm="12" xs="12">
                    <SelectInputCached
                      formik={formik}
                      field="IdAndamentoProposta"
                      label="Selecione o andamento"
                      defaultText="Selecione um andamento"
                      cacheKey="proposta"
                      isPropostaAndamento
                      disabled={isSubmitting}
                    />
                  </Col>
                  <Col className="mt-4" lg="12" md="12" sm="12" xs="12">
                    <TextareaInput
                      formik={formik}
                      field="JustificativaAndamento"
                      label="Informe uma descrição para seu andamento:"
                      disabled={isSubmitting}
                      placeholder="Descreva aqui a justificativa da sua decisão."
                    />
                  </Col>
                </Row>

              </Row>


            </Row>
            <Row>
              <Col>
                <Row>
                  <Col className="d-flex justify-content-center mt-5">
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip className="medium mt-1">Dar andamento à proposta</Tooltip>}>
                      <Button
                        className="mt-3"
                        onClick={() => formik.handleSubmit()}
                        disabled={isSubmitting}
                      >
                        <SpinnerLabel loading={formik.isSubmitting} label="Dar andamento" />

                      </Button>
                    </OverlayTrigger>
                    &nbsp;&nbsp;

                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip className="medium mt-1">Voltar para página anterior</Tooltip>}>
                      <Button className="mt-3" disabled={isSubmitting} onClick={() => history.goBack()}>
                        Voltar
                      </Button>
                    </OverlayTrigger>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default PropostaDarAndamento;


