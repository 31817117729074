import React, { useRef, useState, useEffect } from "react";
import useApiClient, { apiRoutes } from "../../../hooks/apiClient";
import { Button, Form } from "react-bootstrap";
import {toast,  Bounce} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SelectFileInput = (props) => {
  const { accept, show, error, defaultPayload, onFinishUpload, activateSpinner, tipo } = props;
  const fileInput = useRef();
  const [uploadPayload, setUploadPayload] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadError, setUploadError] = useState(null);

  const { request, data, errors, requestId } = useApiClient();

  useEffect(() => {
    if(uploadPayload){
      if (tipo === "chamamento"){
        request(apiRoutes.main.chamamentoPublico.uploadArquivoChamamentoPublico, uploadPayload,{requestId: "salvar-arquivo"});  
      }
      if (tipo === "termo"){
        request(apiRoutes.main.termosAcordos.uploadArquivoTermosAcordos, uploadPayload,{requestId: "salvar-arquivo"});  
      }
      activateSpinner();
    }

  }, [request, uploadPayload]);

  useEffect(() => {
    if (data && requestId === "salvar-arquivo")  onFinishUpload(requestId);     
    if (errors){
      toast.error(errors.message || "algo deu errado!!", {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
        style: { 
          width: "500px", 
        },
        onClose: () => {
          window.location.reload();
        }
      });
    };
    if (data || errors) setUploading(false);
  }, [data, errors, requestId, onFinishUpload, activateSpinner]);

  const onFileChange = (event) => {
    event.preventDefault();
    let payload = {};

    const fileProcessed = () => {
      setUploadPayload(payload);
      event.target.files = new DataTransfer().files;
    };

    let file_reader = new FileReader();
    let file = event.target.files[0];

    if (!file) return;
    setUploading(true);
    if (file.size > 100 * 1024 * 1024) { 
      setUploadError("O tamanho do arquivo excede o limite máximo permitido de 100 MB.");
      toast.error(
        uploadError || 'O tamanho do arquivo excede o limite máximo permitido de 100 MB.',
        {
          position: "top-center",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
        }
      );
      return;
    }
    if(tipo === "chamamento" ||  tipo === "termo"){
      if (file.type !== 'application/pdf') { 
        setUploadError("Por favor, selecione um arquivo PDF.");
        toast.error(
          uploadError || 'Por favor, selecione um arquivo PDF.',
          {
            position: "top-center",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
          }
        );
        return;
      }
    }


    file_reader.onload = () => {
      payload = {
        ...defaultPayload,
        arquivoNome: file.name,
        arquivo: file_reader.result.substring(
          file_reader.result.indexOf(",") + 1
        ),
      };

      fileProcessed();
    };

    file_reader.onerror = () => {
      payload = {
        ...defaultPayload,
        arquivoNome: file.name,
        error: file_reader.error,
      };

      fileProcessed();
    };

    file_reader.readAsDataURL(file);
  };

  //if (uploading) return <Spinner animation="border" />;

  //if (uploading) cancelSpinner();
 // if (errors) cancelSpinner();
  

  if (!show) return null;

  return (
    <>
      <div>{uploadError}</div>
      <Button
        variant="outline-primary"
        size="sm"
        onClick={() => {
          setUploadError(null);
          fileInput.current.click();
        }}
      >
        Selecionar Arquivo
      </Button>
      <Form.Control
        type="file"
        style={{ display: "none" }}
        ref={fileInput}
        accept={accept}
        onChange={onFileChange}
        isInvalid={error}
      />
      {error && (
        <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
      )}
    </>
  );
};

export default SelectFileInput;
