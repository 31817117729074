import { method } from "lodash";
import appConfig from "../config/app.config";

/**
 * Rotas para os serviços das APIs consumidas pela aplicação
 */
const apiRoutes = {
  main: {
    auth: {
      __route: "/api/auth",
      signInBasic: { method: "POST", url: "/signin" },
    },
    cache: {
      __route: "/api/cache",
      areas: { method: "GET", url: "/areas" },
      orgaos: { method: "GET", url: "/orgaos" },
      andamentos: { method: "GET", url: "/andamentos" },
      chamamentos: {method: "GET", url:"/chamamentos"},
      impugnacao: {method: "GET", url:"/impugnacao"},
      modalidades: { method: "GET", url: "/modalidades" },
      proposta:{method: "GET", url:"/proposta"},
      categoria:{method: "GET", url:"/categoria"},
      andamentosRelatorio:{method: "GET", url:"/andamento/relatorio"},
      orgaosProposta:{method: "GET", url:"/proposta/orgaos"},
      setoresPorSecretaria:{method: "GET", url:"/secretaria/setores/:idSecretaria"},
      orgaossemadm:{method: "GET", url:"/orgaossemadm"},
      setoresSemCoordenadores: {method: "GET", url:"/setores/sem/coordenador"}, 
      setoresSecretariaDoUsuario:{method: "GET", url:"/usuario/secretaria/setores"},

    },
    usuario: {
      __route: "/api/usuario",
      usuarioLogado: { method: "GET", url: "" },
      salvar: { method: "POST", url: "/salvar" },
      criar: { method: "POST", url: "/criar" },
      ativar: { method: "POST", url: "/ativar" },
      solicitar: { method: "POST", url: "/redefinicao/solicitar" },
      redefinicao: { method: "POST", url: "/redefinicao" },
    },

    admin: {
      __route: "/api/usuario",
      criarCoordenador: { method: "POST", url: "/admin/criar/coordenador" },
      listarCordenadores: { method: "GET", url: "/admin/listar/coordenadores" },
      pesquisarCoordenador:{method: "POST", url:"/admin/pesquisar/coordenador"},
      removerCoordenador: {
        method: "POST",
        url: "/admin/remover/coordenador/:IdAdm",
      },
    },

    adm: {
      __route: "/api/adm",
      criarAdm: { method: "POST", url: "/criar/adm" },
      listarAdms: { method: "GET", url: "/adms?limit=:limit&offset=:offset" },
      pesquisarAdm:{method: "GET", url:"/pesquisar?limit=:limit&offset=:offset&orgao=:orgao"},
      removerAdm: {
        method: "POST",
        url: "/desativar/:IdAdm",
      },
    },

    setor: {
      __route: "/api/setor",
      criarSetor: { method: "POST", url: "/criar" },
      atualizarSetor: { method: "POST", url: "/atualizar" },
      listarSetores: { method: "GET", url: "/listar?limit=:limit&offset=:offset" },
      pesquisarSetor:{method: "GET", url:"/pesquisar?limit=:limit&offset=:offset&IdSetor=:IdSetor"},
      carregarSetorPorID:{method: "GET", url:"/carregar/:IdSetor"},
      removerAdm: {
        method: "POST",
        url: "/desativar/:IdAdm",
      },
    },

    coordenador: {
      __route: "/api/coordenador",
      criarCoordenador: { method: "POST", url: "/admin/criar/coordenador" },
      listarCordenadores: { method: "GET", url: "/listar?limit=:limit&offset=:offset"},
      pesquisarCoordenador:{method: "POST", url:"/pesquisar?limit=:limit&offset=:offset&IdSetor=:IdSetor"},
      removerCoordenador: { method: "POST", url: "/admin/remover/coordenador/:id"},
    },

    UserGov: {
      __route: "/api/usuariogov",
      CarregarUserGovLogado: { method: "GET", url: "/logado" },
      SalvarUserGovUpdate: { method: "POST", url: "/update" },
      AtivarUserGov: { method: "POST", url: "/ativar" },
    },

    Osc: {
      __route: "/api/osc",
      ListarOscs: { method: "GET", url: "/listar" },
    },

    revisor: {},

    gestorOSC: {
      __route: "/api/gestorosc",
      ListarProponentesDaOsc: {
        method: "POST",
        url: "/listar/proponentes/osc/:id",
      },
      ListarPropostasDaOsc: { method: "GET", url: "/listar/propostas/osc/:id" },
      CarregarOsc: { method: "GET", url: "/carregar/dados/osc" },
      UpdateOsc: { method: "POST", url: "/update/osc" },
      PesquisarProponente: { method: "POST", url: "/pesquisar/cpf" },
      VincularProponente: { method: "POST", url: "/vincular/proponente/:id" },
      DesvincularProponente: {
        method: "POST",
        url: "/desvincular/proponente/:id",
      },
    },

    credenciamento: {
      __route: "/api/credenciamento",
      AndamentoCredenciamento: { method: "POST", url: "/andamento" },
      listarCredenciamentos: {
        method: "POST",
        url: "/carregar/credenciamentos/:idUsuario",
      },
      CarregarCredenciamentoPorId: {
        method: "POST",
        url: "/carregar/credenciamento/:idCredenciamento",
      },
    },

    publicacoes: {
      __route: "/api/publicacao",
      recentes: { method: "GET", url: "/recentes" },
      pesquisar: { method: "GET", url: "/pesquisar" },
    },
    propostas: {
      __route: "/api/proposta",
      listarDoUsuario: { method: "GET", url: "/listar?limit=:limit&offset=:offset" },
      pesquisarDoUsuario: { method: "GET", url: "/usuario/pesquisar?limit=:limit&offset=:offset&idstatus=:idstatus" },
      listarDaSecretaria:{method: "GET", url:"/gov/listar?limit=:limit&offset=:offset"},
      listagemPublica:{method: "GET", url:"/listar/proposta/publica?limit=:limit&offset=:offset"},
      porIdAuth: { method: "GET", url: "/auth/:id" },
      pesquisar: { method: "GET", url: "/pesquisar?limit=:limit&offset=:offset&idorgao=:idorgao&idarea=:idarea" },
      listarArquivos: { method: "GET", url: "/listar/arquivos/:id" },
      listarPorID:{method:"GET", url: "/:id"},
      removerArquivo: { method: "POST", url: "/remover-arquivo" },
      upload: { method: "POST", url: "/upload" },
      download: {
        method: "GET",
        url: "/arquivo/download/:id",
        responseType: "blob",
      },
      salvar: { method: "POST", url: "" },
      submeter: { method: "POST", url: "/submeter" },
      propostaDarAndamento: { method: "POST", url: "/dar-andamento" },
      secretariaPesquisarProposta: {method: "POST", url: "/secretaria/pesquisar?limit=:limit&offset=:offset&idarea=:idarea"}
    },
    
    servidor: {
      __route: "/api/servidor",
      criarServidor: { method: "POST", url: "/criar"},
      listarServidor: {method: "GET",url: "/listar?limit=:limit&offset=:offset"},
      removerServidor: { method: "POST", url: "/remover/:id"},
      pesquisarServidor:{method: "POST", url:"/pesquisar?limit=:limit&offset=:offset&cpf=:cpf"},


      listarAndamento: { method: "GET", url: "/listar/andamento" },
      updateAndamento: { method: "POST", url: "/update/andamento"},


    },
    chamamentoPublico: {
      __route: "/api/chamamentopublico",
      uploadChamamentoPublico: { method: "POST", url: "/upload" },
      uploadArquivoChamamentoPublico: { method: "POST", url: "/secretaria/upload/arquivo/chamamento" },
      listarChamamento:{method:"GET", url:"/listar/todos?limit=:limit&offset=:offset"},
      listarChamamentoPorId:{method: "GET", url:"/listar/chamamento/:id"},
      removerArquivoChamamento:{method: "DELETE", url:"/secretaria/remover/arquivo/:id"},
      listarArquivosPorChamamento:{method: "GET", url:"/listar/arquivos?limit=:limit&offset=:offset&id=:id"},
      listarArquivosPorChamamentoParaListagemPublica:{method: "GET", url:"/listar/arquivos/publico/:id"},
      listarChamamentoPorSecretaria:{method:"GET", url:"/secretaria/listar?limit=:limit&offset=:offset"},
      removerChamamento:{method:"POST",url:"/remover/:IdChamamentoPublico"},
      publicoPesquisarChamamento: {method:"GET", url:"/pesquisar?limit=:limit&offset=:offset&sei=:sei"},
      secretariaPesquisarChamamento: {method:"GET", url:"/secretaria/pesquisar?limit=:limit&offset=:offset&sei=:sei"},
      secretariaAtualizarChamamento: {method:"POST", url:"/secretaria/atualizar/chamamento"},
    },

    impugnacao: {
      __route: "/api/impugnacao",
      salvarImpugnacao: { method: "POST", url: "/enviar/impugnacao" },
      secretariaListarImpugnacao: { method: "GET", url: "/secretaria/listar?limit=:limit&offset=:offset" },
      proponenteListarImpugnacao: { method: "GET", url: "/proponente/listar?limit=:limit&offset=:offset" },
      filtrarImpugnacao: { method: "GET", url: "/secretaria/filtrar/impugnacao?limit=:limit&offset=:offset&idstatus=:idstatus" },
      proponenteFiltrarImpugnacao: { method: "GET", url: "/proponente/filtrar?limit=:limit&offset=:offset&idstatus=:idstatus" },
      listarImpugnacaoPorId: {method: "GET", url:"/listar/impugnacao/:id"},
      responderImpugnacao: {method: "POST", url: "/responder"},
    },

    termosAcordos: {
      __route: "/api/termosacordos", 

      salvarTermosAcordos: { method: "POST", url: "/salvar" },
      listarTermosAcordos: { method: "GET", url: "/secretaria/setor/listar?limit=:limit&offset=:offset" },
      secretariaPesquisarTermosAcordos:{method: "GET", url:"/secretaria/pesquisar/termos?limit=:limit&offset=:offset&sei=:sei"},
      removerTermo: { method: "POST", url: "/remover/termo/:idtermo" },
      listarArquivosTermosAcordos: { method: "GET", url: "/listar/arquivos/termo?limit=:limit&offset=:offset&id=:id" },
      PublicoListarArquivosTermosAcordos: { method: "GET", url: "/publico/listar/arquivos/termo/:id" },


      
      listarTermosAcordosPublico: { method: "GET", url: "/listar?limit=:limit&offset=:offset" },
      uploadArquivoTermosAcordos:{method: "POST", url:"/secretaria/upload/arquivo/termo"},
      listarTermosAcordosPorId: { method: "GET", url: "/listar/termoPorId/:id" },
      PublicoPesquisarTermosAcordos:{method: "GET", url:"/pesquisar?limit=:limit&offset=:offset&sei=:sei"},
      removerArquivoTermoAcordo:{method: "POST", url:"/remover/arquivo/termo/:id"},
      atualizarTermoAcordo:{method: "POST", url:"/termo/atualizar"},
      download: {
        method: "GET",
        url: "/baixar/termos/1",
        responseType: "blob",
        type: "blob",
      },
    },

    relatorio: {
      __route: "/api/relatorio",
      salvar: { method: "POST", url: "/salvar" },
      pesquisar: { method: "POST", url: "/pesquisar?limit=:limit&offset=:offset&idcategoria=:idcategoria" },
      listarRelatorios: {method: "GET", url:"/listar?limit=:limit&offset=:offset"},
      listarRelatorioPorId: {method: "GET", url:"/listar/relatorio/:idrelatorio"},
      relatorioDarAndamento:{method:"POST", url:"/responder/relatorio"}
    },
  },
};

const prepare = (routes) => {
  for (let host in routes) {
    for (let controller in routes[host]) {
      for (let action in routes[host][controller]) {
        if (action === "__route") continue;

        routes[host][controller][
          action
        ].url = `${appConfig.host}${routes[host][controller].__route}${routes[host][controller][action].url}`;
      }
    }
  }

  return routes;
};

const preparedRoutes = prepare(apiRoutes);

export default preparedRoutes;
