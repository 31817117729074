import React, { useEffect, useContext } from "react";
import { Container, Row, Col, Alert, OverlayTrigger, Tooltip } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { AppContext } from "../../../context/AppContext";
import { useHistory } from "react-router";
import * as yup from "yup";
import { useFormik } from "formik";
import TextInput from "../../../components/UI/Formik/TextInput/TextInput";
import SpinnerLabel from "../../../components/UI/SpinnerLabel";
import useApiClient, { apiRoutes } from "../../../hooks/apiClient";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer, Bounce } from "react-toastify";

const validationSchema = yup.object().shape({
  nome: yup.string().max(100, 'O nome  não pode ter mais de 100 caracteres').required("Informe o nome do setor e sua sigla"),
  descricao: yup.string().max(200, 'A descrição não pode ter mais de 200 caracteres').required("Informe uma breve descrição sobre o setor."),
});

const initialValues = {
  nome: "",
  descricao: "",
};

const FormCriarSetor = () => {
  const { setBackgroundClassList } = useContext(AppContext);
  const history = useHistory();

  const { request, data, errors, loading, requestId } = useApiClient();


  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: (values) => {
      request(apiRoutes.main.setor.criarSetor, values, {
        requestId: "salvar",
      });
    }
  });

  const { setValues, setSubmitting, setStatus, isSubmitting } = formik;

  useEffect(() => {
    setBackgroundClassList(["background-1"]);
  });

  useEffect(() => {
    if (data && requestId === "salvar") {
      toast.success("Setor registrado com sucesso!", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
        onClose: () => {
          window.location.reload();
        },
      });
    }

    if (errors) {
      setSubmitting(false);
      toast.error(
        errors.message ||
        "Desculpe algo deu errado, Tente novamente mais tarde!",
        {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
          onClose: () => {
            window.location.reload();
          },
        }
      );
    }
  }, [data, errors, setSubmitting, history, requestId]);


  const readOnly = loading || formik.isSubmitting;

  return (
    <>
      <Container id="cabecalho-pagina" fluid>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <Row>
          <Col className="d-flex flex-column text-center mt-5">
            <h1 className="text-white text-shadow mt-5">
              Cadastro do setor
            </h1>
          </Col>
        </Row>
      </Container>

      <Container id="conteudo-pagina-interna">
        <Row>
          <Col className="mt-5">
            <Form>
              <Row className="d-flex g-4">
                <Col lg="12" md="12" sm="12" xs="12">
                  <h3 className="my-3">Dados do Setor</h3>
                </Col>

                {formik.status && (
                  <Alert variant="danger">{formik.status}</Alert>
                )}

                <Col lg="12" md="12" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    field="nome"
                    label="Informe o nome do setor e sua sigla:"
                    placeholder="EX: Assessoria de Tecnologia da Informação - ASSETI"
                    disabled={isSubmitting}
                  />
                </Col>

                <Col lg="12" md="12" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    field="descricao"
                    label="Forneça uma breve descrição das responsabilidades do setor:"
                    placeholder="EX: Setor responsável por prestar serviços relacionados à Tecnologia da Informação (TI)"
                    disabled={isSubmitting}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="d-flex justify-content-center mt-5">
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip className="medium mt-1">Salvar no sistema</Tooltip>}>
                    <Button
                      className="mt-3"
                      disabled={readOnly}
                      onClick={formik.handleSubmit}
                    >
                      <SpinnerLabel
                        loading={formik.isSubmitting}
                        label="Salvar"
                      />
                    </Button>
                  </OverlayTrigger>
                  &nbsp;&nbsp;
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip className="medium mt-1">Voltar para página anterior</Tooltip>}>
                    <Button className="mt-3" onClick={() => history.goBack()} disabled={formik.isSubmitting}>
                      Voltar
                    </Button>
                  </OverlayTrigger>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default FormCriarSetor;
