import React, { useContext, useEffect } from "react";
import { Col, Container, Row, OverlayTrigger, Tooltip } from "react-bootstrap";
import { BsFillPersonFill } from "react-icons/bs";
import { IoMdLogOut } from "react-icons/io";
import { FaUserPlus } from "react-icons/fa";
import ImgDemoFotoPerfil from "../../../assets/img/foto-perfil-placeholder.png";
import { AppContext } from "../../../context/AppContext";
import { Link } from "react-router-dom";
import ListagemDeRelatorios from "../../Reportar/ListagemDeRelatorios/ListagemDeRelatorios";
import ListarAdms from "../ListarAdm/ListarAdms";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../../redux/auth";
import { MdDomainAdd } from "react-icons/md";

const MinhaAreaSU = () => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { setBackgroundClassList } = useContext(AppContext);

  useEffect(() => {
    setBackgroundClassList(["background-1"]);
  }, []);

  return (
    <>
      {auth.user ? (
        <>
          <Container id="cabecalho-pagina" fluid>
            <Row>
              <Col className="d-flex flex-column text-center mt-5">
                <h1 className="text-white text-shadow-orange mt-5">Minha Área</h1>
              </Col>
            </Row>
          </Container>
          <Container id="conteudo-pagina-interna">
            <Row>
              <Col className="d-flex flex-column mt-5">
                <Row className="g-4 g-lg-4">
                  <Col md="6" className="me-auto ms-auto text-center">
                    <Link>
                      <img
                        src={ImgDemoFotoPerfil}
                        alt="Foto do usuário"
                        className="img rounded-pill w-25 box-shadow"
                      />
                    </Link>
                    <h2>{auth.user.nome}</h2>
                    <p className="mt-2 fw-bolder title-uppercase text-gray">
                      {auth.user.email}
                    </p>
                  </Col>
                </Row>

                <Row className="g-4 g-lg-4">
                  <Col md="12" className="me-auto ms-auto text-center">
                    <ul id="profile-menu" className="mt-md-3 mt-lg-3">
                      <li>
                        <OverlayTrigger
                          placement="bottom"
                          overlay={<Tooltip className="medium mt-1">Atualizar seus dados</Tooltip>}>
                          <Link
                            className="btn btn-outline btn-outline-primary btn-sm rounded-pill"
                            to="/update/user/gov"
                          >
                            <BsFillPersonFill />{" "}
                            <span className="d-md-inline">Seus dados</span>
                          </Link>
                        </OverlayTrigger>
                      </li>
                      <li>
                        <OverlayTrigger
                          placement="bottom"
                          overlay={<Tooltip className="medium mt-1">Cadastrar novo Administrador no sistema</Tooltip>}>
                          <Link
                            className="btn btn-outline btn-outline-primary btn-sm rounded-pill"
                            to="/su/cadastrar/adm"
                          >
                            <FaUserPlus />{" "}
                            <span className="d-md-inline">Adicionar Administrador</span>
                          </Link>
                        </OverlayTrigger>
                      </li>
                      {/**<li>
                        <Link
                          className="btn btn-outline btn-outline-primary btn-sm rounded-pill"
                          to="/su/cadastrar/adm"
                        >
                          <MdDomainAdd />{" "}
                          <span className="d-md-inline">Adicionar secretaria ou autarquia</span>
                        </Link>
                      </li>**/}
                      <li>
                        <OverlayTrigger
                          placement="bottom"
                          overlay={<Tooltip className="medium mt-1">Sair do sistema</Tooltip>}>
                          <a
                            className="btn btn-outline  btn-outline-danger  btn-sm rounded-pill"
                            href="/"
                            onClick={() => dispatch(authActions.signout())}
                          >
                            <IoMdLogOut /> Sair
                          </a>
                        </OverlayTrigger>
                      </li>
                    </ul>
                  </Col>
                </Row>
                <ListagemDeRelatorios />
                < ListarAdms />
              </Col>
            </Row>
          </Container>
        </>
      ) : (
        <p>Carregando...</p>
      )}
    </>
  );
};

export default MinhaAreaSU;
