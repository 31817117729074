import React, { useEffect } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import TextInput from "../../../components/UI/Formik/TextInput/TextInput";
import * as yup from "yup";
import { useFormik } from "formik";
import SelectInput from "../../../components/UI/Formik/SelectInput/SelectInput";
import { MunicipiosAsOptions } from "../../../components/UI/Formik/SelectInput/SelectInput.options";
import useApiClient, { apiRoutes } from "../../../hooks/apiClient";

const validationSchema = yup.object().shape({
  nome: yup.string().matches(""),
  cnpj: yup.string().matches("[0-9]{14}", "Somente números. 14 dígitos."),
});

const initialValues = {
  nome: "",
  municipio: "",
  cnpj: "",
};

const OscCredenciadasLista = () => {
  const { request, data, loading } = useApiClient();
  const readOnly = loading;

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {

    },
  });

  useEffect(() => {
    request(apiRoutes.main.Osc.ListarOscs)
  }, [request]);

  return (
    <>
      <Container id="cabecalho-pagina">
        <Row>
          <Col className="d-flex flex-column text-center mt-5">
            <h1 className="text-white text-shadow-orange mt-5">
              Oscs Credenciadas
            </h1>
          </Col>
        </Row>
      </Container>

      <Container id="conteudo-pagina-interna">
        <Row>
          <Col className="mt-5">
            <p>
              Aqui são listadas  todas as OSC credenciadas pelo governo do estado do Rio Grande do Norte.
            </p>
          </Col>
        </Row>

        <Row>
          <Col className="bg-light rounded p-3 mt-2">
            <Form>
              <Row>
                <Col lg="4" md="4" sm="6" xs="9">
                  <TextInput
                    formik={formik}
                    field="nome"
                    label="Razão social:"
                  />
                </Col>

                <Col lg="6" md="6" sm="12" xs="12">
                  <SelectInput
                    formik={formik}
                    field="cidade"
                    label="Municipio:"
                    readOnly={readOnly}
                  >
                    <MunicipiosAsOptions />
                  </SelectInput>
                </Col>

                <Col lg="2" md="6" sm="6" xs="12">
                  <TextInput
                    formik={formik}
                    field="cnpj"
                    label="CNPJ:"
                    maxLength={14}
                  />
                </Col >

                <Col className="d-flex flex-row mt-3 justify-content-center">
                  <Button variant="primary" className="me-3">
                    Filtrar
                  </Button>
                  <Button variant="link" className="ms-3">
                    Limpar
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>

        <Row>
          <Col>
            <div style={{ overflowX: 'auto' }}>
              <Table striped bordered hover className="mt-4">
                <thead>
                  <tr>
                    <th>Razão Social</th>
                    <th>Logradouro</th>
                    <th>Bairro</th>
                    <th>Cidade</th>
                    <th>CEP</th>
                    <th>CNPJ</th>

                  </tr>
                </thead>
                <tbody>
                  {(data || {}).length &&
                    data.map((itemData) => (
                      <tr
                        key={itemData.index}
                        style={{ cursor: "pointer" }}>
                        <td> {itemData.tx_razao_social}</td>
                        <td> {itemData.tx_logradouro}</td>
                        <td> {itemData.tx_bairro}</td>
                        <td> {itemData.tx_cidade} </td>
                        <td> {itemData.tx_cep} </td>
                        <td> {itemData.tx_cnpj} </td>
                        <td></td>
                      </tr>))}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default OscCredenciadasLista;
