import React, { useEffect, useContext, useState } from "react";
import { Container, Row, Col, Alert, OverlayTrigger, Tooltip } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { AppContext } from "../../../context/AppContext";
import { useHistory, useParams } from "react-router";
import { useFormik } from "formik";
import TextInput from "../../../components/UI/Formik/TextInput/TextInput";
import SpinnerLabel from "../../../components/UI/SpinnerLabel";
import useApiClient, { apiRoutes } from "../../../hooks/apiClient";
import SelectInputCached from "../../../components/UI/Formik/SelectInput/SelectInput.cached";
import TextareaInput from "../../../components/UI/Formik/TextareaInput/TextareaInput";
import { toast, ToastContainer, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Inputmask from "inputmask";




const initialValues = {
  idtermosacordos: "",
  idarquivotermosacordo: "",
  idmodalidades: "",
  idandamentotermosacordos: "",
  razaosocial: "",
  descricao: "",
  processosei: "",

};


const UpdateTermosAcordos = () => {
  const { setBackgroundClassList } = useContext(AppContext);
  const { request, data, loading, requestId, errors } = useApiClient();
  const [idModalidadeTermosAcordos, setModalidadeTermosAcordos] = useState(0);
  const [idStatusAndamento, setStatusAndamento] = useState(0);
  const history = useHistory();
  const { id } = useParams();


  const SalvarTermosAcordos = (values) => {
    const SeiSemMask = values.processosei.replace(/[^\d]+/g, "");
    values.processosei = SeiSemMask;
    request(apiRoutes.main.termosAcordos.atualizarTermoAcordo, values, {
      requestId: "atualizar",
    });
  };


  const formik = useFormik({
    initialValues,
    onSubmit: SalvarTermosAcordos,
  });


  const { setSubmitting, isSubmitting } = formik;

  useEffect(() => {
    setBackgroundClassList(["background-1"]);
  });


  useEffect(() => {
    if (id && requestId !== "remover" && requestId !== "carregar" && requestId !== "atualizar") {
      request(
        apiRoutes.main.termosAcordos.listarTermosAcordosPorId,
        { routeParams: { id } },
        { requestId: "carregar" }
      );
    }
  }, [request, id, requestId]);


  useEffect(() => {
    if (data && requestId === "atualizar") {
      toast.success('Termo ou Acordo atualizado com sucesso!!', {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
        onClose: () => {
          setSubmitting(false);
          window.location.reload();
        }
      });
    }

    if (errors) {
      
      toast.error(errors.message || "algo deu errado!!", {
        position: "top-center",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
        onClose: () => {
          setSubmitting(false);
          window.location.reload();
        }
      });
    }

  }, [data, errors, history]);


  useEffect(() => {

    const SEIMask = Inputmask({
      mask: "99999999.999999/9999-99",
      placeholder: " ",
      numericInput: true,
      rightAlign: false,
      numericInput: true,
      allowMinus: false,
      positionCaretOnClick: "none",
    });

    SEIMask.mask(document.getElementById("processosei"));

  }, []);




  useEffect(() => {
    if (data && requestId === "carregar") {
      setModalidadeTermosAcordos(data.idmodalidades);
      setStatusAndamento(data.idandamentotermosacordos);
      formik.setValues(data);

    };
  }, [data, requestId]);


  const readOnly = loading || formik.isSubmitting;

  return (
    <>
      <Container id="cabecalho-pagina" fluid>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <Row>
          <Col className="d-flex flex-column text-center mt-5">
            <h1 className="text-white text-shadow mt-5">Atualizar Dados do termo ou acordo</h1>
          </Col>
        </Row>
      </Container>

      <Container id="conteudo-pagina-interna">
        <Row>
          <Col className="mt-5">
            <Form>
              <Row className="d-flex g-4">
                <Col lg="12" md="12" sm="12" xs="12">
                  <h3 className="my-3">Dados do termo ou acordo</h3>
                </Col>

                {formik.status && (
                  <Alert variant="danger">{formik.status}</Alert>
                )}

                <Col lg="12" md="12" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    field="razaosocial"
                    label="Razão Social:"
                    disabled = {isSubmitting}
                  />
                </Col>

                <Col lg="12" md="12" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    field="processosei"
                    label="Nº processo SEI:"
                    disabled = {isSubmitting}
                  />
                </Col>

                <Col lg="12" md="12" sm="12" xs="12">
                  <TextareaInput
                    formik={formik}
                    field="descricao"
                    label="Descrição"
                    placeholder="Descreva resumidamente o objetivo do acordo"
                    disabled = {isSubmitting}
                    rows={10}
                  />
                </Col>

                <Col lg="6" md="6" sm="6" xs="6">
                  <SelectInputCached
                    formik={formik}
                    field="idandamentotermosacordos"
                    label="Andamento:"
                    cacheKey="andamentos"
                    disabled = {isSubmitting}
                    id={idStatusAndamento}
                    isUpdate
                  />
                </Col>

                <Col lg="6" md="6" sm="6" xs="6">
                  <SelectInputCached
                    formik={formik}
                    field="idmodalidades"
                    label="Modalidade do Termo ou Acordo"
                    cacheKey="modalidades"
                    disabled = {isSubmitting}
                    id={idModalidadeTermosAcordos}
                    isUpdate
                  />
                </Col>
              </Row>

              <Row>
                <Col className="d-flex justify-content-center mt-5">
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip className="medium mt-1">Salvar modificações</Tooltip>}>
                    <Button
                      className="mt-3"
                      disabled = {isSubmitting}
                      onClick={formik.handleSubmit}
                    >
                      <SpinnerLabel
                        loading={formik.isSubmitting}
                        label="Atualizar"
                      />
                    </Button>
                  </OverlayTrigger>
                
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default UpdateTermosAcordos;
