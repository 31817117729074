import React, { useEffect } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import TextInput from "../../../components/UI/Formik/TextInput/TextInput";
import { useFormik } from "formik";
import useApiClient, { apiRoutes } from "../../../hooks/apiClient";
import { RiDeleteBin2Fill } from "react-icons/ri";
import { useSelector } from "react-redux";

const initialValues = {
  nome: " ",
  cpf: " ",

};

const ListagemProponenteOsc = () => {
  const { request, data } = useApiClient();
  const auth = useSelector((state) => state.auth);
  const id = auth.user.osc;


  const formik = useFormik({
    initialValues,
    onSubmit: (values) => { },
  });


  useEffect(() => {
    request(apiRoutes.main.gestorOSC.ListarProponentesDaOsc, { routeParams: { id } })

  }, [request, id]);


  return (
    <>
      <Container className="conteudo-pagina mt-5 ">
        <Row>
          <Col>
            <h2 className="mt-4">Proponentes vinculados à OSC</h2>

          </Col>
        </Row>


        <Row>
          <Col>
            <p>
              Nesta tabela são listados todos os proponentes que estão vinculados à sua OSC.
            </p>
          </Col>
        </Row>

        <Row>
          <Col className="bg-light rounded p-3">
            <Form>
              <Row>
                <Col lg="4" md="4" sm="6" xs="9">
                  <TextInput
                    formik={formik}
                    field="nome"
                    label="Nome"
                  />
                </Col>
                <Col lg="2" md="2" sm="6" xs="3">
                  <TextInput
                    formik={formik}
                    field="cpf"
                    label="CPF:"
                    maxLength={11}
                  />
                </Col>
                <Col className="d-flex flex-row mt-3 justify-content-center">
                  <Button variant="primary" className="me-3">
                    Filtrar
                  </Button>
                  <Button variant="link" className="ms-3">
                    Limpar
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>

        <Row>
          <Col>
            <div style={{ overflowX: 'auto' }}>
              <Table striped bordered hover className="mt-4">
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Nome</th>
                    <th>CPF</th>
                    <th>Remover</th>
                  </tr>
                </thead>
                <tbody>
                  {(data || {}).length &&
                    data.map((itemData) => (
                      <tr
                        key={itemData.id_usuario}>
                        <td> {itemData.id_usuario}</td>
                        <td> {itemData.tx_nome}</td>
                        <td> {itemData.tx_cpf}</td>
                        <td style={{ cursor: "pointer" }}>
                          <RiDeleteBin2Fill size={20} color="red"
                            onClick={(e) => {
                              e.preventDefault();
                              if (window.confirm("Você deseja realmente excluir esse usuário?")) {
                                const id = itemData.id_usuario;
                                request(apiRoutes.main.gestorOSC.DesvincularProponente, { routeParams: { id } });
                              }
                            }} />
                        </td>
                      </tr>))}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ListagemProponenteOsc;