import { useFormik } from "formik";
import React, { useContext, useEffect, useRef } from "react";
import { Col, Container, Row, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import TextInput from "../../../components/UI/Formik/TextInput/TextInput";
import SpinnerLabel from "../../../components/UI/SpinnerLabel";
import { AppContext } from "../../../context/AppContext";
import useApiClient, { apiRoutes } from "../../../hooks/apiClient";
import { useHistory, useParams } from "react-router";
import { useSelector } from "react-redux";
import SelectInputCached from "../../../components/UI/Formik/SelectInput/SelectInput.cached";
import { useState } from "react";
import { toast, ToastContainer, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Inputmask from "inputmask";
import TextareaInput from "../../../components/UI/Formik/TextareaInput/TextareaInput";





const initialValues = {
  idchamementopublico: "",
  idstatusandamento: "",
  idarea: "",
  Nprocesso: "",
  titulo: "",
  objeto: "",
};


const AtualizarChamamentoPublico = () => {
  const { setBackgroundClassList } = useContext(AppContext);
  const { request, data, errors, requestId } = useApiClient();
  const auth = useSelector((state) => state.auth);
  const papel = auth && auth.user ? auth.user : "";
  const [idArea, setIdArea] = useState(0);
  const [idStatusAndamento, setStatusAndamento] = useState(0);
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    setBackgroundClassList(["background-1"]);
  });

  const handleSubmitClean = (values) => {
    const SeiSemMask = values.Nprocesso.replace(/[^\d]+/g, "");
    values.Nprocesso = SeiSemMask;
    request(apiRoutes.main.chamamentoPublico.secretariaAtualizarChamamento, values, {
      requestId: "atualizar",
    });
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmitClean,
  });

  useEffect(() => {
    if (id && requestId !== "carregar" && requestId !== "atualizar") {
      request(
        apiRoutes.main.chamamentoPublico.listarChamamentoPorId,
        { routeParams: { id } },
        { requestId: "carregar" }
      );
    }
  }, [request, requestId]);

  useEffect(() => {
    if (data && requestId === "carregar") {
      setIdArea(data.idarea);
      setStatusAndamento(data.idstatusandamento);
      formik.setValues(() => ({
        idchamementopublico: data.idchamementopublico,
        idstatusandamento: data.idstatusandamento,
        idarea: data.idarea,
        titulo: data.titulo,
        Nprocesso: data.n_processo,
        objeto: data.objeto,
      }));

    };
  }, [data, requestId]);

  const { setSubmitting, isSubmitting } = formik;

  useEffect(() => {
    if (data && requestId === "atualizar") {
      toast.success("Chamamento atualizado com Sucesso!!!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
        onClose: () => {
          setSubmitting(false);
          switch (papel.papeis[0]) {
            case "SERVIDOR":
              history.push("/minha/area/servidor");
              break;
            case "COORDENADOR":
              history.push("/minha/area/coordenador");
              break;
            default:
              window.location.reload();
              break;
          }
        }
      });
    }

    if (errors) {
      toast.error(errors.message || "algo deu errado!!", {
        position: "top-center",
        autoClose: 9000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
        style: {
          width: "500px",
        },
        onClose: () => {
          setSubmitting(false);
          window.location.reload();
        }
      });

    }
  }, [data, errors, requestId]);


  useEffect(() => {

    const SEIMask = Inputmask({
      mask: "99999999.999999/9999-99",
      placeholder: " ",
      numericInput: true,
      rightAlign: false,
      numericInput: true,
      allowMinus: false,
      positionCaretOnClick: "none",
    });

    SEIMask.mask(document.getElementById("Nprocesso"));

  }, []);

  useEffect(() => {
    if (formik.values.idstatusandamento == 2) {
      toast.warn("Conforme o Decreto Estadual Nº 31.067, ao término do envio das propostas, é imprescindível a divulgação pública dos nomes e CNPJs das OSCs proponentes. Caso o PDF contendo as informações necessárias não tenha sido anexado, acesse a listagem e adicione o arquivo com os nomes e dados requeridos.", {
        position: "top-left",
        autoClose: 15000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
        style: {
          fontSize: "16px",
          width: "800px",
        }
      });
    }
  }, [formik.values.idstatusandamento]);




  return (
    <>
      <Container id="cabecalho-pagina" fluid>
        <Row>
          <Col className="d-flex flex-column text-center mt-5">
            <h1 className="text-white text-shadow mt-5">
              Atualizar chamamento público
            </h1>
          </Col>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
        </Row>
      </Container>

      <Container id="conteudo-pagina-interna">
        <Row className="d-flex g-4">
          <Col md="12" sm="12" xs="12">
            <h2 className="my-3">Dados do chamamento</h2>
          </Col>

          <Col lg="12" md="12" sm="12" xs="12">
            <TextInput
              formik={formik}
              field="titulo"
              label="Titulo:"
              disabled = {isSubmitting}
            />
          </Col>

          <Col lg="12" md="12" sm="12" xs="12">
            <TextInput
              formik={formik}
              field="Nprocesso"
              label="Nº processo SEI:"
              placeholder="Adicione o Nº do processo SEI"
              disabled={isSubmitting}
            />
          </Col>

          <Col lg="12" md="12" sm="12" xs="12">
            <TextareaInput
              formik={formik}
              field="objeto"
              label="Objeto da parceria:"
              rows={5}
              disabled = {isSubmitting}
            />
          </Col>

          <Col md="12" sm="12" xs="12">
            <SelectInputCached
              formik={formik}
              field="idarea"
              label="Área de Atuação:"
              cacheKey="areas"
              disabled = {isSubmitting}
              id={idArea}
              isUpdate
            />
          </Col>
          <Col lg="12" md="12" sm="12" xs="12">
            <SelectInputCached
              formik={formik}
              field="idstatusandamento"
              label="Andamento do Chamamento:"
              cacheKey="chamamentos"
              disabled = {isSubmitting}
              id={idStatusAndamento}
              filtraPorTipo={data && data.tipo ? data.tipo : ""}
              isUpdate
            />
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-center mt-5">
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip className="medium mt-1">Salvar modificações</Tooltip>}>
              <Button
                className="mt-1"
                disabled = {isSubmitting}
                onClick={() => formik.handleSubmit()}
              >
                <SpinnerLabel loading={formik.isSubmitting} label="Atualziar" />
              </Button>
            </OverlayTrigger>
            &nbsp;&nbsp;

          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AtualizarChamamentoPublico;
