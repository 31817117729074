import React, { useContext, useEffect} from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Row,
  Accordion,
} from "react-bootstrap";
import { AppContext } from "../context/AppContext";
import ChamamentoLista from "./Chamamento/ChamamentoLista/ChamamentoLista";
import { AiOutlineDownload } from "react-icons/ai";
import appConfig from "../config/app.config";



const ChamamentosPublicos = () => {
  const { setBackgroundClassList } = useContext(AppContext);


  useEffect(() => {
    setBackgroundClassList(["background-2"]);
  });


  return (
    <>
      <Container id="cabecalho-pagina">
        <Row>
          <Col className="d-flex flex-column text-center mt-5">
            <h1 className="text-white text-shadow-orange mt-5">
              Chamamentos Públicos
            </h1>
          </Col>
        </Row>
      </Container>

      <Container id="conteudo-pagina-interna">
        <Row>
          <Col className="d-flex flex-column">
            <Row>
              <Col>
                <Accordion className="my-5">
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle
                        as={Button}
                        variant="link"
                        eventKey="0"
                        className="w-100 text-left"
                      >
                        O que é um Chamamento Público?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        <p className="text-justify mt-3">
                          O chamamento público é a denominação do instrumento de
                          seleção da Organização da Sociedade Civil (OSC) que
                          irá celebrar a parceria com a Administração Pública.
                          Tal procedimento irá se pautar, primordialmente, em
                          garantir uma igualdade competitiva entre as OSC, ao
                          mesmo tempo em que averigua a melhor proposta. Devem
                          ser observados critérios claros e objetivos, a serem
                          definidos em edital, observando os princípios
                          norteadores da Administração Pública, tais como a
                          isonomia, moralidade e impessoalidade, por exemplo.
                        </p>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle
                        as={Button}
                        variant="link"
                        eventKey="1"
                        className="w-100 text-left"
                      >
                        Quando não será necessário haver Chamamento Público?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1">
                      <Card.Body>
                        <p className="text-justify mt-3">
                          A Lei 13.019/2014 estabelece algumas hipóteses nas
                          quais o chamamento público poderá ser dispensado.
                          Dentre elas, haverá três grandes hipóteses, cada uma
                          com as suas especificidades. As hipóteses serão de não
                          aplicabilidade, dispensa e inexigibilidade. A não
                          aplicabilidade do chamamento público é aplicável nas
                          situações de: (I) Termos de Fomento e Termos de
                          Colaboração envolvendo o repasse de recursos de
                          emendas parlamentares às leis orçamentárias anuais; e
                          (II) Acordos de Cooperação que não envolver o
                          compartilhamento de bem patrimoniado.
                        </p>
                        <p className="text-justify ">
                          A dispensa do chamamento público ocorre nas seguintes
                          situações: (I) no caso de urgência decorrente de
                          paralisação ou iminência de paralisação das atividades
                          de relevante interesse público; (II) nos casos de
                          guerra, calamidade pública, grave perturbação da ordem
                          pública ou ameaça à paz social ; (III) nos casos de
                          programas de proteção a pessoas ameaçadas ou em
                          situação que possa comprometer sua segurança; e (IV)
                          no caso de atividades voltadas ou vinculadas a
                          serviços de saúde, educação e assistência social,
                          desde que a OSC da parceria esteja previamente
                          credenciadas pelo órgão gestor.
                        </p>

                        <p className="text-justify ">
                          A inexigibilidade de chamamento público ocorre nas
                          seguintes situações: (I) objeto da parceria é
                          singular; e (II) quando as metas só puderem ser
                          atingidas por uma OSC específica, especialmente quando
                          a OSC beneficiada estiver identificada em acordo
                          internacional ou em lei (inclusive subvenção social).
                        </p>
                        <p className="text-justify ">
                          Para os casos de dispensa e inexigibilidade de
                          chamamento público, a ausência da sua realização deve
                          ser justificada. Tal explicação pode ser questionada
                          por qualquer OSC ou interessado.
                        </p>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </Col>
              <Row>
                <Col lg="12" md="12" sm="12" xs="12">
                  <h3 className="my-3">Plano de Trabalho</h3>
                </Col>
                <Col lg="12" md="12" sm="12" xs="12">
                  <p>
                   Nesta seção, você pode baixar o plano de trabalho clicando no botão 'Baixar plano de trabalho'. Após o download, preencha o plano conforme as diretrizes do edital do chamamento e apresente-o junto com a sua proposta
                  </p>
                </Col>
              </Row>
              <Row>
              <Button variant="link" href={`${appConfig.host}/docs/plano-de-trabalho-1-3.doc`}>
                  <AiOutlineDownload /> clique aqui para baixar o plano de trabalho
                </Button>
            </Row>

            </Row>
            <Row>
              <Col className="mt-4">
                 <ChamamentoLista/>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      
    </>
  );
};
export default ChamamentosPublicos;
