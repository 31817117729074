import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Spinner, Alert, OverlayTrigger, Tooltip } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import SelectInputCached from "../../components/UI/Formik/SelectInput/SelectInput.cached";
import { useFormik } from "formik";
import useApiClient, { apiRoutes } from "../../hooks/apiClient";
import { FormatDate } from "../../components/UI/Format/Format";
import TabelPagination from "../../components/pagination/TabelPaginatio";
import { toast, ToastContainer, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const initialValues = {
  IdStatusAndamento: 0,
};

const SalvarPesquisa = {
  IdStatusAndamento: 0,
};

const ListagemDeImpugnacoesDoProponente = () => {
  const { request, data, requestId, errors } = useApiClient();
  const [showSipnner, setShowSpinner] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [offset, setoffset] = useState(0);
  const [paginatedData, setPaginatedData] = useState([]);
  const [totalPages, settotalPages] = useState(0);
  const [limparPesquisa, setlimparPesquisa] = useState(true);
  const [offsetPesquisa, setoffsetPesquisa] = useState(0);
  const itemsPerPage = 5;

  useEffect(() => {
    request(apiRoutes.main.impugnacao.proponenteListarImpugnacao,
      {
        routeParams: {
          limit: itemsPerPage,
          offset: offset
        }
      },
      { requestId: "carregar" }
    );
  }, [request, offset]);

  useEffect(() => {
    if (data && requestId === "carregar") {
      setTimeout(() => {
        setPaginatedData(data);
        settotalPages(data && data.length > 0 ? Math.ceil(data[0].totalitens / itemsPerPage) : 0);
        setShowSpinner(false);
      }, 700);
    }

    if (data && requestId === "pesquisar") {
      setShowSpinner(true);
      setPaginatedData(data);
      settotalPages(data && data.length > 0 ? Math.ceil(data[0].totalitens / itemsPerPage) : 0);
      setTimeout(() => {
        setShowSpinner(false);
      }, 900);
    }
  }, [requestId, data]);

  useEffect(() => {
    if (!limparPesquisa) {
      request(apiRoutes.main.impugnacao.proponenteFiltrarImpugnacao,
        {
          routeParams: {
            limit: itemsPerPage,
            offset: offsetPesquisa,
            idstatus: formik.values.IdStatusAndamento,
          }
        },
        { requestId: "pesquisar" }
      );
    }
  }, [offsetPesquisa]);


  const FiltrarPesquisa = (values) => {
    SalvarPesquisa.idArea = values.idArea;
    setlimparPesquisa(false);
    request(apiRoutes.main.impugnacao.proponenteFiltrarImpugnacao,
      {
        routeParams: {
          limit: itemsPerPage,
          offset: offsetPesquisa,
          idstatus: formik.values.IdStatusAndamento,
        }
      },
      { requestId: "pesquisar" }
    );
  };

  const formik = useFormik({
    initialValues,
    onSubmit: FiltrarPesquisa,
  });



  const limparCampos = () => {
    setShowSpinner(true)
    formik.resetForm();
    SalvarPesquisa.idArea = 0;
    setlimparPesquisa(true)
    setoffsetPesquisa(0)
    setoffset(0)
    request(apiRoutes.main.impugnacao.proponenteListarImpugnacao,
      {
        routeParams: {
          limit: itemsPerPage,
          offset: offset
        }
      },
      { requestId: "carregar" }
    );
  };

  useEffect(() => {

    if (data && requestId === "carregar") {
      setTimeout(() => {
        setPaginatedData(data);
        settotalPages(data && data.length > 0 ? Math.ceil(data[0].totalitens / itemsPerPage) : 0);
        setShowSpinner(false);
      }, 700);
    }

    if (data && requestId === "pesquisar") {
      setShowSpinner(true);
      setPaginatedData(data);
      settotalPages(data && data.length > 0 ? Math.ceil(data[0].totalitens / itemsPerPage) : 0);
      setTimeout(() => {
        setShowSpinner(false);
      }, 900);
    }

    if (errors) {
      toast.error(errors.message || "Desculpe, algo deu errado. Tente novamente mais tarde.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
        onClose: () => {
          setShowSpinner(false);
          window.location.reload();
        },
      });
    }
  }, [data, requestId])


  const handlePageChange = (pageNumber) => {
    setShowSpinner(true);
    setActivePage(pageNumber);
    const newOffset = ((pageNumber - 1) * itemsPerPage)
    if (!limparPesquisa) {
      setoffsetPesquisa(newOffset);
    } else {
      setoffset(newOffset);
    }
  };

  return (
    <>
      <Container className="conteudo-pagina mt-5">
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <Row>
          <Col>
            <h3 className="mt-4 fw-bold">Listagem de impugnações</h3>

          </Col>
        </Row>


        <Row>
          <Col>
            <p>
              Esta seção apresenta todas as impugnações que você enviou em relação ao chamamento. A resposta à sua impugnação será enviada para o seu e-mail. Acompanhe a situação das suas impugnações exclusivamente através desta seção.
            </p>
          </Col>

        </Row>

        <Row>
          <Col className="bg-light rounded p-3">
            <Form>
              <Row>
                <Col lg="8" md="12" sm="12" xs="12">
                  <SelectInputCached
                    formik={formik}
                    field="IdStatusAndamento"
                    label="Situação da impugnação"
                    cacheKey="impugnacao"
                    allowEmpty
                  />
                </Col>


                <Col className="d-flex flex-row mt-3 justify-content-center">
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip className="medium mt-1">Pesquisar por parâmetro selecionado</Tooltip>}>

                    <Button variant="primary" className="me-3" onClick={formik.handleSubmit}>
                      Pesquisar
                    </Button>
                  </OverlayTrigger>

                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip className="medium mt-1">Limpar resultados e parâmetro da pesquisa</Tooltip>}>
                    <Button variant="link" className="ms-3" onClick={limparCampos}>
                      Limpar
                    </Button>
                  </OverlayTrigger>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>

        {showSipnner && (
          <Row>
            <Col className="d-flex flex-row mt-5 justify-content-center">
              <h5 className="mb-2">
                <Spinner
                  animation="border"
                  size="lg"
                  variant="warning" />
              </h5>
            </Col>
          </Row>)}

        <Row>
          <Col>
            {data && !showSipnner && (
              <div style={{ overflowX: 'auto' }}>
                <Table striped bordered hover className="mt-4">
                  <thead>
                    <tr>
                      <th>Data</th>
                      <th>Título</th>
                      <th>Chamamento impugnado</th>
                      <th>situação</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paginatedData.map((itemData) => (
                      <tr key={itemData.id_impugnacao}>
                        <td> {FormatDate(itemData.tm_inclusao)}</td>
                        <td> {itemData.tx_titulo} </td>
                        <td> {itemData.chamamento} </td>
                        <td> {itemData.status}</td>
                      </tr>))}

                  </tbody>
                </Table>
              </div>)}
            {!showSipnner && data && data.length === 0 && (
              <Alert variant="primary" className="mt-3">Nenhum registro encontrado</Alert>
            )}
            {data && totalPages > 1 && (
              <TabelPagination totalPages={totalPages} activePage={activePage} handlePageChange={handlePageChange} />)}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ListagemDeImpugnacoesDoProponente;
