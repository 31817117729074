import React, {useEffect, useContext, useRef} from "react";
import {Container, Row, Col} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {AppContext} from "../../context/AppContext";
import {useHistory} from "react-router";
import * as yup from "yup";
import {useFormik} from "formik";
import TextInput from "../../components/UI/Formik/TextInput/TextInput";
import SpinnerLabel from "../../components/UI/SpinnerLabel";
import useApiClient, {apiRoutes} from "../../hooks/apiClient";

import "react-toastify/dist/ReactToastify.css";
import {toast, ToastContainer, Bounce} from "react-toastify";

const validationSchema = yup.object().shape({
  senha: yup
    .string()
    .min(6, "Informe a senha com no mínimo 6 caracteres")
    .required("Informe a senha"),
  confirmeSenha: yup
    .string()
    .oneOf(
      [yup.ref("senha"), null],
      "As senhas não coincidem, digite novamente."
    )
    .required("Digite a sua senha novamente."),
});

const initialValues = {
  senha: "",
};

const UsuarioAtivacao = () => {
  const {setBackgroundClassList} = useContext(AppContext);
  const history = useHistory();
  const url_atual = window.location.href;
  const separados = url_atual.split("/");
  const token = separados[separados.length - 1];

  const {request, data, errors, loading} = useApiClient();

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: (values) => {
        request(
            apiRoutes.main.UserGov.AtivarUserGov,
            { payload: token, ...values },
          );
    } 
  });

  const {setSubmitting,isSubmitting} = formik;

  useEffect(() => {
    setBackgroundClassList(["background-1"]);
  });

  useEffect(() => {
    if (data) {
      toast.success("Cadastro ativado com sucesso!!!", {
        position: "top-center",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
        onClose: () => {
          history.push("/login/gov");
          
        },
      });
    }

    if (errors) {
      setSubmitting(false);
      toast.error(
        errors.message ||
          "Desculpe algo deu errado, Tente novamente mais tarde!",
        {
          position: "top-center",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
          onClose: () => {
            window.location.reload();
          },
        }
      );
    }
  }, [data, errors, setSubmitting, history]);


  const readOnly = loading || formik.isSubmitting;

  return (
    <>
      <Container id="cabecalho-pagina" fluid>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <Row>
          <Col className="d-flex flex-column text-center mt-5">
            <h1 className="text-white text-shadow mt-5">
              Ativação cadastral
            </h1>
          </Col>
        </Row>
      </Container>

      <Container id="conteudo-pagina-interna">
        <Row>
          <Col className="mt-5">
            <Form>
              <Row className="d-flex g-4">
                <Col lg="12" md="12" sm="12" xs="12">
                  <h3 className="my-3">Definição de senha</h3>
                </Col>
                <Row>
                    <Col>
                        <p>Para ativar seu cadastro, por favor, defina uma senha com no mínimo 6 caracteres. Após definir a senha, clique em "Salvar". Concluídas essas etapas, seu cadastro será ativado.</p>
                    </Col>
                </Row>

                <Col lg="6" md="6" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    type="password"
                    field="senha"
                    label="Senha:"
                    placeholder="Digite a sua senha"
                    disabled = {isSubmitting}
                  />
                </Col>

                <Col lg="6" md="6" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    type="password"
                    field="confirmeSenha"
                    label="Confirme sua senha:"
                    placeholder="Confirme a sua senha"
                    disabled = {isSubmitting}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="d-flex justify-content-center mt-5">
                  <Button
                    className="mt-3"
                    disabled={readOnly}
                    onClick={formik.handleSubmit}
                  >
                    <SpinnerLabel
                      loading={formik.isSubmitting}
                      label="Salvar"
                    />
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default UsuarioAtivacao;
