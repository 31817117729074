import { Route, Switch } from "react-router";
import PropostaDetalhe from "./Proposta/PropostaDetalhe/PropostaDetalhe";
import UsuarioDados from "./Usuario/UsuarioDados/UsuarioDados";
import { PrivateRoute } from "../App";

const MinhaAreaLayout = () => {
  return (
    <Switch>
      <Route path="/minha-area/proposta/:id" component={PropostaDetalhe} />
      <PrivateRoute path="/minha-area/proposta" component={PropostaDetalhe} />
      <Route path="/minha-area/usuario" component={UsuarioDados} />
    </Switch>
  );
};

export default MinhaAreaLayout;
