import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  orgaos: [],
  areas: [],
  andamentos:[],
  modalidades:[],
  chamamentos:[],
  impugnacao:[],
  proposta:[],
  categoria:[],
  andamentosRelatorio:[],
  orgaosProposta:[],
  setoresPorSecretaria:[],
  setoresSecretariaDoUsuario:[],
  orgaossemadm:[],
  setoresSemCoordenadores: [],
};

const cacheSlice = createSlice({
  name: "cache",
  initialState,
  reducers: {
    updateCache(state, action) {
      state[action.payload.cacheKey] = action.payload.items;
    }
  },
});

export const cacheActions = cacheSlice.actions;

export default cacheSlice.reducer;
