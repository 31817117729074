import React, { useContext, useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { FaPeopleCarry } from "react-icons/fa";
import { GiBugleCall } from "react-icons/gi";
import { GoLaw } from "react-icons/go";
import { IoDocumentText } from "react-icons/io5";
import { AppContext } from "../context/AppContext";
import { useHistory } from "react-router-dom";

const Home = () => {
  const { setBackgroundClassList } = useContext(AppContext);
  const history = useHistory();

  useEffect(() => {
    setBackgroundClassList(["background-1"]);
  });

  return (
    <>
      <Container id="cabecalho-pagina" fluid>
        <Row>
          <Col className="d-flex flex-column">
            <h1 className="presentation-title text-center p-0 m-0 text-shadow-orange">
              Portal OSC <br /> RN
            </h1>
            <h2 className="presentation-subtitle text-center p-0 m-0 text-shadow">
              Parcerias com Organizações da Sociedade Civil - OSC
            </h2>
          </Col>
        </Row>
      </Container>
      <Container id="conteudo-pagina-inicial">
        <Row className="d-flex flex-row g-4">
          <Col md="6">
            <div className="jumbotron bg-light">
              <h1>
                <span className="text-primary">Bem</span>-vindo(a)!
              </h1>
              <p className="lead mt-3 mb-3 text-justify">
                O Governo do Estado do Rio Grande do Norte e a sociedade civil estão em constante colaboração,
                desenvolvendo atividades e projetos nas mais diversas áreas de relevância pública.
              </p>
              <p className="mt-5">
                <Button variant="primary btn-lg" onClick={() => history.push("/sobre")}>
                  Saiba mais
                </Button>
              </p>
            </div>
          </Col>
          <Col md="6">
            <h3 className="mb-3 mt-0 mp-0">
              <span className="text-primary">Parcerias</span> com OSC
            </h3>

            <p className="text-justify">
              São parcerias nas quais o setor público e as denominadas OSC (Organizações da Sociedade Civil) se
              comprometem a agir, conjuntamente, em favor de específicos objetivos de interesse coletivo.
            </p>

            <p className="text-justify">
              Para dar transparência às informações relativas a essas parcerias, o Estado do Rio Grande do Norte
              desenvolveu este portal, no qual tramitarão os procedimentos destinados à celebração e ao acompanhamento
              da execução de todas as parcerias celebradas com as OSC.
            </p>

            <p className="text-justify">
              Além disso, o Portal tem vários instrumentos de participação social, permitindo, por exemplo, que qualquer
              cidadão apresente proposta de parceria (Manifestações de Interesse Social) ou ofereça contribuição a
              propostas previamente apresentadas, observando as regras e prazos fixados.
            </p>

            <p className="text-justify">
              Este é o Portal de Parcerias Sociais. E aqui você encontrará tudo sobre as Parcerias com OSC. Boa
              navegação!
            </p>
          </Col>
        </Row>
        <Row className="text-center mt-4 g-4 g-lg-4">
          <Col md="3">
            <div className="bg-light position-relative p-3 h-100">
              <div className="text-primary display-5">
                <FaPeopleCarry />
              </div>
              <h4 className="info-title mt-4">Manifestações de Interesse Sociais</h4>
              <p className="description mt-3 text-justify">
                Proposta de organização da sociedade civil (OSC), movimento social ou cidadão para o Poder Público
                realizar chamamento público.
              </p>
              <Button
                className="btn btn-sm mt-sm-3 btn-link w-75 position-absolute bottom-0 mb-3 translate-middle-x"
                onClick={() => history.push("/publicacoes/interessesocial")}
              >
                Faça sua Proposta
              </Button>
            </div>
          </Col>
          <Col md="3">
            <div className="bg-light position-relative p-3 h-100">
              <div className="text-primary display-5">
                <GiBugleCall />
              </div>
              <h4 className="info-title mt-4">Chamamentos Públicos</h4>
              <p className="description mt-3 text-justify">
                Destinado a selecionar, para a celebração de parceria com o Poder Público, a OSC mais apta à execução de
                determinada atividade ou projeto.
              </p>
              <Button onClick={() => history.push("/publicacoes/chamamentos")} className="btn btn-sm btn-link ">
                Ver mais
              </Button>
            </div>
          </Col>
          <Col md="3">
            <div className="bg-light position-relative p-3 h-100">
              <div className="text-primary display-5">
                <IoDocumentText />
              </div>
              <h4 className="info-title mt-4">Termos e Acordos</h4>
              <p className="description mt-5 text-justify">
                Parcerias celebradas entre o Governo do Estado e OSC, com seus respectivos instrumentos e aditivos.
              </p>
              <Button
                className="btn btn-sm mt-sm-3 btn-link position-absolute mb-3 bottom-0 translate-middle-x"
                onClick={() => history.push("/parcerias/termoseacordos")}
              >
                Ver mais
              </Button>
            </div>
          </Col>
          <Col md="3">
            <div className="bg-light p-3 position-relative h-100">
              <div className="text-primary display-5">
                <GoLaw />
              </div>
              <h4 className="info-title mt-4">Legislação OSC</h4>
              <p className="description mt-5 text-justify">
                Acompanhe e fique bem informado sobre nossas leis e decretos OSC
              </p>
              <Button
                onClick={() => history.push("/legislacao")}
                className="btn btn-sm mt-sm-3 btn-link position-absolute mb-3 bottom-0 translate-middle-x"
              >
                Ver mais
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Home;
