import React from "react";
import {Modal, ModalFooter} from "react-bootstrap";

const ConfirmarExclusaoModal = ({
  showModal,
  handleConfirm,
  handleCancel,
  title,
  bodyText,
  confirmButtonText,
  cancelButtonText,
}) => {
  return (
    <Modal show={showModal} onHide={handleCancel}>
      <Modal.Header>
        <Modal.Title style={{fontSize: "1.3rem"}}>{title}</Modal.Title>
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={handleCancel}
        ></button>
      </Modal.Header>
      <Modal.Body>{bodyText}</Modal.Body>
      <ModalFooter>
        <button
          style={{ backgroundColor: "#4CAF50", color: "white" }}
          type="button"
          className="btn-outline-danger"
          onClick={handleConfirm}
        >
          {confirmButtonText}
        </button>
        <button
          style={{ backgroundColor: "#F44336", color: "white" }}
          type="button"
          className="btn-outline-danger"
          onClick={handleCancel}
        >
          {cancelButtonText}
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmarExclusaoModal;
