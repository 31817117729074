import React from "react";
import {  Pagination } from "react-bootstrap";




const TabelPagination = (props) =>{

const {totalPages, activePage, handlePageChange} = props


const renderPaginationItems = () => {
    let items = [];
    for (let number = 1; number <= totalPages; number++) {
      items.push(
        <Pagination.Item key={number} active={number === activePage} onClick={() => handlePageChange(number)}>
          {number}
        </Pagination.Item>,
      );
    }
    return items;
  };


  return (
    <>
        <Pagination>
            {renderPaginationItems().map((item, index) => (
                <Pagination.Item
                    key={index}
                    active={item.props.active}
                    onClick={item.props.onClick}
                    activeLabel=""
                >
                {item.props.children}
                </Pagination.Item>
            ))}
        </Pagination>  
    </>
  )
}
export default TabelPagination;