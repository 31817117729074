import React, { useEffect, useContext, useRef } from "react";
import { Container, Row, Col, Alert } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { AppContext } from "../../context/AppContext";
import { useHistory } from "react-router";
import * as yup from "yup";
import { useFormik } from "formik";
import TextInput from "../../components/UI/Formik/TextInput/TextInput";
import SpinnerLabel from "../../components/UI/SpinnerLabel";
import ViaCepLoader from "../../components/UI/ViaCepLoader/ViaCepLoader";
import useApiClient, { apiRoutes } from "../../hooks/apiClient";
import {
  MunicipiosAsOptions,
  MunicipiosDoRN,
} from "../../components/UI/Formik/SelectInput/SelectInput.options";
import { useDispatch} from "react-redux";
import { authActions } from "../../redux/auth";
import { validCpf } from "../../components/UI/Formik/Validations/Validations";
import SelectInput from "../../components/UI/Formik/SelectInput/SelectInput";

const validationSchema = yup.object().shape({
  nome: yup.string().required("Informe seu nome completo "),
  login: yup.string().required("Informe o seu email "),
  cpf: yup
    .string()
    .matches("[0-9]{11}", "Somente números com 11 dígitos ")
    .required("Somente números com 8 dígitos ")
    .test(validCpf),
  logradouro: yup.string().required("Informe o logradouro de seu endereço "),
  numero: yup.string().required("Informe o número de seu endereço "),
  cidade: yup.string().required("Informe a cidade de seu endereço "),
  bairro: yup.string().required("Informe o bairro de seu endereço "),
  cep: yup
    .string()
    .matches("[0-9]{8}", "Somente números com 8 dígitos ")
    .required("Somente números com 8 dígitos "),
  telefone1: yup
    .string()
    .matches("[0-9]{10}", "Informe DDD e telefone com no mínimo 10 dígitos ")
    .required("Informe DDD e telefone com no mínimo 10 dígitos "),
});

const initialValues = {
  cnpj: "",
  razaosocial: "",
  logradouro: "",
  numero: "",
  complemento: "",
  bairro: "",
  cidade: "",
  cep: "",
  telefone1: "",
  telefone2: "",
  email: ""

};


const  AtualizarDadosOsc = () => {
  const { setBackgroundClassList } = useContext(AppContext);
  const history = useHistory();
  const numeroInputRef = useRef(null);
  const dispatch = useDispatch();
  //const auth = useSelector((state) => state.auth);


  const { request, data, errors, loading, requestId, requestExtra,  } = useApiClient();

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: (values, actions) => {
      actions.setStatus(null);
      request(apiRoutes.main.gestorOSC.UpdateOsc, values, {
        requestId: "salvar",
        requestExtra: { razaosocial: values.razaosocial },
      });
    },
  });

  useEffect(() => {
    setBackgroundClassList(["background-1"]);
  });

  useEffect(() => {
    request(apiRoutes.main.gestorOSC.CarregarOsc, null, {
      requestId: "carregar",
    });
  }, [request]);



  const { setValues, setSubmitting, setStatus } = formik;

  useEffect(() => {
    if (data && requestId === "carregar") setValues(data);
    if (requestId === "salvar") {
      setSubmitting(false);
      if (data) {
        dispatch(authActions.updateUser(requestExtra));
        alert("Dados atualizados com sucesso!!!!");
        history.goBack();
      }
      if (errors) setStatus("Verifique as informações e tente novamente. ");
    }
  }, [
    data,
    errors,
    requestId,
    requestExtra,
    history,
    dispatch,
    setValues,
    setSubmitting,
    setStatus,
  ]);

  const readOnly = loading || formik.isSubmitting;

  return (
    <>
      <Container id="cabecalho-pagina" fluid>
        <Row>
          <Col className="d-flex flex-column text-center mt-5">
            <h1 className="text-white text-shadow mt-5">Atualizar Dados OSC</h1>
          </Col>
        </Row>
      </Container>

      <Container id="conteudo-pagina-interna">
        <Row>
          <Col className="mt-5">
            <Form>
              <Row className="d-flex g-4">
                <Col lg="12" md="12" sm="12" xs="12">
                  <h3 className="my-3">Dados da OSC</h3>
                </Col>

                {formik.status && (
                  <Alert variant="danger">{formik.status}</Alert>
                )}

                <Col lg="6" md="6" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    field="razaosocial"
                    label="Razão Social:"
                    readOnly={readOnly}
                  />
                </Col>

                <Col lg="6" md="6" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    field="email"
                    label="E-mail:"
                    readOnly={readOnly}
                  />
                </Col>
                <Col lg="6" md="6" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    field="cnpj"
                    label="CNPJ:"
                    readOnly={readOnly}
                    maxLength={11}
                  />
                </Col>

                <Col lg="3" md="3" sm="11" xs="11">
                  <TextInput
                    formik={formik}
                    field="cep"
                    label="CEP:"
                    readOnly={readOnly}
                    maxLength={8}
                  />
                </Col>

                {!readOnly && (
                  <Col lg="3" md="3" sm="1" xs="1" style={{ alignSelf: "end" }}>
                    <ViaCepLoader
                      disabled={!formik.values.cep || !!formik.errors.cep}
                      cep={formik.values.cep}
                      onFound={(data) => {
                        const { logradouro, bairro, ibge } = data;
                        formik.setValues({
                          ...formik.values,
                          logradouro: logradouro,
                          bairro: bairro,
                          cidade: MunicipiosDoRN[ibge],
                        });
                        numeroInputRef.current.focus();
                      }}
                    />
                  </Col>
                    )}

                <Col lg="10" md="10" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    field="logradouro"
                    label="Logradouro:"
                    maxLength={250}
                    readOnly={readOnly}
                  />
                </Col>

                <Col lg="2" md="2" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    ref={numeroInputRef}
                    field="numero"
                    label="Número:"
                    readOnly={readOnly}
                  />
                </Col>

                <Col lg="12" md="12" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    field="complemento"
                    label="Complemento:"
                    readOnly={readOnly}
                  />
                </Col>

                <Col lg="6" md="6" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    field="bairro"
                    label="Bairro:"
                    readOnly={readOnly}
                  />
                </Col>

                <Col lg="6" md="6" sm="12" xs="12">
                  <SelectInput
                    formik={formik}
                    field="cidade"
                    label="Cidade:"
                    readOnly={readOnly}
                  >
                    <MunicipiosAsOptions />
                  </SelectInput>
                </Col>


                <Col lg="6" md="6" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    field="telefone1"
                    label="Telefone 1:"
                    readOnly={readOnly}
                  />
                </Col>
                <Col lg="6" md="6" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    field="telefone2"
                    label="Telefone 2:"
                    readOnly={readOnly}
                  />
                </Col>


              </Row>
              <Row>
                <Col className="d-flex justify-content-center mt-5">
                  <Button className="mt-3" onClick={() => history.goBack()}>
                    Voltar
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    className="mt-3"
                    disabled={readOnly}
                    onClick={formik.handleSubmit}
                  >
                    <SpinnerLabel
                      loading={formik.isSubmitting}
                      label="Salvar"
                    />
                  </Button>
                  &nbsp;&nbsp;
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AtualizarDadosOsc;
