import { Form } from "react-bootstrap";

const DateInput = (props) => {
  const { formik, field, label, placeholder, readOnly, maxLength,disabled} = props;
  return (
    <Form.Group>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        id={field}
        type={"date"}
        placeholder ={placeholder}
        name={field}
        value={formik.values[field]}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        isInvalid={!!formik.errors[field] && formik.touched[field]}
        readOnly={readOnly}
        disabled={disabled}
        maxLength={maxLength}
      />
      <Form.Control.Feedback type="invalid">
        {formik.errors[field]}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default DateInput;
