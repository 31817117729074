import React, { useEffect } from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import Table from 'react-bootstrap/Table'




const MisConsultaPublica = () => {
    useEffect(() => {

        //background personalizado
        document.body.classList.remove('bg-cover');
        document.body.classList.add('background-3');

    });

    return (
        <>

            <Container id="cabecalho-pagina">
                <Row>
                    <Col className="d-flex flex-column text-center">
                        <h1 className="text-white text-shadow-orange mt-5">MiS em consulta Pública</h1>

                    </Col>

                </Row>
            </Container>

            <Container id="conteudo-pagina-interna">


                <Row>
                    <Row>
                        <Col className="text-center">
                            <h4 className=" mt-lg-5 mb-lg-5 mt-5">Aqui você poderá consultar as MIS que estão em consulta Pública
                                basta selecionar a área e será apresentado na tabela abaixo todas as MIS da respectiva área.</h4>
                        </Col>
                    </Row>
                    <Col>
                        <Form>
                            <Row className="mt-5">
                                <Col>
                                    <h5>Busca por Área</h5>
                                </Col>
                            </Row>

                            <Row>
                                <Col lg="10" md="10" sm="12">
                                    <Form.Group>
                                        <Form.Control as="select">
                                            <option>Todos</option>
                                            <option value="1">Assistência Social</option>
                                            <option value="2">Acessibilidade e Inclusão</option>
                                            <option value="3">Ciência e Tecnologia</option>
                                            <option value="4">Cultura</option>
                                            <option value="5">Desenvolvimento Econômico</option>
                                            <option value="6">Direitos Humanos e Cidadania</option>
                                            <option value="7">Educação</option>
                                            <option value="8">Energia e Saneamento Básico</option>
                                            <option value="9">Esporte e lazer</option>
                                            <option value="10">Habitação</option>
                                            <option value="11">Meio Abiente e Sustentabilidade</option>
                                            <option value="12">Mobilidade e transporte</option>
                                            <option value="13">Pesquisas</option>
                                            <option value="14">Saúde</option>
                                            <option value="15">Segurança Pública</option>
                                            <option value="16">outros</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col lg="2" md="2" sm="12">
                                    <Form.Group>
                                        <Button variant="primary" className="w-100 mt-4 mt-md-0">Pesquisar</Button>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col >
                        <div style={{ overflowX: 'auto' }}>
                            <Table size="sm" striped bordered hover w-100 table-responsive text-center>
                                <thead>
                                    <tr>
                                        <th>Detalhe</th>
                                        <th>Protocolo</th>
                                        <th>Nome da Proposta</th>
                                        <th>Proponente</th>
                                        <th>Área de Atuação</th>
                                        <th>Submetida Em</th>
                                        <th>Situação</th>
                                    </tr>
                                </thead>
                            </Table>
                        </div>
                    </Col>
                </Row>
            </Container>

        </>
    )
}

export default MisConsultaPublica;