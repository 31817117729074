import { useFormik } from "formik";
import React, { useContext, useEffect} from "react";
import { Col, Container, Row, Button, Form, OverlayTrigger, Tooltip} from "react-bootstrap";
import * as yup from "yup";
import TextInput from "../../components/UI/Formik/TextInput/TextInput";
import SpinnerLabel from "../../components/UI/SpinnerLabel";
import { AppContext } from "../../context/AppContext";
import useApiClient, { apiRoutes } from "../../hooks/apiClient";
import { useHistory, useParams } from "react-router";
import SelectFileInputGeneral from "../../components/UI/SelectFileInput/SelectFileInputGeneral";
import { toast, ToastContainer, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TextareaInput from "../../components/UI/Formik/TextareaInput/TextareaInput";
import SelectInputCached from "../../components/UI/Formik/SelectInput/SelectInput.cached";
import { useSelector } from "react-redux";

const validationSchema = yup.object().shape({
  titulo: yup.string().required("Informe o titulo para seu relatório."),
  IdCategoria: yup.string().required("Informe a categoria do seu relatório."),
  descricao: yup.string().max(500, 'A descrição deve ter no máximo 500 caracteres.').required("Informe uma descrição resumida para seu relatório."),
});

const initialValues = {
  titulo: "",
  descricao: "",
  IdCategoria: "",
  IdSecretaria: "",
  Arquivo: null,
  ArquivoNome: null,
};

const Reportar = () => {
  const { setBackgroundClassList } = useContext(AppContext);
  const { request, data, errors, loading } = useApiClient();
  const auth = useSelector((state) => state.auth);
  const history = useHistory();
  initialValues.IdSecretaria = auth && auth.user ? auth.user.secretaria : "";

  useEffect(() => {
    setBackgroundClassList(["background-1"]);
  });

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: (values) => {
      request(apiRoutes.main.relatorio.salvar, values, {
        requestId: "salvar",
      });
    },
  });

  const handleArquivo = (uploadPayload) => {
    formik.setValues((curr) => ({
      ...curr,
      Arquivo: uploadPayload.arquivo,
      ArquivoNome: uploadPayload.arquivoNome,
    }));
  };

  const { setSubmitting, isSubmitting } = formik;

  useEffect(() => {
    if (data && !errors) {
      toast.success('Relátorio enviado com Sucesso!!! enviaremos a resposta via Email.', {
        position: "top-center",
        autoClose: 3500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
        onClose: () => {
          setSubmitting(false);
          window.location.reload();
        }
      });
    }

    if (errors) {
      toast.error(errors.message || "algo deu errado!!", {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
        onClose: () => {
          setSubmitting(false);
        }
      });
    }

  }, [data, errors, history]);


  const readOnly = loading || formik.isSubmitting;

  return (
    <>
      <Container id="cabecalho-pagina" fluid>
        <Row>
          <Col className="d-flex flex-column text-center mt-5">
            <h1 className="text-white text-shadow mt-5">Relátorio</h1>
          </Col>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
        </Row>
      </Container>

      <Container id="conteudo-pagina-interna">
      <Row>
          <div className="alert alert-info mt-5" role="alert">
                <h4 className="alert-heading">Instruções</h4>
                <p>Nesta página, você poderá relatar os problemas enfrentados ao utilizar o portal, bem como sugerir melhorias. Lembre-se de que a resposta ao seu relatório será enviada para o seu e-mail cadastrado.</p>
          </div>
        </Row>
        <Form>
          <Row className="d-flex g-4 mt-4">
            <Col md="12" sm="12" xs="12">
              <h2 className="my-3">Detalhes do Relatório</h2>
            </Col>

            <Col lg="12" md="12" sm="12" xs="12">
              <TextInput formik={formik} field="titulo" label="Titulo:" placeholder="Adicione um titulo ao seu relátorio." readOnly={readOnly} disabled={isSubmitting} />
            </Col>
            <Col lg="12" md="12" sm="12" xs="12">
              <SelectInputCached
                formik={formik}
                field="IdCategoria"
                label="Especifique a categoria do relatório"
                cacheKey="categoria"
                readOnly={readOnly}
                disabled={isSubmitting}
                categoriaRelatorio
                allowEmpty
              />
            </Col>

            <Col lg="12" md="12" sm="12" xs="12" className="mt-4">
              <TextareaInput
                formik={formik}
                field="descricao"
                label="Descrição do relátorio."
                placeholder="Por favor, descreva sucintamente a dificuldade que você está enfrentando ou a sugestão que deseja enviar para a gestão."
                disabled={isSubmitting}
                rows={5}
              />
            </Col>
          </Row>
          <Row>
            <Col lg="12" md="12" sm="12" xs="12">
              <h3 className="my-3">Fudamentação do relatório</h3>
            </Col>
            <Col lg="12" md="12" sm="12" xs="12">
              <p>
                Nesta seção, você tem a opção de anexar um arquivo para fundamentar o relatório de forma mais detalhada. Este arquivo pode ser uma foto do erro que você está enfrentando ou o documento legal que fundamenta sua sugestão. Para anexar um arquivo, clique em 'Selecionar Arquivo'.
              </p>
            </Col>
            <Col className="mt-2" lg="12" md="6" sm="6" xs="12">
              <SelectFileInputGeneral
                ArquivoRelatorio
                disabled={loading}
                show={!readOnly}
                showListfile={!readOnly}
                field="arquivonovo"
                handleArquivo={handleArquivo}
              />
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-center mt-5">
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip className="medium mt-1">Salvar no sistema</Tooltip>}>
                <Button
                  className="mt-3"
                  disabled={readOnly}
                  onClick={() => formik.handleSubmit()}
                >
                  <SpinnerLabel
                    loading={formik.isSubmitting}
                    label="Salvar"
                  />
                </Button>
              </OverlayTrigger>
              &nbsp;&nbsp;
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip className="medium mt-1">Voltar para página anterior</Tooltip>}>
                <Button className="mt-3" disabled={formik.isSubmitting} onClick={() => history.goBack()}>
                  Voltar
                </Button>
              </OverlayTrigger>
            </Col>
          </Row>
        </ Form>
      </Container>
    </>
  )
}

export default Reportar;
