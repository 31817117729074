import React, { useRef, useState, useEffect } from "react";
import useApiClient from "../../../hooks/apiClient";
import { Button, Form, Table, Row, Col } from "react-bootstrap";
import { RiDeleteBin2Fill } from "react-icons/ri";
import { toast, ToastContainer, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SelectFileInputGeneral = (props) => {
  const {
    accept,
    show,
    showListfile,
    error,
    defaultPayload,
    onFinishUpload,
    field,
    handleArquivo,
    ArquivoRelatorio
  } = props;
  const fileInput = useRef();
  const [uploadPayload, setUploadPayload] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadError, setUploadError] = useState(null);

  const { request, data, errors, requestId } = useApiClient();

  useEffect(() => {
    if (uploadPayload) handleArquivo(uploadPayload);
  }, [request, uploadPayload]);

  useEffect(() => {
    if (data) {
      onFinishUpload();
    }
    if (errors) setUploadError(errors.message);
    if (data || errors) setUploading(false);
  }, [data, errors, requestId, onFinishUpload]);

  const onFileChange = (event) => {
    event.preventDefault();
    let payload = {};

    const fileProcessed = () => {
      setUploadPayload(payload);
      event.target.files = new DataTransfer().files;
    };

    let file_reader = new FileReader();
    let file = event.target.files[0];

    if (!file) return;
    setUploading(true);
    if (file.size > 100 * 1024 * 1024) {
      setUploadError("O tamanho do arquivo excede o limite máximo permitido de 100 MB.");
      toast.error(
        uploadError || 'O tamanho do arquivo excede o limite máximo permitido de 100 MB.',
        {
          position: "top-center",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
        }
      );
      return;
    }
    if (!ArquivoRelatorio) {
      if (file.type !== 'application/pdf') {
        setUploadError("Por favor, selecione um arquivo PDF.");
        toast.error(
          uploadError || 'Por favor, selecione um arquivo PDF.',
          {
            position: "top-center",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
          }
        );
        return;
      }
    }


    file_reader.onload = () => {
      payload = {
        ...defaultPayload,
        arquivoNome: file.name,
        arquivo: file_reader.result.substring(
          file_reader.result.indexOf(",") + 1
        ),
      };

      fileProcessed();
    };

    file_reader.onerror = () => {
      payload = {
        ...defaultPayload,
        arquivoNome: file.name,
        error: file_reader.error,
      };

      fileProcessed();
    };

    file_reader.readAsDataURL(file);
  };

  const handleRemoveFile = () => {
    setUploadPayload(null);
    fileInput.current.value = "";
  };

  if (!show) return null;


  return (
    <>
      <Row>
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <Col lg="6" md="6" sm="12" xs="12" >
          <div>{uploadError}</div>
          <Button
            variant="outline-primary"
            size="sm"
            onClick={() => {
              setUploadError(null);
              fileInput.current.click();
            }}
          >
            Selecionar Arquivo
          </Button>
          <Form.Control
            type="file"
            id={field}
            name={field}
            style={{ display: "none" }}
            ref={fileInput}
            accept={accept}
            onChange={(event) => onFileChange(event)}
            isInvalid={error}
          />
        </Col>
        <Col lg="6" md="6" sm="12" xs="12" >
          {uploadPayload && showListfile ? (
            <div style={{ overflowX: 'auto' }}>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Arquivo</th>
                    <th>Remover</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{uploadPayload.arquivoNome}</td>
                    <td>
                      <RiDeleteBin2Fill size={20} color="red" onClick={handleRemoveFile} style={{ cursor: "pointer" }} />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          ) : (
            ""
          )}
          {error && (
            <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
          )}
        </Col>
      </Row>
    </>
  );
};

export default SelectFileInputGeneral;
