import React, { useEffect, useState } from "react";
import { Alert, Col, Container, Row, Spinner, Form, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { FormatDate } from "../../../components/UI/Format/Format";
import useApiClient, { apiRoutes } from "../../../hooks/apiClient";
import TabelPagination from "../../../components/pagination/TabelPaginatio";
import SelectInputCached from "../../../components/UI/Formik/SelectInput/SelectInput.cached.js";
import { useFormik } from "formik";
import ConfirmarExclusaoModal from "../../../components/Modal/ConfirmarExclusaoModal";
import { FaUserAltSlash } from "react-icons/fa";
import { toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const initialValues = {
  orgao: 0,
};

const SalvarPesquisa = {
  orgao: 0,
};

const ListarAdms = () => {
  const { request, data, errors, loading, requestId } = useApiClient();
  const [showSpinner, setShowSpinner] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [totalPages, settotalPages] = useState(0);
  const [offset, setoffset] = useState(0);
  const [paginatedData, setPaginatedData] = useState([]);
  const [offsetPesquisa, setoffsetPesquisa] = useState(0);
  const [limparPesquisa, setlimparPesquisa] = useState(true);
  const [IdAdm, setIdAdm] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const itemsPerPage = 5;

  useEffect(() => {
    request(apiRoutes.main.adm.listarAdms,
      {
        routeParams: {
          limit: itemsPerPage,
          offset: offset
        }
      },
      { requestId: "carregar" }
    );
  }, [request]);

  useEffect(() => {
    if (!limparPesquisa) {
      request(apiRoutes.main.adm.pesquisarAdm,
        {
          routeParams: {
            limit: itemsPerPage,
            offset: offsetPesquisa,
            orgao: SalvarPesquisa.orgao,
          }
        },
        { requestId: "pesquisar" }
      );
    }
  }, [offsetPesquisa]);


  const FiltrarPesquisa = (values) => {
    SalvarPesquisa.orgao = values.orgao;
    setlimparPesquisa(false);
    request(apiRoutes.main.adm.pesquisarAdm,
      {
        routeParams: {
          limit: itemsPerPage,
          offset: offsetPesquisa,
          orgao: SalvarPesquisa.orgao,
        }
      },
      { requestId: "pesquisar" }
    );
  };

  const formik = useFormik({
    initialValues,
    onSubmit: FiltrarPesquisa,
  });

  const limparCampos = () => {
    setShowSpinner(true)
    formik.setValues({
      orgao: 0,
    });
    SalvarPesquisa.orgao = 0;
    setlimparPesquisa(true)
    setoffsetPesquisa(0)
    setoffset(0)
    request(apiRoutes.main.adm.listarAdms,
      {
        routeParams: {
          limit: itemsPerPage,
          offset: 0
        }
      },
      { requestId: "carregar" }
    );
  };


  useEffect(() => {
    if (data && requestId === "carregar") {
      setTimeout(() => {
        setPaginatedData(data);
        settotalPages(data && data.length > 0 ? Math.ceil(data[0].totalitens / itemsPerPage) : 0);
        setShowSpinner(false);
      }, 700);
    }

    if (data && requestId === "pesquisar") {
      setShowSpinner(true);
      setPaginatedData(data);
      settotalPages(data && data.length > 0 ? Math.ceil(data[0].totalitens / itemsPerPage) : 0);
      setTimeout(() => {
        setShowSpinner(false);
      }, 900);
    }

    if (data && requestId === "remover") {
      toast.success("ADM desativado com sucesso!!!", {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
        onClose: () => {
          window.location.reload();
        },
      });
    }

    if (errors) {
      setShowSpinner(false);
      toast.error(errors.message || "Desculpe, algo deu errado!!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
        onClose: () => {
          setShowSpinner(false);
          window.location.reload();
        },
      });
    }
  }, [requestId, data]);



  const handlePageChange = (pageNumber) => {
    setShowSpinner(true);
    setActivePage(pageNumber);
    const newOffset = ((pageNumber - 1) * itemsPerPage)
    if (!limparPesquisa) {
      setoffsetPesquisa(newOffset);
    } else {
      setoffset(newOffset);
    }
  };

  const handleDeleteConfirm = () => {
    if (IdAdm) {
      request(
        apiRoutes.main.adm.removerAdm,
        {
          routeParams: { IdAdm },
        },
        { requestId: "remover" }
      );
    }
    setShowModal(false);
  };
  const handleCancelDelete = () => {
    setShowModal(false);
    setShowSpinner(false);
  };

  

  return (
    <>
      <Container className="conteudo-pagina mt-5">
        <Row>
          <Col>
            <h3 className="mt-4 fw-bold">Administradores</h3>
            {errors && (
              <Alert variant="danger">
                Desculpe, ocorreu um erro durante a recuperação das propostas.
                Tente novamente mais tarde.
              </Alert>
            )}
          </Col>
        </Row>

        <>
          <Row>
            <Col>
              <p>
                  Nesta seção, são listados todos os administradores cadastrados no portal OSC. Para desativar um administrador, clique no ícone de usuário localizado na coluna "Desativar". Note que a desativação de um usuário é um processo irreversível.
              </p>
            </Col>
          </Row>
          <Row>
            <Col className="bg-light rounded p-3">
              <Form>
                <Row>
                  <Col lg="7" md="12" sm="12" xs="12">
                    <SelectInputCached
                      formik={formik}
                      field="orgao"
                      label="Pesquisar Administrador por secretaria ou autarquia"
                      cacheKey="orgaos"
                    />
                  </Col>
                  <Col className="d-flex flex-row mt-3 justify-content-center">
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip className="medium mt-1">Pesquisar por parâmetro selecionado</Tooltip>}>
                      <Button variant="primary" className="me-3" onClick={formik.handleSubmit}>
                        Pesquisar
                      </Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip className="medium mt-1">Limpar resultados e parâmetro da pesquisa</Tooltip>}>
                      <Button variant="link" className="ms-3" onClick={limparCampos}>
                        Limpar
                      </Button>
                    </OverlayTrigger>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          {showSpinner && (
            <Row>
              <Col className="d-flex flex-row mt-5 justify-content-center">
                <h5 className="mb-2">
                  <Spinner
                    animation="border"
                    size="lg"
                    variant="warning"
                  />
                </h5>
              </Col>
            </Row>
          )}
          {!errors && !loading && !showSpinner && (
            <Row>
              <Col>
                <div style={{ overflowX: 'auto' }}>
                  <Table striped bordered hover className="mt-4">
                    <thead>
                      <tr>
                        <th>Nome</th>
                        <th>Secretaria</th>
                        <th>Último Acesso</th>
                        <th>Desativar</th>
                      </tr>
                    </thead>
                    <tbody>

                      {(paginatedData || {}) &&
                        paginatedData.map((item) => (
                          <tr
                            key={item.id_usuario_adm}
                          >
                            <td>{item.tx_nome}</td>
                            <td>{item.tx_orgao}</td>
                            <td>{FormatDate(item.tm_ultimo_acesso) ? FormatDate(item.tm_ultimo_acesso) : "O usuário ainda não realizou o primeiro acesso."}</td>
                            <td className="text-center mt-2">
                              <OverlayTrigger
                                placement="bottom"
                                overlay={<Tooltip className="medium mt-1">Desativar Usuário</Tooltip>}>
                                <FaUserAltSlash
                                  style={{ cursor: "pointer" }}
                                  size={20}
                                  color="red"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setShowSpinner(true);
                                    setIdAdm(item.id_usuario_adm);
                                    setShowModal(true);
                                  }}
                                />
                              </OverlayTrigger>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>
                {totalPages > 1 && (
                  <TabelPagination totalPages={totalPages} activePage={activePage} handlePageChange={handlePageChange} />
                )}
              </Col>
            </Row>)}
          {!showSpinner && paginatedData && paginatedData.length === 0 && (
            <Alert className="mt-0" variant="primary">Nenhum registro encontrado</Alert>
          )}
        </>
      </Container>
      <ConfirmarExclusaoModal
        showModal={showModal}
        setShowModal={setShowModal}
        handleConfirm={handleDeleteConfirm}
        handleCancel={handleCancelDelete}
        title="Confirmação de desativação"
        bodyText="Atenção: A desativação de um usuário é um processo irreversível. Você tem certeza de que deseja prosseguir com a desativação deste usuário?"
        confirmButtonText="Confirmar"
        cancelButtonText="Cancelar"
      />
    </>
  );
};

export default ListarAdms;
