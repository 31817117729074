import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Card, Button, Alert, Container, Spinner, OverlayTrigger, Tooltip } from "react-bootstrap";
import { AiOutlineCopy } from "react-icons/ai";
import { FormatDate } from "../../../components/UI/Format/Format";
import { useFormik } from "formik";
import { BsBoxArrowInRight } from "react-icons/bs";
import useApiClient, { apiRoutes } from "../../../hooks/apiClient";
import TextInput from "../../../components/UI/Formik/TextInput/TextInput";
import { useHistory } from "react-router";
import { toast, ToastContainer, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import appConfig from "../../../config/app.config";
import { FormatarSEI } from "../../../components/UI/Format/Format";
import Inputmask from "inputmask";
import * as yup from "yup";

const validationSchema = yup.object().shape({
  ProcessoSei: yup.string().transform((value) => value.replace(/[^0-9]/g, '')) 
  .length(20, "O Número do processo SEI deve ter exatamente 20 caracteres"),
});

const initialValues = {
  ProcessoSei: "",
};

const ChamamentoLista = () => {
  const { request, data, errors, loading, requestId } = useApiClient();
  const [paginatedData, setPaginatedData] = useState([]);
  const [showSpinner, setShowSpinner] = useState(true);
  const [showSpinnerhandleLoadMore, setshowSpinnerhandleLoadMore] = useState(false);
  const [itemsToShow, setItemsToShow] = useState(10);
  const [PageOffset, setPageOffset] = useState(0);
  const [TotalItens, setTotalItens] = useState(0);
  const history = useHistory();
  const tipo = "chamamento";


  useEffect(() => {
    if (requestId !== "pesquisar") {
      request(apiRoutes.main.chamamentoPublico.listarChamamento,
        {
          routeParams: {
            limit: itemsToShow,
            offset: PageOffset
          }
        },
        { requestId: "carregar" }
      );
    }
  }, [requestId, request]);

  useEffect(() => {
    const SEIMask = Inputmask({
      mask: "99999999.999999/9999-99",
      placeholder: " ",
      numericInput: true,
      rightAlign: false,
      allowMinus: false,
      positionCaretOnClick: "none",
    });

    SEIMask.mask(document.getElementById("ProcessoSei"));
  }, []);

  const FiltrarPesquisa = (values) => {
    values.ProcessoSei = values.ProcessoSei.replace(/[^\d]+/g, "");
    setShowSpinner(true);
    setPageOffset(0)
    request(apiRoutes.main.chamamentoPublico.publicoPesquisarChamamento,
      {
        routeParams: {
          limit: itemsToShow,
          offset: 0,
          sei: values.ProcessoSei
        }
      },
      { requestId: "pesquisar" }
    );
  };

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: FiltrarPesquisa,
  });

  const limparCampos = () => {
    setShowSpinner(true);
    setPageOffset(0)
    formik.resetForm();
    request(apiRoutes.main.chamamentoPublico.listarChamamento,
      {
        routeParams: {
          limit: itemsToShow,
          offset: PageOffset
        }
      },
      { requestId: "limpar" })
  };

  useEffect(() => {
    if (data && requestId === "carregar") {
      setTimeout(() => {
        setPaginatedData(data);
        setTotalItens(data.length > 0 ? data[0].totalitens : [])
        setShowSpinner(false);
      }, 1000);

    }

    if (data && requestId === "limpar") {
      setTimeout(() => {
        setTotalItens(data.length > 0 ? data[0].totalitens : [])
        setPaginatedData(data);
        setShowSpinner(false);
      }, 1000);
    }

    if (data && requestId === "pesquisar") {
      setTimeout(() => {
        setPaginatedData(data);
        setTotalItens(data.length > 0 ? data[0].totalitens : [])
        setShowSpinner(false);
      }, 1000);
    }
  }, [data, requestId]);

  useEffect(() => {
    if ((data || errors) && !loading && formik.isSubmitting) {
      formik.setSubmitting(false);
    }
  }, [data, errors, loading]);

  const getStatusClass = (id) => {
    switch (id) {
      case 1:
        return "bnt-ativo rounded-pill btn-round bnt-status";
      case 2:
        return "bnt-encerrado rounded-pill btn-round bnt-status";
      case 3:
        return "bnt-aguardando rounded-pill btn-round bnt-status";
      case 4:
        return "bnt-parceria_em_andamento rounded-pill btn-round bnt-status";
      case 5:
        return "bnt-chamamento-aberto-para-impugnacao rounded-pill btn-round bnt-status";
      case 6:
        return "bnt-chamamento_fechado_para_impugnacao rounded-pill btn-round bnt-status";
      case 7:
        return "bnt-encerrado_dipensa_mantida rounded-pill btn-round bnt-status";
      case 8:
        return "bnt-encerrado_dipensa_revogada rounded-pill btn-round bnt-status";
      case 9:
        return "bnt-encerrado rounded-pill btn-round bnt-status";
      case 10:
        return "bnt-cancelado rounded-pill btn-round bnt-status";
      default:
        return "";
    }
  };

  const handleLoadMore = (e) => {
    e.preventDefault();
    const newOffset = PageOffset + 10;
    setshowSpinnerhandleLoadMore(true);
    if (requestId === "pesquisar") {
      fetch(`${appConfig.host}/api/chamamentopublico/pesquisar?limit=${itemsToShow}&offset=${newOffset}&idsecretaria=${formik.values.IdSecretaria}&idtipochamamento=${formik.values.IdTipoChamamento}`)
        .then(response => {
          if (!response.ok) {
            throw new Error('Erro ao carregar dados.');
          }
          return response.json();
        })
        .then(data => {
          setPageOffset(newOffset);
          setTimeout(() => {
            setshowSpinnerhandleLoadMore(false);
            setPaginatedData(prevData => [...prevData, ...data]);
          }, 900);
        })
        .catch(error => {
          console.error('Erro na requisição:', error);
          setshowSpinnerhandleLoadMore(false);
        });
    } else {
      fetch(`${appConfig.host}/api/chamamentopublico/listar/todos?limit=${itemsToShow}&offset=${newOffset}`)
        .then(response => {
          if (!response.ok) {
            throw new Error('Erro ao carregar dados.');
          }
          return response.json();
        })
        .then(data => {
          setPageOffset(newOffset);
          setTimeout(() => {
            setshowSpinnerhandleLoadMore(false);
            setPaginatedData(prevData => [...prevData, ...data]);
          }, 900);
        })
        .catch(error => {
          console.error('Erro na requisição:', error);
          setshowSpinnerhandleLoadMore(false);
        });
    }
  };



  const processo_copiado = () => {
    toast.info('Número do processo SEI copiado!!', {
      position: "bottom-right",
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      transition: Bounce,
    });
  };



  if (errors) {
    return (
      <Row className="mt-4">
        <Col>
          <Alert variant="danger">
            Desculpe, ocorreu um erro durante a pesquisa. Tente novamente mais tarde.
          </Alert>
        </Col>
      </Row>
    );
  }

  return (
    <>
      <Container>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <Row>
          <Col className="d-flex flex-column">
          <Row className="bg-light rounded p-3">
             <Col lg="8" md="12" sm="12" xs="12">
                <TextInput
                  formik={formik}
                  field="ProcessoSei"
                  label="Pesquisar chamamento pelo processo SEI:"
                />
              </Col>
              <Col className="d-flex flex-row mt-3 justify-content-center">
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip className="medium mt-1">Pesquisar por parâmetro selecionado</Tooltip>}>
                  <Button variant="primary" className="me-3" onClick={formik.handleSubmit}>
                    Pesquisar
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip className="medium mt-1">Limpar resultados e parâmetro da pesquisa</Tooltip>}>
                  <Button variant="link" className="ms-3" onClick={limparCampos}>
                    Limpar
                  </Button>
                </OverlayTrigger>
              </Col>
            </Row>
          </Col>
        </Row>
        {!loading && data && !showSpinner && data.length === 0 && (
          <Alert className="mt-2" variant="primary">Nenhum registro encontrado</Alert>
        )}
        {showSpinner && (
          <Row>
            <Col className="d-flex flex-row mt-5 justify-content-center">
              <h5 className="mb-2">
                <Spinner animation="border" size="lg" variant="warning" />
              </h5>
            </Col>
          </Row>
        )}

        {!loading &&
          data &&
          !showSpinner &&
          paginatedData.map((item) => (
            <Row key={item.id_chamamento_publico}>
              <Col>
                <Card className="card-border mt-3">
                  <Card.Img variant="top" />
                  <Card.Body>
                    <Row className="justify-content-between">
                      <Col md="12" lg="8">
                        <Card.Title className="h4">{item.tx_orgao} </Card.Title>
                        <Card.Subtitle className="h5 mt-2 mb-2">{item.tx_titulo} </Card.Subtitle>
                        <Card.Text className="mt-3 mb-3">{item.tx_objeto}</Card.Text>
                      </Col>
                      <Col
                        className=" d-flex flex-column align-items-center"
                      >
                        <Col className="d-flex flex-column align-items-center justify-content-start">
                          <Card.Subtitle>Publicação do edital:</Card.Subtitle>
                          <Card.Text className="description mb-1 text-center">
                            {FormatDate(item.tm_inclusao)}
                          </Card.Text>
                          <Card.Subtitle>Processo SEI: </Card.Subtitle>
                          <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip className="medium mt-1">Copiar númerdo do processo SEI</Tooltip>}>
                            <Card.Text
                              onClick={() => {
                                navigator.clipboard.writeText(item.n_processo);
                                processo_copiado();
                              }}
                            >
                              <span style={{ cursor: "pointer" }}>{FormatarSEI(item.n_processo)}<AiOutlineCopy /></span>

                            </Card.Text>
                            </OverlayTrigger>
                            <Card.Text className="mt-2 d-flex mb-3">
                              <OverlayTrigger
                                placement="bottom"
                                overlay={<Tooltip className="medium">{"Status da parceria" + " " + item.tx_nome}</Tooltip>}
                              >
                                <span className={`${getStatusClass(item.id_status_andamento_chamamento)}`}>
                                  {item.tx_nome + " "}
                                </span>
                                </OverlayTrigger>
                               
                                  {item.id_status_andamento_chamamento === 5 && item.tx_tipo === 'D' ? (
                                    <OverlayTrigger
                                      placement="bottom"
                                      overlay={<Tooltip className="medium mt-1">{"Enviar Impugnação"}</Tooltip>}
                                      >
                                      <span className={`${getStatusClass(item.id_status_andamento_chamamento)} ms-1 text-center p-2`}>

                                        <div className="mt-2">
                                        <BsBoxArrowInRight
                                          onClick={() => history.push(`/enviar/impugnacao/${item.id_chamamento_publico}`)}
                                          color="white"
                                          size={20}
                                          cursor={"pointer"}
                                        /></div></span>
                                    </OverlayTrigger>
                                    ) : (
                                      ""
                                    )}
                            </Card.Text>
                        </Col>
                      </Col>
                      <Card.Text>
                        <Button variant="link" onClick={(e) => {
                          e.preventDefault()
                          history.push(`/listar/arquivos-andamentos/${tipo}/${item.id_chamamento_publico}`)
                        }}>
                          Visualizar arquivos e andamentos.
                        </Button>
                      </Card.Text>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          ))}
        <Row>
          {showSpinnerhandleLoadMore && (
            <Row>
              <Col className="d-flex flex-row mt-2 justify-content-center">
                <h5 className="mb-2">
                  <Spinner animation="border" size="lg" variant="warning" />
                </h5>
              </Col>
            </Row>
          )}
          {data && !showSpinner && !showSpinnerhandleLoadMore && paginatedData.length < TotalItens && (
            <div className="d-flex justify-content-center">
              <Button onClick={handleLoadMore}>Carregar mais</Button>
            </div>
          )}
        </Row>

      </Container>
    </>
  );
};

export default ChamamentoLista;
