import React, { useContext, useEffect } from "react";
import { Col, Container, Row,} from "react-bootstrap";
import { IoDocumentText } from "react-icons/io5";
import { AppContext } from "../../context/AppContext";
import TermosEAcordosLista from "./TermosEAcordosLista";
import useApiClient, { apiRoutes } from "../../hooks/apiClient";


const initialValues = {
  grupo: "0",
};

const TermosEAcordos = () => {
  const { setBackgroundClassList } = useContext(AppContext);

  const { request, data, requestId } = useApiClient();

  useEffect(() => {
    request(apiRoutes.main.termosAcordos.listarTermosAcordosPublico);
  }, [request]);

  useEffect(() => {
    if (data && requestId === "carregar"){


    }
  }, [data]);

  useEffect(() => {
    setBackgroundClassList(["background-4"]);
  });




  return (
    <>
      <Container id="cabecalho-pagina">
        <Row>
          <Col className="d-flex flex-column text-center mt-5">
            <h1 className="text-white text-shadow-orange mt-5">
              Termos e Acordos
            </h1>
          </Col>
        </Row>
      </Container>

      <Container id="conteudo-pagina-interna">
        <Row>
          <Col>
            <p className="p-2 mt-5 text-justify">
              As parcerias desenvolvidas pela Administração Pública e as
              Organizações da Sociedade Civil (OSC), de acordo com o
              estabelecido na Lei Federal 13.019/2014, são acordos que poderão
              envolver ou não a transferência voluntária de recursos financeiros
              e objetivam desenvolver atividades ou projetos que estão atrelados
              com o interesse público e recíproco, em regime de mútua
              cooperação. A referida legislação abarca três modalidades de
              parcerias entre a Administração Pública e as OSC, as quais são:{" "}
            </p>
          </Col>
        </Row>

        <Row className="text-center">
          <Col lg="4" md="4" sm="12" xs="12" className="mt-4">
            <div className="bg-light p-3 h-100">
              <div className="text-primary display-5">
                <IoDocumentText />
              </div>
              <h4 className="info-title mt-3">Termo de Colaboração</h4>
              <p className="description mt-3 text-justify">
                Modalidade de parceria na qual se envolve a transferência de
                recursos financeiros, as suas diretrizes são estabelecidas
                previamente pelo Governo e a forma de seleção é por meio de
                chamamento público, em via de regra.
              </p>
            </div>
          </Col>
          <Col lg="4" md="4" sm="12" xs="12" className="mt-4">
            <div className="bg-light p-3 h-100">
              <div className="text-primary display-5">
                <IoDocumentText />
              </div>
              <h4 className="info-title mt-3">Termo de Fomento</h4>
              <p className="description mt-3 text-justify">
                Modalidade de parceria na qual se envolve a transferência de
                recursos financeiros, não há delimitação específica de propostas
                previamente, as OSC podem sugerir um determinado projeto de
                atuação, visando uma problemática específica. A sua forma de
                seleção, geralmente, é por meio de chamamento público.
              </p>
            </div>
          </Col>
          <Col lg="4" md="4" sm="12" xs="12" className="mt-4">
            <div className="bg-light p-3 h-100">
              <div className="text-primary display-5">
                <IoDocumentText />
              </div>
              <h4 className="info-title mt-3">Acordo de Cooperação</h4>
              <p className="description mt-3 text-justify">
                Modalidade de parceria em que não há a transferência de recursos
                financeiros. A OSC é quem estabelece a parceria com a
                Administração Pública, objetivando uma execução fundada em
                interesse mútuo, com finalidade pública. Consequentemente, não
                será necessária uma forma de seleção prévia, com exceção para os
                casos de comodato, doação de bens ou outra forma de
                compartilhamento de recurso patrimonial. Nestas situações deverá
                haver chamamento público, em conformidade com o art. 29 da Lei
                13.019/2014.
              </p>
            </div>
          </Col>
        </Row>
        <TermosEAcordosLista />
      </Container>
    </>
  );
};

export default TermosEAcordos;
