import React, { useContext, useEffect } from "react";
import { useFormik } from "formik";
import { Col, Container, Row, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import * as yup from "yup";
import TextInput from "../../../components/UI/Formik/TextInput/TextInput";
import DateInput from "../../../components/UI/Formik/DateInput/DateInput";
import SpinnerLabel from "../../../components/UI/SpinnerLabel";
import { AppContext } from "../../../context/AppContext";
import useApiClient, { apiRoutes } from "../../../hooks/apiClient";
import { useHistory } from "react-router";
import TextareaInput from "../../../components/UI/Formik/TextareaInput/TextareaInput";
import SelectFileInputGeneral from "../../../components/UI/SelectFileInput/SelectFileInputGeneral";
import SelectInputCached from "../../../components/UI/Formik/SelectInput/SelectInput.cached";
import { toInteger } from "lodash";
import { toast, ToastContainer, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Inputmask from "inputmask";

const validationSchema = yup.object().shape({
  RazaoSocialEntidade: yup.string().max(100, "A descrição deve conter no máximo 100 caracteres.").required("Informe a Razão Social"),
  descricao: yup
    .string()
    .max(400, "A descrição deve conter no máximo 400 caracteres.")
    .required("Informe uma descrição resumida do objetivo do acordo."),
  idModalidadeTermosAcordos: yup.string().required("Escolha uma Modalidade"),
  arquivo: yup.string().required("Escolha um arquivo"),
  arquivoNome: yup.string().required("Escolha um arquivo"),
  Tm_inicio_vigencia: yup.date().required("Informe a data inicial da Vigência"),
  Tm_fim_vigencia: yup.date().required("Informe a data final da Vigência"),
  IdstatusAndamento: yup.string().required("Escolha o andamento do termo ou acordo."),
  Nprocesso: yup.string().transform((value) => value.replace(/[^0-9]/g, '')) 
  .length(20, "O Número do processo SEI deve ter exatamente 20 caracteres")
  .required('Infome o número do processo SEI.'),
});

const initialValues = {
  idModalidadeTermosAcordos: "",
  IdstatusAndamento: "",
  RazaoSocialEntidade: "",
  descricao: "",
  Tm_inicio_vigencia: new Date(),
  Tm_fim_vigencia: new Date(),
  Nprocesso: "",
  arquivo: "",
  arquivoNome: "",
  Justificativa: "",
};

const LançamentoTermosAcordos = () => {
  const { setBackgroundClassList } = useContext(AppContext);
  const { request, data, errors, loading } = useApiClient();
  const history = useHistory();


  useEffect(() => {
    setBackgroundClassList(["background-1"]);
  });


  const SalvarTermosAcordos = (values) => {
    const SeiSemMask = values.Nprocesso.replace(/[^\d]+/g, "");
    values.Nprocesso = SeiSemMask;
    request(apiRoutes.main.termosAcordos.salvarTermosAcordos, values);
  };


  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: SalvarTermosAcordos,
  });


  const { setSubmitting, isSubmitting } = formik;

  useEffect(() => {
    if (data) {
      toast.success("Termo ou Acordo lançado com Sucesso!!!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
        onClose: () => {
          setSubmitting(false);
          window.location.reload();
        }
      });
    }

    if (errors) {
      toast.error(errors.message || "algo deu errado!!", {
        position: "top-center",
        autoClose: 3500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
        onClose: () => {
          setSubmitting(false);
        }
      });
      
    }
  }, [data, errors, history]);

  const handleArquivo = (uploadPayload) => {
    formik.setValues((curr) => ({
      ...curr,
      arquivo: uploadPayload.arquivo,
      arquivoNome: uploadPayload.arquivoNome,
    }));
  };

  useEffect(() => {

    const SEIMask = Inputmask({
      mask: "99999999.999999/9999-99",
      placeholder: " ",
      numericInput: true,
      rightAlign: false,
      numericInput: true,
      allowMinus: false,
      positionCaretOnClick: "none",
    });

    SEIMask.mask(document.getElementById("Nprocesso"));

  }, []);

  const readOnly = loading || !!((data || {}).fg_somente_leitura === "S");

  return (
    <>
      <Container id="cabecalho-pagina" fluid className="">
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <Row>
          <Col className="d-flex flex-column text-center mt-5">
            <h1 className="text-white text-shadow mt-5">
              Lançamento dos termos e acordos
            </h1>
          </Col>
        </Row>
      </Container>

      <Container id="conteudo-pagina-interna">
      <Row>
          <div className="alert alert-info mt-5" role="alert">
                <h4 className="alert-heading">Instruções</h4>
                <p>As parcerias desenvolvidas pela Administração Pública e as Organizações da Sociedade Civil (OSC), de acordo com o estabelecido na Lei Federal 13.019/2014, são acordos que poderão envolver ou não a transferência voluntária de recursos financeiros e objetivam desenvolver atividades ou projetos que estão atrelados com o interesse público e recíproco, em regime de mútua cooperação. A referida legislação abarca três modalidades de parcerias:  Termo de Colaboração, Termo de Fomento e Acordo de Cooperação.</p>
                <p>Nesta página, é imprescindível que você informe todos os dados relativos ao termo ou acordo, observando cuidadosamente todas as informações, modalidades e status pertinentes.</p>
          </div>
        </Row>
        <Row className="d-flex g-4">
          <Col md="12" sm="12" xs="12">
            <h2 className="my-3">Dado do termo ou acordo</h2>
          </Col>
          <Col lg="12" md="12" sm="12" xs="12">
            <TextInput
              formik={formik}
              field="RazaoSocialEntidade"
              label="Razão social da entidade:"
              placeholder="Adicione a razão social da entidade"
              disabled={isSubmitting}
            />
          </Col>
          <Col lg="6" md="12" sm="12" xs="12">
            <TextInput
              formik={formik}
              field="Nprocesso"
              label="Nº processo SEI:"
              placeholder="Adicione o Nº do processo SEI"
              disabled={isSubmitting}
            />
          </Col>
          <Col lg="3" md="5" sm="12" xs="12">
            <DateInput
              formik={formik}
              type="date"
              field="Tm_inicio_vigencia"
              label="Inicio da vigência:"
              disabled={isSubmitting}
            />
          </Col>

          <Col lg="3" md="5" sm="12" xs="12">
            <DateInput
              formik={formik}
              type="date"
              field="Tm_fim_vigencia"
              label="Fim da vigência:"
              disabled={isSubmitting}
            />
          </Col>

          <Col lg="12" md="12" sm="12" xs="12">
            <TextareaInput
              formik={formik}
              field="descricao"
              label="Descrição"
              placeholder="Descreva resumidamente o objetivo do acordo"
              disabled={isSubmitting}
              rows={10}
            />
          </Col>

          <Col lg="6" md="6" sm="6" xs="6">
            <SelectInputCached
              formik={formik}
              field="IdstatusAndamento"
              label="Andamento:"
              cacheKey="andamentos"
              disabled={isSubmitting}
              allowEmpty
            />
          </Col>

          <Col lg="6" md="6" sm="6" xs="6">
            <SelectInputCached
              formik={formik}
              field="idModalidadeTermosAcordos"
              label="Modalidade do Termo ou Acordo"
              cacheKey="modalidades"
              disabled={isSubmitting}
              allowEmpty
            />
          </Col>

          {[3, 4].includes(toInteger(formik.values.idAndamento)) && (
            <Col lg="12" md="12" sm="12" xs="12">
              <TextInput
                formik={formik}
                field="Justificativa"
                label="Justificativa:"
                disabled={isSubmitting}
              />
            </Col>
          )}

          <Row>
            <Col className="mt-5" lg="12" md="12" sm="12" xs="12">
              <h3 className="my-3 mt-3">Relacionar Arquivo ao termo ou acordo</h3>
            </Col>
            <Col lg="12" md="12" sm="12" xs="12">
              <p>
                Nesta seção, é possível anexar um arquivo PDF ao termo ou acordo, sendo recomendável o edital correspondente. Caso deseje incluir documentos adicionais, salve e acesse a opção para atualizar um termo ou acordo na listagem de termos e acordos.
              </p>
            </Col>
            <Col className="mt-5" lg="12" md="12" sm="12" xs="12">
              <SelectFileInputGeneral
                disabled={isSubmitting}
                show={!readOnly}
                showListfile={!readOnly}
                field="arquivo"
                handleArquivo={handleArquivo}
              />
            </Col>
          </Row>
        </Row>

        <Row>
          <Col className="d-flex justify-content-center mt-5">
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip className="medium mt-1">Salvar no sistema</Tooltip>}>
              <Button
                className="mt-3"
                disabled={isSubmitting}
                onClick={() => {
                  if ([1, 2].includes(toInteger(formik.values.idAndamento))) {
                    formik.setFieldValue("Justificativa", "#");
                  }
                  formik.handleSubmit();
                }}
              >
                <SpinnerLabel loading={formik.isSubmitting} label="Salvar" />
              </Button>
            </OverlayTrigger>
            &nbsp;&nbsp;
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip className="medium mt-1">Voltar para página anterior</Tooltip>}>
              <Button className="mt-3" disabled={isSubmitting}  onClick={() => history.goBack()}>
                Voltar
              </Button>
            </OverlayTrigger>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default LançamentoTermosAcordos;
