import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: false,
  user: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    
    updateUser(state, action) {

      state.user = { ...state.user, ...action.payload };
      sessionStorage.setItem('user',JSON.stringify(state));
    },
    signin(state, action) {
      state.isAuthenticated = true;
      state.user = action.payload;
      sessionStorage.setItem('user',JSON.stringify(state));
    },
    signout(state) {
      localStorage.removeItem(TOKEN_KEY);
      sessionStorage.removeItem('user');
      state.isAuthenticated = false;
      
    },
  },
});

export const TOKEN_KEY = "token_user_OSC";

export const authActions = authSlice.actions;

export default authSlice.reducer;
