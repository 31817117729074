import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Alert, Spinner, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import Table from "react-bootstrap/Table";
import useApiClient, { apiRoutes } from "../../../hooks/apiClient";
import { useFormik } from "formik";
import Inputmask from "inputmask";
import TextInput from "../../../components/UI/Formik/TextInput/TextInput";
import ConfirmarExclusaoModal from "../../../components/Modal/ConfirmarExclusaoModal";
import TabelPagination from "../../../components/pagination/TabelPaginatio";
import { toast, ToastContainer, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AiOutlineForm } from "react-icons/ai";
import { RiDeleteBin5Line } from "react-icons/ri";
import { FaFilePen } from "react-icons/fa6";
import {  useSelector } from "react-redux";
import {  FormatarSEI } from "../../../components/UI/Format/Format";
import * as yup from "yup";


const validationSchema = yup.object().shape({
  ProcessoSei: yup.string().transform((value) => value.replace(/[^0-9]/g, '')) 
  .length(20, "O Número do processo SEI deve ter exatamente 20 caracteres"),
});

const initialValues = {
  ProcessoSei: "",
};

const SalvarPesquisa = {
  ProcessoSei: "",
};

const initialValuesChamamentoRemover = {
  IdChamamentoPublico: 0,
};

const ListaChamamentosPublicos = () => {
  const { request, data, requestId, errors } = useApiClient();
  const auth = useSelector((state) => state.auth);
  const [showSpinner, setShowSpinner] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [offset, setoffset] = useState(0);
  const [paginatedData, setPaginatedData] = useState([]);
  const [totalPages, settotalPages] = useState(0);
  const [limparPesquisa, setlimparPesquisa] = useState(true);
  const [offsetPesquisa, setoffsetPesquisa] = useState(0);
  const itemsPerPage = 5;


  useEffect(() => {
    request(apiRoutes.main.chamamentoPublico.listarChamamentoPorSecretaria,
      {
        routeParams: {
          limit: itemsPerPage,
          offset: offset
        }
      },
      { requestId: "carregar" }
    );
  }, [request, offset]);

  useEffect(() => {
    if (data && requestId === "carregar") {
      setTimeout(() => {
        setPaginatedData(data);
        settotalPages(data && data.length > 0 ? Math.ceil(data[0].totalitens / itemsPerPage) : 0);
        setShowSpinner(false);
      }, 700);
    }

    if (data && requestId === "pesquisar") {
      setShowSpinner(true);
      setPaginatedData(data);
      settotalPages(data && data.length > 0 ? Math.ceil(data[0].totalitens / itemsPerPage) : 0);
      setTimeout(() => {
        setShowSpinner(false);
      }, 900);
    }

    if (data && requestId === "remover") {
      toast.success("Chamamento removido com sucesso", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
        onClose: () => {
          setShowSpinner(false);
          window.location.reload();
        },
      });
    }

    if (errors) {
      toast.error(errors.message || "algo deu errado!!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
        onClose: () => {
          setShowSpinner(false);
          window.location.reload();
        },
      });
    }
  }, [requestId, data, errors]);

  useEffect(() => {
    if (!limparPesquisa) {
      request(apiRoutes.main.chamamentoPublico.secretariaPesquisarChamamento,
        {
          routeParams: {
            limit: itemsPerPage,
            offset: offsetPesquisa,
            sei: SalvarPesquisa.ProcessoSei,
          }
        },
        { requestId: "pesquisar" }
      );
    }
  }, [offsetPesquisa]);


  const FiltrarPesquisa = (values) => {
    SalvarPesquisa.ProcessoSei = values.ProcessoSei.replace(/[^\d]+/g, "");
    setlimparPesquisa(false);
    request(apiRoutes.main.chamamentoPublico.secretariaPesquisarChamamento,
      {
        routeParams: {
          limit: itemsPerPage,
          offset: offsetPesquisa,
          sei: SalvarPesquisa.ProcessoSei,
        }
      },
      { requestId: "pesquisar" }
    );
  };

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: FiltrarPesquisa,
  });

  const limparCampos = () => {
    setShowSpinner(true)
    formik.resetForm();
    SalvarPesquisa.sei = "";
    setlimparPesquisa(true)
    setoffsetPesquisa(0)
    setoffset(0)
    request(apiRoutes.main.chamamentoPublico.listarChamamentoPorSecretaria,
      {
        routeParams: {
          limit: itemsPerPage,
          offset: 0
        }
      },
      { requestId: "carregar" }
    );
  };


  const handlePageChange = (pageNumber) => {
    setShowSpinner(true);
    setActivePage(pageNumber);
    const newOffset = ((pageNumber - 1) * itemsPerPage)
    if (!limparPesquisa) {
      setoffsetPesquisa(newOffset);
    } else {
      setoffset(newOffset);
    }
  };

  const handleDeleteConfirm = (initialValuesChamamentoRemover) => {
    if (initialValuesChamamentoRemover) {

      request(apiRoutes.main.chamamentoPublico.removerChamamento,
        {
          routeParams: {
            IdChamamentoPublico: initialValuesChamamentoRemover.IdChamamentoPublico,
          }
        },
        { requestId: "remover" }
      );
    }
    setShowDeleteModal(false);
  };

  const handleCancelDelete = () => {
    setShowDeleteModal(false);
    setShowSpinner(false);
  };

  useEffect(() => {
    const SEIMask = Inputmask({
      mask: "99999999.999999/9999-99",
      placeholder: " ",
      numericInput: true,
      rightAlign: false,
      allowMinus: false,
      positionCaretOnClick: "none",
    });

    SEIMask.mask(document.getElementById("ProcessoSei"));
  }, []);


  return (
    <>
      <Container className="conteudo-pagina mt-5">
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <Row>
          <Col>
            <h3 className="mt-5 fw-bold">Chamamentos públicos</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
            Nesta seção, estão listados todos os chamamentos públicos lançados pelo seu setor. Para realizar modificações, utilize as seguintes colunas: "Atualizar" para editar o chamamento, "Arquivos" para adicionar ou remover documentos associados, e "Remover" para excluir o chamamento público do portal. Lembre-se de que apenas o coordenador do setor possui permissão para remover um chamamento público do portal OSC.
            </p>
          </Col>
        </Row>
        <Row className="bg-light rounded p-3">
             <Col lg="7" md="12" sm="12" xs="12">
                <TextInput
                  formik={formik}
                  field="ProcessoSei"
                  label="Pesquisar chamamento pelo processo SEI:"
                />
              </Col>
          <Col className="d-flex flex-row mt-3 justify-content-center">
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip className="medium mt-1">Pesquisar por parâmetro selecionado</Tooltip>}>
              <Button variant="primary" className="me-3" onClick={formik.handleSubmit}>
                Pesquisar
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip className="medium mt-1">Limpar resultados e parâmetro da pesquisa</Tooltip>}>
              <Button variant="link" className="ms-3" onClick={limparCampos}>
                Limpar
              </Button>
            </OverlayTrigger>
          </Col>
        </Row>
        <Row>
          {showSpinner && (
            <Row>
              <Col className="d-flex flex-row mt-5 justify-content-center">
                <h5 className="mb-2">
                  <Spinner animation="border" size="lg" variant="warning" />
                </h5>
              </Col>
            </Row>
          )}
          <Col>
            {paginatedData && paginatedData.length > 0 && !showSpinner && (
              <>
                <div style={{ overflowX: 'auto' }}>
                  <Table striped bordered hover className="mt-4">
                    <thead>
                      <tr>
                        <th>Processo SEI</th>
                        <th>Título</th>
                        <th>Andamento</th>
                        <th>Atualizar</th>
                        <th>Arquivos</th>
                        {auth.user && auth.user.papeis[0] === "COORDENADOR" &&(<th>Remover</th>)}
                      </tr>
                    </thead>
                    <tbody>
                      {paginatedData.map((itemData) => (
                        <tr key={itemData.id_chamamento_publico}>
                          <td>{FormatarSEI(itemData.n_processo)}</td>
                          <td>{itemData.tx_titulo}</td>
                          <td>{itemData.tx_nome}</td>
                          <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip className="medium mt-1">Atualizar Chamamento público</Tooltip>}>

                            <td className="text-center mt-2">
                              <Link to={`/update/chamamento/${itemData.id_chamamento_publico}`}>
                                <AiOutlineForm 
                                style={{ cursor: "pointer" }}
                                size={25} />
                              </Link>
                            </td>

                          </OverlayTrigger>

                          <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip className="medium mt-1">Adicionar ou remover arquivos do Chamamento público</Tooltip>}>
                            <td className="text-center mt-2">
                              <Link to={`/chamamento/arquivos/${itemData.id_chamamento_publico}/chamamento`}>
                                <FaFilePen 
                                style={{ cursor: "pointer" }}
                                size={25} />
                              </Link>
                            </td>
                          </OverlayTrigger>

                          {auth.user && auth.user.papeis[0] === "COORDENADOR" &&( 
                            <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip className="medium mt-1">Remover Chamamento público</Tooltip>}>

                            <td className="text-center">
                              <RiDeleteBin5Line
                                style={{ cursor: "pointer" }}
                                size={25}
                                color="red"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setShowSpinner(true)
                                  initialValuesChamamentoRemover.IdChamamentoPublico = itemData.id_chamamento_publico;
                                  setShowDeleteModal(true);
                                }}
                              />
                            </td>
                          </OverlayTrigger>)}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
                {data && totalPages > 1 && (
                  <TabelPagination totalPages={totalPages} activePage={activePage} handlePageChange={handlePageChange} />)}
              </>
            )}
            {!showSpinner && paginatedData && paginatedData.length === 0 && (
              <Alert className="mt-3" variant="primary">Nenhum registro encontrado</Alert>
            )}
          </Col>
        </Row>
      </Container>
      <ConfirmarExclusaoModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        handleConfirm={() => handleDeleteConfirm(initialValuesChamamentoRemover)}
        handleCancel={handleCancelDelete}
        title="Confirmar exclusão"
        bodyText="Você tem certeza que deseja excluir este chamamento público?"
        confirmButtonText="Confirmar"
        cancelButtonText="Cancelar"
      />
    </>
  );
};

export default ListaChamamentosPublicos;
