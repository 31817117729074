import { parseJSON, format } from 'date-fns';


export const FormatDate = (jsonDate, outputFormat = "dd/MM/yyyy HH:mm") => {
    if(!jsonDate) return "";

    var date = parseJSON(jsonDate);
    var dateTimezoned = new Date(date.getTime() + (date.getTimezoneOffset() / 60) * 3600000);
    const formattedDate = format(dateTimezoned, outputFormat);
    return formattedDate.replace(' ', ' às ');
};


export const  FormatarSEI = (sei) => {
    if (sei) {
      return sei.replace(/(\d{8})(\d{6})(\d{4})(\d{2})/, "$1.$2/$3-$4");
    }
    return "";
  };


  export const  FormatTelefone = (tel) => {
    if (tel) {
      return tel.replace(/(\d{2})(\d{4})(\d{4})/, "($1)$2-$3");
    }
    return "";
  };

