
import React, { useEffect, useContext, useRef } from "react";
import { Container, Row, Col, Alert } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { AppContext } from "../context/AppContext";
import { useHistory } from "react-router";
import * as yup from "yup";
import { useFormik } from "formik";
import TextInput from "../components/UI/Formik/TextInput/TextInput";
import SpinnerLabel from "../components/UI/SpinnerLabel";
import ViaCepLoader from "../components/UI/ViaCepLoader/ViaCepLoader";
import SelectInput from "../components/UI/Formik/SelectInput/SelectInput";
import useApiClient, { apiRoutes } from "../hooks/apiClient";
import {
  MunicipiosAsOptions,
  MunicipiosDoRN,
} from "../components/UI/Formik/SelectInput/SelectInput.options";
import { TOKEN_KEY } from "../redux/auth";
import { useDispatch } from "react-redux";
import { authActions } from "../redux/auth";
import appConfig from "../config/app.config";
import {validateCPF} from "../components/UI/Formik/Validations/Validations";
import Inputmask from "inputmask";
import { toast, ToastContainer, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const validationSchema = yup.object().shape({
  nome: yup.string().required("Informe seu nome completo "),
  cpf: yup
    .string()
    .transform((originalValue) => {
      const cleanedValue = originalValue.replace(/[^\d]+/g, "");
      return cleanedValue;
    })
    .matches("[0-9]{11}", "Somente números de 0-9 e no máximo 11 dígitos")
    .test("cpf-validation", "CPF inválido.", (cpf) => {
      return validateCPF(cpf);
    })
    .required("Informe seu CPF."),
  senha: yup
    .string()
    .min(6, "Informe a senha com no mínimo 6 caracteres")
    .required("Informe a senha"),
  confirmeSenha: yup
    .string()
    .oneOf(
      [yup.ref("senha"), null],
      "As senhas não coincidem, digite novamente."
    )
    .required("Digite a sua senha novamente."),
  logradouro: yup.string().required("Informe o logradouro de seu endereço "),
  complemento: yup.string(),
  numero: yup.string().required("Informe o número de seu endereço "),
  cidade: yup.string().required("Informe a cidade de seu endereço "),
  bairro: yup.string().required("Informe o bairro de seu endereço "),
  cep: yup
    .string()
    .transform((originalValue) => {
      const cleanCEP = originalValue.replace(/[^\d]+/g, "");
      return cleanCEP;
    })
    .matches("[0-9]{8}","O cep deve conter até 8 digitos")
    .required("O CEP é um campo obrigátorio"),
  telefone1: yup
    .string()
    .transform((originalValue) => {
      const cleanedValue = originalValue.replace(/[^\d]+/g, "");
      return cleanedValue;
    })
    .matches("[0-9]{10}", "Somente números de 0-9 e no máximo 11 dígitos")
    .required("Informe DDD e telefone com no mínimo 10 dígitos "),
  telefone2: yup
  .string()
  .transform((originalValue) => {
    const cleanedValue = originalValue.replace(/[^\d]+/g, "");
    return cleanedValue;
  })
  .matches("[0-9]{10}", "Somente números de 0-9 e no máximo 11 dígitos"),
});

const initialValues = {
  nome: "",
  cpf: "",
  senha: "",
  confirmeSenha: "",
  logradouro: "",
  numero: "",
  complemento: "",
  bairro: "",
  cidade: "",
  uf: "RN",
  cep: "",
  telefone1: "",
  telefone2: "",
}

const PaginaCadastroUsuario = () => {
  const { setBackgroundClassList } = useContext(AppContext);
  const history = useHistory();
  const numeroInputRef = useRef(null);
  const dispatch = useDispatch();
  const url_atual = window.location.href;
  const separados = url_atual.split("/");

  const enviar = separados[separados.length - 1];
  const { request, data, errors, loading, requestExtra } = useApiClient();

  const handleSubmitClenMask = (values) => {
    const cpfSemMascara = values.cpf.replace(/[^\d]+/g, "");
    const cepSemMascara = values.cep.replace(/[^\d]+/g, "");
    const tel1SemMascara  = values.telefone1.replace(/[^\d]+/g, "");
    const tel2SemMascara  = values.telefone2.replace(/[^\d]+/g, "");
    values.cpf = cpfSemMascara;
    values.cep =  cepSemMascara
    values.telefone1 = tel1SemMascara;
    values.telefone2 = tel2SemMascara;
    request(
      apiRoutes.main.usuario.ativar,
      { payload: enviar, ...values },
      {
        requestExtra: {
          senha: values.senha,
        },
      }
    );
  };


  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: handleSubmitClenMask,
  });

  const { setSubmitting, isSubmitting } = formik;

  useEffect(() => {
    setBackgroundClassList(["background-1"]);
  });

  useEffect(() => {
    if (data) {
      var credenciais = `${data}:${requestExtra.senha}`;
      fetch(`${appConfig.host}/api/auth/signin`, {   
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${btoa(credenciais)}`,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            response
              .json()
              .then((result) => {
                localStorage.setItem(TOKEN_KEY, result.accessToken);
                dispatch(authActions.signin(result));
                history.push("/minha-area");
              })
              .catch((e) => history.push("/login"));
          } 
        })
        .catch((e) => history.push("/login"));
    }
    if (errors) {
      toast.error(errors.message || "algo deu errado!!", {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
        onClose: () => {
          setSubmitting(false);
        }
        });
    }
  }, [data, errors, requestExtra, history, dispatch, setSubmitting]);

  useEffect(() => {
    const CEPMask = Inputmask({
      mask: "99999-999",
      numericInput: true,
    });

    const CPFFormat = Inputmask({
      mask: "999.999.999-99",
      numericInput: true,
    });

    const telefoneMask = Inputmask({
      mask: ["(99) 9999-9999", "9999-9999"],
      numericInput: true,
    });

    CPFFormat.mask(document.getElementById("cpf"));
    CEPMask.mask(document.getElementById("cep"));
    telefoneMask.mask(document.getElementById("telefone1"));
    telefoneMask.mask(document.getElementById("telefone2"));
  }, []);



  const readOnly = loading;

  return (
    <>
      <Container id="cabecalho-pagina" fluid>
        <Row>
          <Col className="d-flex flex-column text-center mt-5">
            <h1 className="text-white text-shadow mt-5">Cadastro de Usuário</h1>
          </Col>
        </Row>
      </Container>

      <Container id="conteudo-pagina-interna">
        <Row>
          <Col className="mt-5">
            <Form>
              <Row className="d-flex g-4">
                <Col lg="12" md="12" sm="12" xs="12">
                  <h3 className="my-3">Dados Pessoais</h3>
                </Col>

                {formik.status && (
                  <Alert variant="danger">{formik.status}</Alert>
                )}

                <Col lg="6" md="6" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    field="nome"
                    label="Nome:"
                    placeholder="Informe o seu nome completo"
                    disabled = {isSubmitting}
                  />
                </Col>

                <Col lg="6" md="6" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    field="cpf"
                    label="CPF:"
                    placeholder="Informe o seu CPF"
                    disabled = {isSubmitting}
                  />
                </Col>

                <Col lg="3" md="3" sm="11" xs="11">
                  <TextInput
                    formik={formik}
                    field="cep"
                    label="CEP:"
                    disabled = {isSubmitting}
                  />
                </Col>

                {!readOnly && (
                  <Col lg="3" md="3" sm="1" xs="1" style={{ alignSelf: "end" }}>
                    <ViaCepLoader
                      disabled={!formik.values.cep || !!formik.errors.cep}
                      cep={formik.values.cep}
                      onFound={(data) => {
                        const { logradouro, bairro, ibge } = data;
                        formik.setValues({
                          ...formik.values,
                          logradouro: logradouro,
                          bairro: bairro,
                          cidade: MunicipiosDoRN[ibge],
                        });
                        numeroInputRef.current.focus();
                      }}
                    />
                  </Col>
                )}

                <Col lg="10" md="10" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    field="logradouro"
                    label="Logradouro:"
                    disabled = {isSubmitting}
                  />
                </Col>

                <Col lg="2" md="2" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    ref={numeroInputRef}
                    field="numero"
                    label="Número:"
                    disabled = {isSubmitting}
                  />
                </Col>

                <Col lg="12" md="12" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    field="complemento"
                    label="Complemento:"
                    disabled = {isSubmitting}
                  />
                </Col>

                <Col lg="6" md="6" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    field="bairro"
                    label="Bairro:"
                    disabled = {isSubmitting}
                  />
                </Col>

                <Col lg="6" md="6" sm="12" xs="12">
                  <SelectInput
                    formik={formik}
                    field="cidade"
                    label="Cidade:"
                    disabled = {isSubmitting}
                  >
                    <MunicipiosAsOptions />
                  </SelectInput>
                </Col>

                <Col lg="6" md="6" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    type="password"
                    field="senha"
                    label="Senha:"
                    disabled = {isSubmitting}
                  />
                </Col>

                <Col lg="6" md="6" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    type="password"
                    field="confirmeSenha"
                    label="Confirme sua senha:"
                    readOnly={readOnly}
                    disabled = {isSubmitting}
                  />
                </Col>

                <Col lg="6" md="6" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    field="telefone1"
                    label="Telefone 1:"
                    readOnly={readOnly}
                    disabled = {isSubmitting}
                  />
                </Col>
                <Col lg="6" md="6" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    field="telefone2"
                    label="Telefone 2:"
                    readOnly={readOnly}
                    disabled = {isSubmitting}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="d-flex justify-content-center mt-5">
                  <Button
                    className="mt-3"
                    disabled={readOnly}
                    onClick={formik.handleSubmit}
                  >
                    <SpinnerLabel
                      loading={formik.isSubmitting}
                      label="Salvar"
                    />
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    className="mt-3"
                    onClick={() => history.push("/login")}
                  >
                    Voltar
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default PaginaCadastroUsuario;
