export const validateCPF = (cpf) => {
  if(!cpf) return false

  cpf = cpf.replace(/[^\d]+/g, "");

  if (cpf.length !== 11 || /^(.)\1{10}$/.test(cpf)) {
    return false;
  }

  const calculateVerifier = (startIndex, factor) => {
    let sum = 0;
    for (let i = 0; i < startIndex; i++) {
      sum += parseInt(cpf[i]) * (factor - i);
    }
    const verifier = 11 - (sum % 11);
    return verifier > 9 ? 0 : verifier;
  };

  const firstVerifier = calculateVerifier(9, 10);
  const secondVerifier = calculateVerifier(10, 11);

  return (
    firstVerifier === parseInt(cpf[9]) && secondVerifier === parseInt(cpf[10])
  );
};


export const validateCNPJ = (cnpj) => {

  if(!cnpj) return false;
  

  cnpj = cnpj.replace(/[^\d]+/g, '');

 
  if (cnpj.length !== 14) {
    return false;
  }


  if (/^(\d)\1{13}$/.test(cnpj)) {
    return false;
  }


  const calculateVerifier = (startIndex, factor) => {
    let sum = 0;
    for (let i = 0; i < startIndex; i++) {
      sum += parseInt(cnpj[i]) * factor--;
      if (factor < 2) {
        factor = 9;
      }
    }
    const verifier = 11 - (sum % 11);
    return verifier > 9 ? 0 : verifier;
  };

  
  const firstVerifier = calculateVerifier(12, 5); 
  const secondVerifier = calculateVerifier(13, 6); 


  return (
    firstVerifier === parseInt(cnpj[12], 10) &&
    secondVerifier === parseInt(cnpj[13], 10)
  );
};





//TODO: RETIRAR ESSE CÓDIGO
//valida o CNPJ digitado
function validaCNPJ(CNPJ) {
  var a = new Array();
  var b = new Number();
  var c = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
  for (var i = 0; i < 12; i++) {
    a[i] = CNPJ.charAt(i);
    b += a[i] * c[i + 1];
  }
  var x;
  if ((x === b % 11) < 2) {
    a[12] = 0;
  } else {
    a[12] = 11 - x;
  }
  b = 0;
  for (var y = 0; y < 13; y++) {
    b += a[y] * c[y];
  }
  if ((x === b % 11) < 2) {
    a[13] = 0;
  } else {
    a[13] = 11 - x;
  }
  if (CNPJ.charAt(12) != a[12] || CNPJ.charAt(13) != a[13]) {
    return false;
  }
  return true;
}


export const validCpf = {
  exclusive: false,
  message: "O CPF informado é inválido",
  test: (value) => {
    if (value === null) return false;

    return validateCPF(value);
  },
};

export const validCnpj = {
  exclusive: false,
  message: "O CNPJ informado é inválido",
  test: (value) => {
    if (value === null) return false;

    return validaCNPJ(value);
  },
};
