import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Col, Container, Form, Row, Alert, Spinner, OverlayTrigger, Tooltip } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import TextInput from "../../../components/UI/Formik/TextInput/TextInput";
import { useFormik } from "formik";
import useApiClient, { apiRoutes } from "../../../hooks/apiClient";
import { FormatDate, FormatarSEI } from "../../../components/UI/Format/Format";
import Inputmask from "inputmask";
import { AiOutlineForm } from "react-icons/ai";
import { RiDeleteBin5Line } from "react-icons/ri";
import { toast, ToastContainer, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TabelPagination from "../../../components/pagination/TabelPaginatio";
import ConfirmarExclusaoModal from "../../../components/Modal/ConfirmarExclusaoModal";
import {  useSelector } from "react-redux";
import { FaFilePen } from "react-icons/fa6";
import * as yup from "yup";


const validationSchema = yup.object().shape({
  sei: yup.string().transform((value) => value.replace(/[^0-9]/g, '')) 
  .length(20, "O Número do processo SEI deve ter exatamente 20 caracteres"),
});


const initialValues = {
  sei: "",
};

const SalvarPesquisa = {
  sei: "",
};

const ListaTermosAcordos = () => {
  const { request, data, requestId, errors } = useApiClient();
  const auth = useSelector((state) => state.auth);
  const [showSpinner, setShowSpinner] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [offset, setoffset] = useState(0);
  const [paginatedData, setPaginatedData] = useState([]);
  const [totalPages, settotalPages] = useState(0);
  const [limparPesquisa, setlimparPesquisa] = useState(true);
  const [offsetPesquisa, setoffsetPesquisa] = useState(0);
  const [IdTermoDelete, setIdTermoDelete,] = useState(0);
  const itemsPerPage = 5;


  useEffect(() => {

    request(apiRoutes.main.termosAcordos.listarTermosAcordos,
      {
        routeParams: {
          limit: itemsPerPage,
          offset: offset
        }
      },
      { requestId: "carregar" }
    );
  }, [request, offset]);


  useEffect(() => {
    if (data && requestId === "carregar") {
      setTimeout(() => {
        setPaginatedData(data);
        settotalPages(data && data.length > 0 ? Math.ceil(data[0].totalitens / itemsPerPage) : 0);
        setShowSpinner(false);
      }, 700);
    }

    if (data && requestId === "pesquisar") {
      setShowSpinner(true);
      setPaginatedData(data);
      settotalPages(data && data.length > 0 ? Math.ceil(data[0].totalitens / itemsPerPage) : 0);
      setTimeout(() => {
        setShowSpinner(false);
      }, 900);
    }

    if (data && requestId === "remover") {
      toast.success("Termo ou acordo removido com sucesso!!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
        onClose: () => {
          window.location.reload();
        },
      });
    }

    if (errors) {
      toast.error(errors.message || "algo deu errado!!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
        onClose: () => {
          setShowSpinner(false);
          window.location.reload();
        },
      });
    }
  }, [requestId, data, errors]);

  useEffect(() => {
    if (!limparPesquisa) {
      request(apiRoutes.main.termosAcordos.secretariaPesquisarTermosAcordos,
        {
          routeParams: {
            limit: itemsPerPage,
            offset: offsetPesquisa,
            sei: SalvarPesquisa.sei,
          }
        },
        { requestId: "pesquisar" }
      );
    }
  }, [offsetPesquisa]);


  const FiltrarPesquisa = (values) => {
    SalvarPesquisa.sei = values.sei.replace(/[^\d]+/g, "");
    setlimparPesquisa(false);
    request(apiRoutes.main.termosAcordos.secretariaPesquisarTermosAcordos,
      {
        routeParams: {
          limit: itemsPerPage,
          offset: offsetPesquisa,
          sei: SalvarPesquisa.sei,
        }
      },
      { requestId: "pesquisar" }
    );
  };


  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: FiltrarPesquisa,
  });

  const limparCampos = () => {
    setShowSpinner(true)
    formik.resetForm();
    SalvarPesquisa.sei = "";
    setlimparPesquisa(true)
    setoffsetPesquisa(0)
    setoffset(0)
    request(apiRoutes.main.termosAcordos.listarTermosAcordos,
      {
        routeParams: {
          limit: itemsPerPage,
          offset: offset
        }
      },
      { requestId: "carregar" }
    );
  };



  useEffect(() => {
    const SEIMask = Inputmask({
      mask: "99999999.999999/9999-99",
      placeholder: " ",
      numericInput: true,
      rightAlign: false,
      allowMinus: false,
      positionCaretOnClick: "none",
    });

    SEIMask.mask(document.getElementById("sei"));
  }, []);

  const handlePageChange = (pageNumber) => {
    setShowSpinner(true);
    setActivePage(pageNumber);
    const newOffset = ((pageNumber - 1) * itemsPerPage)
    if (!limparPesquisa) {
      setoffsetPesquisa(newOffset);
    } else {
      setoffset(newOffset);
    }
  };

  const handleDeleteConfirm = () => {
    if (IdTermoDelete) {
      request(
        apiRoutes.main.termosAcordos.removerTermo,
        {
          routeParams: { idtermo: IdTermoDelete },
        },
        { requestId: "remover" }
      );
    }
    setShowModal(false);
  };
  const handleCancelDelete = () => {
    setShowModal(false);
    setShowSpinner(false);
  };


  return (
    <>
      <Container className="conteudo-pagina mt-5 ">
        <ToastContainer
          position="top-center"
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />

        <Row>
          <Col>
            <h3 className="mt-4m fw-bold">Termos e Acordos</h3>
          </Col>
        </Row>

        <Row>
          <Col>
            <p>Nesta seção, estão listados todos os termo e acordos lançados pelo seu setor. Para realizar modificações, utilize as seguintes colunas: "Atualizar" para editar o termo ou acordo, "Arquivos" para adicionar ou remover documentos associados, e "Remover" para excluir o termo ou acordo do portal. Lembre-se de que apenas o coordenador do setor possui permissão para remover um termo ou acordo do portal OSC.</p>
          </Col>
        </Row>

        <Row className="bg-light rounded p-3">
          <Form>
            <Row>
              <Col lg="7" md="12" sm="12" xs="12">
                <TextInput
                  formik={formik}
                  field="sei"
                  label="Pesquisar termo ou acordo pelo processo SEI:"
                />
              </Col>
              <Col className="d-flex flex-row mt-3 justify-content-center">
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip className="medium mt-1">Pesquisar por parâmetro selecionado</Tooltip>}>
                  <Button variant="primary" className="me-3" onClick={formik.handleSubmit}>
                    Pesquisar
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip className="medium mt-1">Limpar resultados e parâmetro da pesquisa</Tooltip>}>
                  <Button variant="link" className="ms-3" onClick={limparCampos}>
                    Limpar
                  </Button>
                </OverlayTrigger>
              </Col>
            </Row>
          </Form>
        </Row>

        {showSpinner && (
          <Row>
            <Col className="d-flex flex-row mt-5 justify-content-center">
              <h5 className="mb-2">
                <Spinner
                  animation="border"
                  size="lg"
                  variant="warning"
                />
              </h5>
            </Col>
          </Row>
        )}

        <Row>
          <Col>
            {paginatedData && !showSpinner && (
              <>
              <div style={{overflowX: 'auto'}}>
                <Table striped bordered hover className="mt-4">
                  <thead>
                    <tr>
                      <th>Processo SEI</th>
                      <th>Entidade</th>
                      <th>Andamento</th>
                      <th>Atualizar</th>
                      <th>Arquivos</th>
                      {auth.user && auth.user.papeis[0] === "COORDENADOR" &&(  <th>Remover</th>)}
                    </tr>
                  </thead>
                  <tbody>
                    {paginatedData.map((itemData) => (
                      <tr key={itemData.id_termos_acordos}>
                        <td>{FormatarSEI(itemData.n_processo)}</td>
                        <td>{itemData.tx_razao_social_entidade}</td>
                        <td>{itemData.statusandamento}</td>
                        <td className="text-center mt-2">
                          <OverlayTrigger
                              placement="bottom"
                              overlay={<Tooltip className="medium mt-1">Atualizar termo ou acordo</Tooltip>}>
                              <Link
                                to={`/update/termos-acordos/${itemData.id_termos_acordos}`}
                              >
                                <AiOutlineForm 
                                style={{ cursor: "pointer" }}
                                size={25} />
                              </Link>
                          </OverlayTrigger>
                        </td>

                       <td className="text-center mt-2">
                          <OverlayTrigger
                              placement="bottom"
                              overlay={<Tooltip className="medium mt-1">Adicionar ou remover arquivos do termo ou acordo</Tooltip>}>
                          
                            <Link to={`/chamamento/arquivos/${itemData.id_termos_acordos}/termo`}>
                              <FaFilePen 
                              style={{ cursor: "pointer" }}
                              size={25} />
                            </Link>
                          </OverlayTrigger>
                        </td>

                        {auth.user && auth.user.papeis[0] === "COORDENADOR" &&(
                          <td className="text-center mt-2">
                        <OverlayTrigger
                              placement="bottom"
                              overlay={<Tooltip className="medium mt-1">Remover termo ou acordo</Tooltip>}>
                            <RiDeleteBin5Line
                              style={{ cursor: "pointer" }}
                              size={25}
                              color="red"
                              onClick={(e) => {
                                e.preventDefault();
                                setShowSpinner(true);
                                setIdTermoDelete(itemData.id_termos_acordos);
                                setShowModal(true);


                              }}
                            />
                          </OverlayTrigger>
                        </td>)}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
                {data && totalPages > 1 && (
                  <TabelPagination totalPages={totalPages} activePage={activePage} handlePageChange={handlePageChange} />)}
              </>
            )}
            {!showSpinner && paginatedData && paginatedData.length === 0 && (
              <Alert variant="primary">Nenhum registro encontrado</Alert>
            )}
          </Col>
        </Row>

        <ConfirmarExclusaoModal
          showModal={showModal}
          setShowModal={setShowModal}
          handleConfirm={() => handleDeleteConfirm()}
          handleCancel={handleCancelDelete}
          title="Confirmar exclusão"
          bodyText="Você tem certeza que deseja excluir este termo?"
          confirmButtonText="Confirmar"
          cancelButtonText="Cancelar"
        />
      </Container>

    </>
  );
};

export default ListaTermosAcordos;
