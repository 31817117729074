import React, {useContext, useEffect,useState} from "react";
import {Col, Container, Row, Button} from "react-bootstrap";
import {AppContext} from "../../context/AppContext";
import useApiClient, {apiRoutes} from "../../hooks/apiClient";
import { useHistory, useParams } from "react-router";
import ConteudoListagemArquivos from "./ConteudoListagemArquivos";
import appConfig from "../../config/app.config";


const sectionAndamentoChamamento = {
    titulo:"Andamentos do chamamento público",
    subtitulo:"Nesta seção, serão listados todos os andamentos e etapas relacionados ao chamamento público selecionado, oferecendo uma visão detalhada do progresso e das fases envolvidas.",
};

const sectionArquivosChamamento = {
    titulo:"Arquivos Relacionados ao chamamento público",
    subtitulo:"Nesta seção, serão disponibilizados todos os arquivos pertinentes ao chamamento público, proporcionando acesso completo à documentação relacionada.",
};


const sectionAndamentoTermo = {
  titulo:"Andamentos do termo ou acordo",
  subtitulo:"Nesta seção, serão listados todos os andamentos e etapas relacionados ao termo ou acordo selecionado, oferecendo uma visão detalhada do progresso e das fases envolvidas.",
};

const sectionArquivosTermo = {
  titulo:"Arquivos Relacionados ao termo ou acordo",
  subtitulo:"Nesta seção, serão disponibilizados todos os arquivos pertinentes ao termo ou acordo, proporcionando acesso completo à documentação relacionada.",
};


const sectionAndamentoProposta = {
  titulo:"Andamentos da Manifestação de interesse social",
  subtitulo:"Nesta seção, serão listados todos os andamentos e etapas relacionados à MIS, oferecendo uma visão detalhada do progresso e das fases envolvidas.",
};

const sectionArquivosProposta = {
  titulo:"Arquivos Relacionados à MIS",
  subtitulo:"Nesta seção, serão disponibilizados todos os arquivos pertinentes à MIS, proporcionando acesso completo à documentação relacionada.",
};

const ListagemDeArquivosEAndamentos = () => {
    const {request, data, requestId} = useApiClient();
    const [dataAndamento, setdataAndamento] = useState([]);
    const {setBackgroundClassList} = useContext(AppContext);
    const history = useHistory();
    const {id, tipo} = useParams();
    console.log(tipo)

  useEffect(() => {
    setBackgroundClassList(["background-1"]);
    
  });

  useEffect(() => {
    if(id && tipo){
      if(id && tipo === "chamamento"){
        request(
          apiRoutes.main.chamamentoPublico.listarArquivosPorChamamentoParaListagemPublica,
          { routeParams: { id } },
          { requestId: "carregar"}
        );

        fetch(`${appConfig.host}/api/chamamentopublico/listar/andamentos/chamamento/${id}`)
        .then(res => {
            if (!res.ok) {
                throw new Error('Erro ao carregar os dados');
            }
            return res.json(); 
        })
        .then(data => {
          setdataAndamento(data);
        })
        .catch(error => {
            console.error('Erro na requisição:', error);
        });
      }
      if(id && tipo === "termo"){
        request(
          apiRoutes.main.termosAcordos.PublicoListarArquivosTermosAcordos,
          { routeParams: { id } },
          { requestId: "carregar" }
        );

        fetch(`${appConfig.host}/api/termosacordos/listar/andamentos/termo/${id}`)
        .then(res => {
            if (!res.ok) {
                throw new Error('Erro ao carregar os dados');
            }
            return res.json(); 
        })
        .then(data => {
          setdataAndamento(data);
        })
        .catch(error => {
            console.error('Erro na requisição:', error);
        });
      }

      if(id && tipo === "MIS"){
        request(
          apiRoutes.main.propostas.listarArquivos,
          { routeParams: { id } },
          { requestId: "carregar" }
        );

        fetch(`${appConfig.host}/api/proposta/listar/andamentos/${id}`)
        .then(res => {
            if (!res.ok) {
                throw new Error('Erro ao carregar os dados');
            }
            return res.json(); 
        })
        .then(data => {
          setdataAndamento(data);
        })
        .catch(error => {
            console.error('Erro na requisição:', error);
        });
      }
    }
   }, [request, requestId, id, tipo]);

   const selectSection1 = () => {
      switch (tipo) {
        case "chamamento":
          return sectionAndamentoChamamento;
        case "termo":
          return sectionAndamentoTermo;
        case "MIS":
          return sectionAndamentoProposta;
        default:
          return "";
      }
   }

   const selectSection2 = () => {
    switch (tipo) {
      case "chamamento":
        return sectionArquivosChamamento;
      case "termo":
        return sectionArquivosTermo;
      case "MIS":
        return sectionArquivosProposta;
      default:
        return "";
    }
 }


  return (
    <>
      <Container id="cabecalho-pagina" fluid>
        <Row>
          <Col className="d-flex flex-column text-center mt-5">
            <h1 className="text-white text-shadow mt-5">
              Listagem de arquivos e andamentos.
            </h1>
          </Col>
        </Row>
      </Container>

      <ConteudoListagemArquivos  tipo={tipo} section1 = {selectSection1()} section2 = {selectSection2()} data = { data ? data : []} dataAndamento = {dataAndamento ? dataAndamento : []}  />

      <Row>
          <Col className="d-flex justify-content-center mt-5">
            <Button className="mt-3" onClick={() => history.goBack()}>
              Voltar
            </Button>
          </Col>
        </Row>
    </>
  );
};

export default ListagemDeArquivosEAndamentos;
