import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
import ChamamentosPublicos from "./pages/ChamamentosPublicos";
import Contato from "./pages/Contato";
import Home from "./pages/Home";
import Login from "./pages/Login";
import LoginGov from "./pages/LoginGov";
import PaginaEnvioLinkCadastroUsuario from "./pages/PaginaEnvioLinkCadastroUsuario";
import PaginaCadastroUsuario from "./pages/PaginaCadastroUsuario";
import PaginaEnvioLinkRedefinirSenha from "./pages/PaginaEnvioLinkRedefinirSenha";
import RedefinirSenhaUsuario from "./pages/RedefinirSenhaUsuario";
import ManifestacoesInteresseSociais from "./pages/ManifestacoesInteresseSociais";
import MinhaArea from "./pages/MinhaArea";
import MisConsultaPublica from "./pages/MisConsultaPublica";
import NotFound from "./pages/NotFound";
import PaginaLegislacao from "./pages/PaginaDeLegislacao";
import PaginaModelo from "./pages/PaginaModelo";
import PublicacoesLista from "./pages/PublicacoesLista";
import Sobre from "./pages/Sobre";
import FazerPropostaMis from "./pages/FazerPropostaMis";
import {  useDispatch  } from "react-redux";
import MinhaAreaLayout from "./pages/MinhaAreaLayout";
import MinhaAreaCoordenador from "./pages/Coordenador/MinhaAreaCoordenador/MinhaAreaCoordenador";
import FormCriacaoServidor from "./pages/Coordenador/FormCriacaoServidor/FormCriacaoServidor";
import CredenciamentoOSC from "./pages/Coordenador/CredenciamentoOsc/PaginaCredenciamentoOsc";
import FormCriacaoCoordenador from "./pages/Admin/FormCriacaoCoordenador/FormCriacaoCoordenador";
import MinhaAreaServidor from "./pages/Servidor/MinhaArea/MinhaAreaServidor";
import MinhaAreaAdmin from "./pages/Admin/MinhaAreaAdmin/MinhaAreaAdmin";
import TermosEAcordos from "./pages/TermosEAcordos/TermosEAcordos";
import PaginaFaq from "./pages/PaginaFaq";
import OscCredenciadas from "./pages/Osc/OscCredenciadas/OscCredenciadasLista";
import PaginaCadastroOsc from "./pages/Osc/PaginaCadastroOsc";
import AtualizarDadosUserGov from "./pages/AtualizarDadosUserGov/AtualizarDadosUserGov";
import MinhaAreaGestor from "./pages/Gestor/MinhaAreaGestor";
import LançamentoTermosAcordos from "./pages/TermosEAcordos/LançamentoTermosAcordos/LançamentoTermosAcordos";
import LançamentoChamamentoPublico from "./pages/Chamamento/LançamentoChamamentoPublico/LançamentoChamamentoPublico";
import AtualizarDadosOsc from "./pages/Gestor/AtualizarDadosOsc";
import VincularProponeteOSC from "./pages/Gestor/VincularProponeteOSC";
import UpdateTermosAcordos from "./pages/TermosEAcordos/UpdateTermosAcordos/UpdateTermosAcordos";
import PaginaDeManuntencao from  "./pages/PaginaDeManuntencao";
import Enviarimpugnacao from "./pages/Impugnacao/Enviarimpugnacao";
import ResponderImpugnacao  from "./pages/Impugnacao/ResponderImpugnacao";
import AtualizarChamamentoPublico from "./pages/Chamamento/AtualizarChamamentoPublico/AtualizarChamamentoPublico";
import PropostaDarAndamento from "./pages/Proposta/PropostaDarAndamento/PropostaDarAndamento"
import Reportar from "./pages/Reportar/Reportar";
import DarAndamentoRelatorio from "./pages/Reportar/DarAndamentoRelatorio";
import ListagemDeArquivosEAndamentos from "./pages/ListagemDeArquivosEAndamentos/ListagemDeArquivosEAndamentos";
import MinhaAreaSU from "./pages/SU/MinhaAreaSU/MinhaAreaSU";
import FormCriarAdmin from "./pages/SU/CriarAdmin/FormCriarAdmin";
import UsuarioAtivacao from "./pages/UsuarioAtivacao/UsuarioAtivacao";
import FormCriarSetor from "./pages/Admin/FormCriarSetor/FormCriarSetor";
import AtualizarSetor from "./pages/Admin/AtualizarSetor/AtualizarSetor";
import ListarArquivosChamamento from "./pages/Chamamento/ListarArquivosChamamento/ListarArquivosChamamento";
import { authActions } from "../src/redux/auth";
import { useEffect } from "react";
import {toast, Bounce} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ListagemDeImpugnacoes from "./pages/Impugnacao/ListagemDeImpugnacoes";

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = JSON.parse(sessionStorage.getItem("user"))?.isAuthenticated;

  if (!isAuthenticated) {
    toast.error("Para continuar, é necessário fazer login.", {
      position: "top-center",
      autoClose: 3500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      transition: Bounce,
    });

    return <Redirect to="/login" />;
  }

  return <Route {...rest} render={(props) => <Component {...props} />} />;

};





const App = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const savedUser = JSON.parse(sessionStorage.getItem("user"));
    if (savedUser && savedUser.isAuthenticated) {
      dispatch(authActions.signin(savedUser.user));
    }
  }, [dispatch]);


  
  return (
    <Router>
      <header>
        <Header />
      </header>
      <main>
        <Switch>
          <Route path="/" component={Home} exact />
          <Route path="/paginamodelo" component={PaginaModelo} />
          <PrivateRoute
            path="/usuario/reportar"
            component={Reportar}
          />
           <PrivateRoute
            path="/usuario/andamento/relatorio/:idrelatorio"
            component={DarAndamentoRelatorio}
          />
          
          <PrivateRoute
            path="/adm/criar/setor"
            component={FormCriarSetor}
          />

          <PrivateRoute
            path="/adm/atualizar/setor/:IdSetor"
            component={AtualizarSetor}
          />
          
          <PrivateRoute
            path="/publicacoes/lista"
            component={PublicacoesLista}
          />
          <PrivateRoute
            path="/listar/impugnacoes"
            component={ListagemDeImpugnacoes}
          />
          <PrivateRoute
            path="/chamamento/arquivos/:id/:tipo"
            component={ListarArquivosChamamento}
          />
          <PrivateRoute
            path="/enviar/impugnacao/:id"
            component={Enviarimpugnacao}
          />

          <PrivateRoute
            path="/responder/impugnacao/:id"
            component={ResponderImpugnacao} 
          />

          <Route path="/sobre" component={Sobre} />
          <Route path="/contato" component={Contato} />
          <Route path="/legislacao" component={PaginaLegislacao} />
          <Route
            path="/publicacoes/chamamentos"
            component={ChamamentosPublicos}
          />
          <Route
            path="/listar/arquivos-andamentos/:tipo/:id"
            component={ListagemDeArquivosEAndamentos}
          />

          <Route
            path="/publicacoes/interessesocial"
            component={ManifestacoesInteresseSociais} 
          />
          <Route path="/mis/consulta/publica" component={MisConsultaPublica} />
          <Route path="/login" component={Login} />
          <Route path="/gov/login" component={LoginGov} />
          <Route
            path="/interessesocial/fazerproposta"
            component={FazerPropostaMis}
          />
          <Route
            path="/link/cadastro"
            component={PaginaEnvioLinkCadastroUsuario}
          />
          <Route
            path="/novo/usuario/:token"
            component={PaginaCadastroUsuario}
          />
          <Route
            path="/ativar/usuario/gov/:token"
            component={UsuarioAtivacao}
          />
          <Route
            path="/link/redefinir/senha"
            component={PaginaEnvioLinkRedefinirSenha}
          />
          <Route
            path="/redefinir/senha/:token"
            component={RedefinirSenhaUsuario}
        />
          <Route
            path="/parcerias/termoseacordos"
            component={TermosEAcordos}
          />
          <Route path="/perguntas/frequentes" component={PaginaFaq} />
          <Route path="/oscs/credenciadas" component={OscCredenciadas} />
          <Route path="/cadastro-oscs" component={PaginaCadastroOsc} />
          <PrivateRoute path="/minha-area" exact component={MinhaArea} />
          <PrivateRoute
            path="/minha/area/coordenador"
            exact
            component={MinhaAreaCoordenador}
          />
            <PrivateRoute
            path="/minha/area/su"
            exact
            component={MinhaAreaSU}
          />
         <PrivateRoute path="/minha/area/admin" exact component={MinhaAreaAdmin} />
          <PrivateRoute  path="/novo/servidor" exact component={FormCriacaoServidor} />
          <PrivateRoute 
            path="/cadastro/coordenador"
            exact
            component={FormCriacaoCoordenador}
          />
          <PrivateRoute 
            path="/su/cadastrar/adm"
            exact
            component={FormCriarAdmin}
          />
          <PrivateRoute  path="/minha/area/gestor" exact component={MinhaAreaGestor} />
          <PrivateRoute 
            path="/upload/termos-acordos"
            exact
            component={LançamentoTermosAcordos}
          />
         <PrivateRoute 
            path="/update/termos-acordos/:id"
            exact
            component={UpdateTermosAcordos}
          />
          <PrivateRoute 
            path="/proposta/andamento/:id"
            exact
            component={PropostaDarAndamento}
          />
          <PrivateRoute 
            path="/update/chamamento/:id"
            exact
            component={AtualizarChamamentoPublico}
          />
          <PrivateRoute 
            path="/upload/chamamento"
            exact
            component={LançamentoChamamentoPublico}
          />

          <PrivateRoute
            path="/gestor/update/osc"
            exact
            component={AtualizarDadosOsc}
          />
        <PrivateRoute
            path="/gestor/vincular/proponente"
            exact
            component={VincularProponeteOSC}/>

          <PrivateRoute path="/minha-area" component={MinhaAreaLayout} />
          <PrivateRoute
            path="/minha/area/servidor"
            component={MinhaAreaServidor}/>

          <PrivateRoute
            path="/update/user/gov"
            component={AtualizarDadosUserGov}/>

         <Route
            path="/coordenador/credenciamento/osc/:idCredenciamento"
            component={CredenciamentoOSC}
          />

          <Route
            path="/osc/manuntencao"
            component={PaginaDeManuntencao}
          />
          {/**  <Route path='/sobre' component={Sobre} LançamentoTermosAcordos />*/}
          
          <Route component={NotFound} />
        </Switch>
      </main>
      <Footer />
    </Router>
  );
};

export default App;
