import { useFormik } from "formik";
import React, { useContext, useEffect, useRef } from "react";
import { Col, Container, Row, Button, Form, OverlayTrigger, Tooltip} from "react-bootstrap";
import * as yup from "yup";
import TextInput from "../../components/UI/Formik/TextInput/TextInput";
import SpinnerLabel from "../../components/UI/SpinnerLabel";
import { AppContext } from "../../context/AppContext";
import useApiClient, { apiRoutes } from "../../hooks/apiClient";
import { useHistory, useParams } from "react-router";
import SelectFileInputGeneral from "../../components/UI/SelectFileInput/SelectFileInputGeneral";
import { toast, ToastContainer, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TextareaInput from "../../components/UI/Formik/TextareaInput/TextareaInput";
import Inputmask from "inputmask";

const validationSchema = yup.object().shape({
  titulo: yup.string().max(100, 'O titulo deve ter no máximo 100 caracteres.').required("Informe o titulo para sua impugnação."),
  descricao: yup.string().max(500, 'A descrição deve ter no máximo 500 caracteres.').required("Informe uma descrição resumida da sua impugnação."),
});

const initialValues = {
  titulo: "",
  descricao: "",
  Arquivo: null,
  ArquivoNome: null,
  IdChamentoPublico: 0,
};

const Enviarimpugnacao = () => {
  const { setBackgroundClassList } = useContext(AppContext);
  const { request, data, errors, loading } = useApiClient();
  const cpfInputRef = useRef(null);
  const cnpjInputRef = useRef(null);
  const history = useHistory();
  const { id } = useParams();
  initialValues.IdChamentoPublico = id;

  useEffect(() => {
    setBackgroundClassList(["background-1"]);
  });

  const handleSubmitClean = (values) => {
    request(apiRoutes.main.impugnacao.salvarImpugnacao, values, {
      requestId: "salvar",
    });
  };

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: handleSubmitClean,
  });

  const handleArquivo = (uploadPayload) => {
    formik.setValues((curr) => ({
      ...curr,
      Arquivo: uploadPayload.arquivo,
      ArquivoNome: uploadPayload.arquivoNome,
    }));
  };

  const { setSubmitting, isSubmitting } = formik;

  useEffect(() => {
    if (data) {
      toast.success('Impugnação lançada com Sucesso!!! enviaremos a resposta via E-mail.', {
        position: "top-center",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
        onClose: () => {
          setSubmitting(false);
          history.push("/publicacoes/chamamentos");
        }
      });
    }

    if (errors) {
      toast.error(errors.message || "algo deu errado!!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
        onClose: () => {
          setSubmitting(false);
        }
      });
    }

  }, [data, errors, history]);


  useEffect(() => {
    if (formik.values.TipoPessoa === "PF" && cpfInputRef.current) {
      Inputmask({ mask: "999.999.999-99" }).mask(cpfInputRef.current);
    }
    if (formik.values.TipoPessoa === "PJ" && cnpjInputRef.current) {
      Inputmask({ mask: "99.999.999/9999-99" }).mask(cnpjInputRef.current);
    }
  }, [formik.values.TipoPessoa]);


  const readOnly = loading || formik.isSubmitting;

  return (
    <>
      <Container id="cabecalho-pagina" fluid>
        <Row>
          <Col className="d-flex flex-column text-center mt-5">
            <h1 className="text-white text-shadow mt-5">impugnação</h1>
          </Col>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
        </Row>
      </Container>

      <Container id="conteudo-pagina-interna">
        <Form>
            <Row>
              <div className="alert alert-info mt-5" role="alert">
                    <h4 className="alert-heading">Instruções</h4>
                    <p>A impugnação de um chamamento público é o ato de contestar formalmente os termos ou condições estabelecidos em um edital de seleção ou contratação pública. Ela ocorre quando um interessado, ou potencial participante, identifica irregularidades, ilegalidades ou falta de clareza no edital, solicitando sua revisão ou cancelamento. A impugnação visa garantir a transparência e a legalidade do processo, assegurando igualdade de condições para todos os participantes.</p>
                    <p> Para submeter uma  impugnação, não é necessária a anexação de arquivos. No entanto, você pode anexar um arquivo em formato PDF para fundamentar melhor sua impugnação, com o objetivo de reforçar a argumentação e convencer a Administração da importância da revisão do edital.</p>
                    <p> Lembre-se de que a resposta à sua impugnação será enviada por e-mail.</p>
              </div>
            </Row>
          <Row className="d-flex g-4 mt-5">
            <Col className="mt-5" md="12" sm="12" xs="12">
              <h2 className="my-2">Dados da impugnação</h2>
            </Col>
            <Col lg="12" md="12" sm="12" xs="12">
              <TextInput formik={formik} field="titulo" label="Titulo:" placeholder="Adicione um titulo a sua impugnação." disabled={isSubmitting} />
            </Col>

            <Col lg="12" md="12" sm="12" xs="12" className="mt-4">
              <TextareaInput
                formik={formik}
                field="descricao"
                label="Descrição da sua impugnação."
                placeholder="Descreve brevimente sua impugnação."
                disabled={isSubmitting}
                rows={5}
                
              />
            </Col>
          </Row>
          <Row>
            <Col lg="12" md="12" sm="12" xs="12">
              <h3 className="my-3">Fudamentação da sua impugnação</h3>
            </Col>
            <Col lg="12" md="12" sm="12" xs="12">
              <p>
                Nesta seção, você tem a opção de anexar um arquivo PDF para fundamentar sua impugnação de forma mais detalhada. Para anexar um arquivo clique em "Selecionar Arquivo".
              </p>
            </Col>
            <Col className="mt-2" lg="12" md="6" sm="6" xs="12">
              <SelectFileInputGeneral
                disabled={loading}
                show={!readOnly}
                showListfile={!readOnly}
                field="arquivonovo"
                handleArquivo={handleArquivo}
              />
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-center mt-5">
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip className="medium mt-1">Salvar no sistema</Tooltip>}>
                <Button
                  className="mt-3"
                  disabled={isSubmitting}
                  onClick={() => formik.handleSubmit()}
                >
                  <SpinnerLabel
                    loading={isSubmitting}
                    label="Salvar"
                  />
                </Button>
              </OverlayTrigger>
              &nbsp;&nbsp;
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip className="medium mt-1">Voltar para página anterior</Tooltip>}>
                <Button
                  className="mt-3"
                  onClick={() => history.goBack()}
                  disabled={isSubmitting}
                >
                  Voltar
                </Button>
              </OverlayTrigger>
            </Col>
          </Row>
        </ Form>
      </Container>
    </>
  )
}

export default Enviarimpugnacao;
