import React, { useEffect, useState, useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { AppContext } from "../context/AppContext";
import { AiOutlineDownload } from "react-icons/ai";

const FazerPropostaMis = () => {
  const { setBackgroundClassList } = useContext(AppContext);

  const [StatusOsc, setStatusOsc] = useState(false);

  useEffect(() => {
    setBackgroundClassList(["background-1"]);
  });

  return (
    <>
      <Container id="cabecalho-pagina" fluid>
        <Row>
          <Col className="d-flex flex-column text-center mt-5">
            <h1 className="text-white text-shadow-orange mt-5">
              Proposta para uma MIS
            </h1>
          </Col>
        </Row>
      </Container>

      {/* //Conteúdo da página  */}
      <Container id="conteudo-pagina-interna">
        <Row>
          <Col className="mt-5">
            <Form>
              <Row>
                <Col md="12" sm="12" xs="12">
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label className="me-3">
                      Selecione seu perfil:
                    </Form.Label>
                    <Form.Check
                      className="me-2"
                      inline
                      type="radio"
                      name="radio-perfil"
                      id="raio-cidadao"
                      value="cidadao"
                      label="Cidadão"
                      onClick={() => setStatusOsc(false)}
                    />
                    <Form.Check
                      className="me-2"
                      inline
                      type="radio"
                      name="radio-perfil"
                      id="raio-Osc"
                      value="Osc"
                      label="OSC"
                      onClick={() => setStatusOsc(true)}
                    />
                  </Form.Group>
                </Col>
              </Row>

              {StatusOsc && (
                <Row>
                  <Col md="12" sm="12" xs="12">
                    <h3 className="my-3">Dados Sobre a OSC </h3>
                  </Col>

                  <Col lg="3" md="3" sm="12" xs="12">
                    <Form.Group>
                      <Form.Label>CNPJ:</Form.Label>
                      <Form.Control
                        type="text"
                        name="subscritor"
                        placeholder=""
                        required
                      />
                    </Form.Group>
                  </Col>

                  <Col lg="9" md="9" sm="12" xs="12">
                    <Form.Group>
                      <Form.Label>Razão Social:</Form.Label>
                      <Form.Control
                        type="text"
                        name="subscritor"
                        placeholder=""
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>
              )}

              <Row className="d-flex g-4">
                <Col lg="12" md="12" sm="12" xs="12">
                  <h3 className="my-3">Dados da proposta</h3>
                  <Form.Group>
                    <Form.Label>Titulo da proposta:</Form.Label>
                    <Form.Control
                      type="text"
                      name="proposta"
                      placeholder="Digite o titulo da proposta"
                      required
                    />
                  </Form.Group>
                </Col>

                <Col lg="12" md="12" sm="12" xs="12">
                  <Form.Group>
                    <Form.Label>Área de atuação:</Form.Label>
                    <Form.Control as="select">
                      <option>Todos</option>
                      <option value="1">Assistência Social</option>
                      <option value="2">Acessibilidade e Inclusão</option>
                      <option value="3">Ciência e Tecnologia</option>
                      <option value="4">Cultura</option>
                      <option value="5">Desenvolvimento Econômico</option>
                      <option value="6">Direitos Humanos e Cidadania</option>
                      <option value="7">Educação</option>
                      <option value="8">Energia e Saneamento Básico</option>
                      <option value="9">Esporte e lazer</option>
                      <option value="10">Habitação</option>
                      <option value="11">
                        Meio Abiente e Sustentabilidade
                      </option>
                      <option value="12">Mobilidade e transporte</option>
                      <option value="13">Pesquisas</option>
                      <option value="14">Saúde</option>
                      <option value="15">Segurança Pública</option>
                      <option value="16">outros</option>
                    </Form.Control>
                  </Form.Group>
                </Col>

                <Col lg="6" md="6" sm="12" xs="12">
                  <Form.Group>
                    <Form.Label>Estimativa de custos</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Digite a estimativa de custo, Ex: 61000.56"
                      required
                    />
                  </Form.Group>
                </Col>

                <Col lg="6" md="6" sm="12" xs="12">
                  <Form.Group>
                    <Form.Label>Estimativa de prazo de execução</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Digite a Estimativa de prazo de execução"
                      required
                    />
                  </Form.Group>
                </Col>

                <Col lg="6" md="6" sm="12" xs="12">
                  <Form.Group>
                    <Form.Label>
                      Indicação do interesse público envolvido
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      placeholder="Descreva aqui"
                      required
                    />
                  </Form.Group>
                </Col>

                <Col lg="6" md="6" sm="12" xs="12">
                  <Form.Group>
                    <Form.Label>
                      Diagnóstico da realidade que se quer modificar, aprimorar
                      ou desenvolver
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      placeholder="Descreva aqui"
                      required
                    />
                  </Form.Group>
                </Col>

                <Col lg="6" md="6" sm="12" xs="12">
                  <Form.Group>
                    <Form.Label>Indicação da viablidade</Form.Label>
                    <Form.Control
                      as="textarea"
                      placeholder="Descreva aqui"
                      required
                    />
                  </Form.Group>
                </Col>

                <Col lg="6" md="6" sm="12" xs="12">
                  <Form.Group>
                    <Form.Label>Indicação dos beneficios</Form.Label>
                    <Form.Control
                      as="textarea"
                      placeholder="Descreva aqui"
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg="12" md="12" sm="12" xs="12">
                  <h3>Plano de trabalho </h3>
                  <p>
                    Nesta seção você deverá baixar o plano de trabalho, clicando
                    no botão "Baixar plano de trabalho", após baixar você deve
                    preencher o plano de trabalho e anexar o arquivo clicando no
                    botão "Anexar plano de trabalho", o preenchimento do plano
                    de trabalho é <strong>OBRIGATÓRIO</strong>. Você também pode
                    anexar até 5 arquivos opcionais clicando no botão "arquivos
                    opcionais"
                  </p>

                  <Button variant="link">
                    <AiOutlineDownload /> clique aqui para baixar o plano de
                    trabalho
                  </Button>
                </Col>
                <Col lg="6" md="6" sm="12" xs="12" className="mt-3">
                  <Form.Group controlId="formFileMultiple" className="mb-3">
                    <Form.Label>Anexar plano de trabalho</Form.Label>
                    <Form.Control type="file" required />
                  </Form.Group>
                </Col>
                <Col lg="6" md="6" sm="12" xs="12" className="mt-3">
                  <Form.Group controlId="formFileMultiple" className="mb-3">
                    <Form.Label>Arquivos opcionais</Form.Label>
                    <Form.Control type="file" multiple />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button type="submit" className="mt-3">
                    Enviar proposta
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default FazerPropostaMis;
