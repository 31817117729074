import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Alert,
  Spinner,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { FormatDate } from "../../../components/UI/Format/Format";
import useApiClient, { apiRoutes } from "../../../hooks/apiClient";
import TabelPagination from "../../../components/pagination/TabelPaginatio";
import SelectInputCached from "../../../components/UI/Formik/SelectInput/SelectInput.cached";

const initialValues = {
  IdStatus: 0,
}
const SalvarPesquisa = {
  IdStatus: 0,
};

const PropostaLista = () => {
  const { request, data, errors, loading, requestId } = useApiClient();
  const [showSpinner, setShowSpinner] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [totalPages, settotalPages] = useState(0);
  const [paginatedData, setPaginatedData] = useState([]);
  const [offset, setoffset] = useState(0);
  const [limparPesquisa, setlimparPesquisa] = useState(true);
  const [offsetPesquisa, setoffsetPesquisa] = useState(0);
  const itemsPerPage = 5;

  useEffect(() => {
    request(apiRoutes.main.propostas.listarDoUsuario,
      {
        routeParams: {
          limit: itemsPerPage,
          offset: offset
        }
      },
      { requestId: "carregar" }
    );
  }, [request, offset]);

  useEffect(() => {
    if (data && requestId === "carregar") {
      setTimeout(() => {
        setPaginatedData(data);
        settotalPages(data && data.length > 0 ? Math.ceil(data[0].totalitens / itemsPerPage) : 0);
        setShowSpinner(false);
      }, 700);
    }

    if (data && requestId === "pesquisar") {
      setShowSpinner(true);
      setPaginatedData(data);
      settotalPages(data && data.length > 0 ? Math.ceil(data[0].totalitens / itemsPerPage) : 0);
      setTimeout(() => {
        setShowSpinner(false);
      }, 900);
    }
  }, [requestId, data]);

  useEffect(() => {
    if (!limparPesquisa) {
      request(apiRoutes.main.propostas.pesquisarDoUsuario,
        {
          routeParams: {
            limit: itemsPerPage,
            offset: offsetPesquisa,
            idstatus: SalvarPesquisa.IdStatus,
          }
        },
        { requestId: "pesquisar" }
      );
    }
  }, [offsetPesquisa]);

  const FiltrarPesquisa = (values) => {
    setShowSpinner(true);
    setlimparPesquisa(false);
    SalvarPesquisa.IdStatus = values.IdStatus
    request(apiRoutes.main.propostas.pesquisarDoUsuario,
      {
        routeParams: {
          limit: itemsPerPage,
          offset: offsetPesquisa,
          idstatus: SalvarPesquisa.IdStatus,
        }
      },
      { requestId: "pesquisar" }
    );
  };

  const formik = useFormik({
    initialValues,
    onSubmit: FiltrarPesquisa,
  });

  const limparCampos = () => {
    setShowSpinner(true)
    formik.resetForm();
    SalvarPesquisa.IdStatus = 0;
    setlimparPesquisa(true)
    setoffsetPesquisa(0)
    setoffset(0)
    request(apiRoutes.main.propostas.listarDoUsuario,
      {
        routeParams: {
          limit: itemsPerPage,
          offset: 0
        }
      },
      { requestId: "carregar" }
    );
  };


  const handlePageChange = (pageNumber) => {
    setShowSpinner(true);
    setActivePage(pageNumber);
    const newOffset = ((pageNumber - 1) * itemsPerPage)
    if (!limparPesquisa) {
      setoffsetPesquisa(newOffset);
    } else {
      setoffset(newOffset);
    }
  };

  return (
    <>
      <Container className="conteudo-pagina">
        <Row>
          <Col>
            <h3 className="mt-4 fw-bold">Minhas Propostas</h3>
            {errors && (
              <Alert variant="danger">
                Desculpe, ocorreu um erro durante a recuperação das propostas.
                Tente novamente mais tarde.
              </Alert>
            )}
          </Col>
        </Row>
        <>
          <Row>
            <Col>
              <p>
                Aqui são listadas todas as propostas que você lançou para o poder público
              </p>
            </Col>
          </Row>
          <Row>
            <Col className="bg-light rounded p-3">
              <Form>
                <Row>
                  <Col lg="8" md="12" sm="12" xs="12">
                    <SelectInputCached
                      formik={formik}
                      field="IdStatus"
                      label="Filtrar por situação da proposta"
                      cacheKey="proposta"
                      allowEmpty
                    />
                  </Col>


                  <Col className="d-flex flex-row mt-3 justify-content-center">
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip className="medium mt-1">Pesquisar por parâmetro selecionado</Tooltip>}>

                      <Button variant="primary" className="me-3" onClick={formik.handleSubmit}>
                        Pesquisar
                      </Button>
                    </OverlayTrigger>

                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip className="medium mt-1">Limpar resultados e parâmetro da pesquisa</Tooltip>}>
                      <Button variant="link" className="ms-3" onClick={limparCampos}>
                        Limpar
                      </Button>
                    </OverlayTrigger>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>

          {showSpinner && (
            <Row>
              <Col className="d-flex flex-row mt-5 justify-content-center">
                <h5 className="mb-2">
                  <Spinner
                    animation="border"
                    size="lg"
                    variant="warning"
                  />
                </h5>
              </Col>
            </Row>
          )}
          {!errors && !loading && !showSpinner && (
            <Row>
              <Col>
                <div style={{ overflowX: 'auto' }}>
                  <Table striped bordered hover className="mt-4">
                    <thead>
                      <tr>
                        <th>Data</th>
                        <th>Título</th>
                        <th>Área</th>
                        <th>Secretaria</th>
                        <th>Situação</th>
                      </tr>
                    </thead>
                    <tbody>

                      {(paginatedData || {}) &&
                        paginatedData.map((item) => (
                          <tr
                            key={item.id_proposta}
                          >
                            <td>{FormatDate(item.tm_inclusao)}</td>
                            <td>{item.tx_titulo}</td>
                            <td>{item.areaoutros ? item.areaoutros : item.tx_area}</td>
                            <td>{item.tx_orgao}</td>
                            <td>{item.tx_status}</td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>
                {totalPages > 1 && (
                  <TabelPagination totalPages={totalPages} activePage={activePage} handlePageChange={handlePageChange} />
                )}
              </Col>
            </Row>)}
          {!showSpinner && paginatedData && paginatedData.length === 0 && (
            <Alert className="mt-0" variant="primary">Nenhum registro encontrado</Alert>
          )}
        </>
      </Container>
    </>
  );
};

export default PropostaLista;
