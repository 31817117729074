import React, { useContext, useEffect } from "react";
import { Alert, Card, Col, Container, Row, Spinner } from "react-bootstrap";
import { BsFillPersonFill } from "react-icons/bs";
import { RiLockPasswordFill } from "react-icons/ri";
import { AppContext } from "../context/AppContext";
import { useFormik } from "formik";
import * as yup from "yup";
import TextInput from "../components/UI/TextInput";
import { useHistory } from "react-router";
import { TOKEN_KEY } from "../redux/auth";
import { useDispatch } from "react-redux";
import { authActions } from "../redux/auth";
import { Link } from "react-router-dom";
import appConfig from "../config/app.config";  

const validationSchema = yup.object().shape({
  email: yup.string().required("Informe o email de seu usuário "),










  
  senha: yup.string().required("Informe a senha de seu usuário "),
});

const initialValues = {
  email: "",
  senha: "",
};

const Login = () => {
  const { setBackgroundClassList } = useContext(AppContext);
  const history = useHistory();
  const dispatch = useDispatch();

 
  useEffect(() => {
    setBackgroundClassList(["background-1", "bg-cover"]);
  });

 

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: async (values, actions) => {
      actions.setStatus(null);
      var credenciais = `${values.email}:${values.senha}`;
      var response = await fetch(`${appConfig.host}/api/auth/signin`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${btoa(credenciais)}`,
        },
      });
     
      console.log(response.data);
      if (response.status === 401)
        actions.setStatus(
          "Desculpe, acesso negado. Verifique suas credenciais. Se você for um funcionário público, faça login através da opção 'Sou Servidor'"
        );
      else if (response.status === 200 ) {        
        var result = await response.json();
        localStorage.setItem(TOKEN_KEY, result.accessToken);
        dispatch(authActions.signin(result));

        if(result.papeis[0] === "PROPONENTE"){
          history.push("/minha-area");
        }
      } else {
        actions.setStatus("Desculpe, ocorreu um erro na requisição. ");
      }
    },
  });

  return (
    <Container>
      <Row>
        <Col className="d-flex flex-column">
          <Row className="g-4 g-lg-4">
            <Col lg="4" md="4" sm="6" className="ms-auto me-auto">
              <Card className="card-login">
                <Card.Header>
                  <h1 className="fs-3 text-center w-100">Bem-vindo (a)!</h1>
                </Card.Header>
                <Card.Body>
                  {formik.status && (
                    <Alert variant="danger">{formik.status}</Alert>
                  )}
                  <form
                    className="register-form"
                    onSubmit={formik.handleSubmit}
                  >
                    <label>Login</label>
                    <div className="input-group form-group-no-border">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <BsFillPersonFill />
                        </span>
                      </div>
                      <TextInput
                        formik={formik}
                        field="email"
                        placeholder="E-mail"
                      />
                    </div>
                    <label>Senha</label>
                    <div className="input-group form-group-no-border">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <RiLockPasswordFill />
                        </span>
                      </div>
                      <TextInput
                        formik={formik}
                        field="senha"
                        type="password"
                        placeholder="Senha de seu usuário"
                      />
                    </div>
                    <div className="d-grid gap-4 mt-4">
                      <button
                        className="btn btn-primary btn-block btn-round rounded-pill"
                        type="submit"
                      >
                        {formik.isSubmitting ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          "Entrar"
                        )}
                      </button>
                      <Link to="/link/cadastro" className="btn btn-light  btn-block btn-round rounded-pill">
                        Cidadão, cadastre-se aqui
                      </Link></div>
                  </form>
                  <div className="d-grid gap-4 mt-4">
                  
                    <Link to="/link/redefinir/senha" className="btn btn-sm btn-link btn-round rounded-pill border-0">
                       Esqueceu sua senha?
                    </Link>
                  </div>

                  <div className="d-grid gap-4 mt-4">

                    <Link to="/gov/login" className="btn btn-sm btn-link btn-round rounded-pill border-0">
                       Sou servidor
                    </Link>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
