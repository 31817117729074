import { Form } from "react-bootstrap";

const TextareaInput = (props) => {
  const { formik, field, label, placeholder, rows, readOnly, disabled } = props;
  return (
    <Form.Group>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        id={field}
        name={field}
        as="textarea"
        rows={rows}
        placeholder={placeholder}
        value={formik.values[field]}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        isInvalid={!!formik.errors[field] && formik.touched[field]}
        readOnly={readOnly}
        disabled={disabled}
      />
      <Form.Control.Feedback type="invalid">
        {formik.errors[field]}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default TextareaInput;
