import { useFormik } from "formik";
import React, { useContext, useEffect, useRef } from "react";
import { Col, Container, Row, Alert, Button } from "react-bootstrap";
import * as yup from "yup";
import SelectInput from "../../../components/UI/Formik/SelectInput/SelectInput";
import { MunicipiosAsOptions } from "../../../components/UI/Formik/SelectInput/SelectInput.options"
import TextInput from "../../../components/UI/Formik/TextInput/TextInput";
import { validCnpj } from "../../../components/UI/Formik/Validations/Validations";
import SpinnerLabel from "../../../components/UI/SpinnerLabel";
import { AppContext } from "../../../context/AppContext";
import useApiClient, { apiRoutes } from "../../../hooks/apiClient";
import { useHistory, useParams } from "react-router-dom";
import TextareaInput from "../../../components/UI/Formik/TextareaInput/TextareaInput";



const validationSchema = yup.object().shape({
    tx_cnpj: yup.string().when("tx_tipo", {
      is: "osc",
      then: yup.string().matches("[0-9]{14}", "Somente números. 14 dígitos.").required("Informe o CNPJ da OSC").test(validCnpj),
      otherwise: yup.string().nullable().strip(),
    }),
    tx_razao_social: yup.string().when("tx_tipo", {
      is: "osc",
      then: yup.string().required("Informe a Razão Social da OSC"),
      otherwise: yup.string().nullable().strip(),
    }),
    tx_logradouro: yup.string().when("tx_tipo", {
      is: "osc",
      then: yup.string().required("Informe o logradouro da OSC"),
      otherwise: yup.string().nullable().strip(),
    }),
    tx_numero: yup.string().when("tx_tipo", {
      is: "osc",
      then: yup.string().required("Informe o número do logradouro da OSC"),
      otherwise: yup.string().nullable().strip(),
    }),
    tx_cidade: yup.string().when("tx_tipo", {
      is: "osc",
      then: yup.string().required("Selecione a Cidade do endereço da OSC"),
      otherwise: yup.string().nullable().strip(),
    }),
    tx_bairro: yup.string().when("tx_tipo", {
      is: "osc",
      then: yup.string().required("Selecione o Bairro do endereço da OSC"),
      otherwise: yup.string().nullable().strip(),
    }),
    tx_cep: yup.string().when("tx_tipo", {
      is: "osc",
      then: yup.string().matches("[0-9]{8}", "Somente números. 8 dígitos.").required("Informe o CEP do endereço da OSC"),
      otherwise: yup.string().nullable().strip(),
    }),
  });

  const initialValues = {
  bairroosc: "",
  ceposc: "",
  cidadeosc: "",
  cnpjosc: "",
  complementoosc: "",
  emailosc: "",
  logradouroosc: "",
  numeroosc: "",
  razaosocialosc: "",
  status: "",
  tel1osc: "",
  tel2osc: "",
  tx_bairro:"",
  tx_cep: "",
  tx_cidade: "",
  tx_complemento: "",
  tx_cpf: "",
  tx_login: "",
  tx_logradouro:"",
  tx_nome: "",
  tx_numero:"",
  tx_tel1: "",
  tx_tel2:"",
  tx_uf: "",
  ufosc: "",
  statusAndamento:"",
  justificativaAndamento:"",
  };





 

const PaginaCredenciamentoOsc = () => {
    const { setBackgroundClassList } = useContext(AppContext);
    const { request, data, errors, loading, requestId, requestExtra } = useApiClient();
    const numeroInputRef = useRef(null);
    const history = useHistory();
    const {idCredenciamento} = useParams();
  

    

    useEffect(() => {
        setBackgroundClassList(["background-1"]);
      });

      const formik = useFormik({
        validationSchema,
        initialValues,
        onSubmit: (values, actions) => {
          

          
          let payload ={
            IdCredenciamento: idCredenciamento,
            IdStatusNovo: values.statusAndamento,
            Justificativa: values.justificativaAndamento,
          };

          actions.setStatus(null);
          request(apiRoutes.main.credenciamento.AndamentoCredenciamento, payload, {
            requestId: "salvar",
          });
         
        },
      });


    useEffect(() => {
        request(
            apiRoutes.main.credenciamento.CarregarCredenciamentoPorId,
            {
              routeParams:  {idCredenciamento},
            },
            {
              requestId: "carregar"
            }
          );

      }, [request, idCredenciamento]);


      const { setValues, setSubmitting, setStatus} = formik;

  
      
      useEffect(() => {
        if (data && requestId === "carregar") setValues(data[0]);
        if (requestId === "salvar") {
          setSubmitting(false);
          if (data) {
            alert("Andamento feito com sucesso!!!!");
            history.goBack();
          }
          if (errors) {
            alert(
              errors.message ||
                "Desculpe, algo deu errado. Tente novamente mais tarde."
            );
          }
        }
      }, [
        data,
        errors,
        requestId,
        requestExtra,
        history,
        setValues,
        setSubmitting,
        setStatus,
      ]);


     // const readOnly = loading || formik.isSubmitting;

     const readOnly = loading || !!((data || {}).fg_somente_leitura === "S");

    return(
        <>
        <Container id="cabecalho-pagina" fluid>
            <Row>
            <Col className="d-flex flex-column text-center mt-5">
                <h1 className="text-white text-shadow mt-5">Pedido de Credeciamento da Organização da Sociedade Civil</h1>
            </Col>
            </Row>
        </Container>

        <Container id="conteudo-pagina-interna">

        <Row className="d-flex g-4">
                <Col lg="12" md="12" sm="12" xs="12">
                  <h2 className="my-3">Dados Pessoais</h2>
                </Col>

                {formik.status && (
                  <Alert variant="danger">{formik.status}</Alert>
                )}

                <Col lg="6" md="6" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    field="tx_nome"
                    label="Nome:"
                    readOnly={readOnly}
                  />
                </Col>

                <Col lg="6" md="6" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    field="tx_cpf"
                    label="CPF:"
                    readOnly={readOnly}
                    maxLength={11}
                  />
                </Col>

                <Col lg="6" md="6" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    field="tx_tel1"
                    label="Telefone Principal:"
                    readOnly={readOnly}
                    maxLength={11}
                  />
                </Col>

                <Col lg="6" md="6" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    field="tx_tel2"
                    label="Telefone Secundário:"
                    readOnly={readOnly}
                    maxLength={11}
                  />
                </Col>

                <Col lg="5" md="5" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    field="tx_cep"
                    label="CEP:"
                    readOnly={readOnly}
                    maxLength={8}
                  />
                </Col>
                

                <Col lg="5" md="5" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    field="tx_logradouro"
                    label="Logradouro:"
                    maxLength={250}
                    readOnly={readOnly}
                  />
                </Col>

                <Col lg="2" md="2" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    ref={numeroInputRef}
                    field="tx_numero"
                    label="Número:"
                    readOnly={readOnly}
                  />
                </Col>

                <Col lg="12" md="12" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    field="tx_complemento"
                    label="Complemento:"
                    readOnly={readOnly}
                  />
                </Col>

                <Col lg="6" md="6" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    field="tx_bairro"
                    label="Bairro:"
                    readOnly={readOnly}
                  />
                </Col>

                <Col lg="6" md="6" sm="12" xs="12">
                  <SelectInput
                    formik={formik}
                    field="tx_cidade"
                    label="Cidade:"
                    readOnly={readOnly}
                  >
                    <MunicipiosAsOptions />
                  </SelectInput>
                </Col>
              </Row>



            <Row className="d-flex g-4">
              <Col md="12" sm="12" xs="12">
                <h2 className="my-3">Dados da OSC</h2>
              </Col>
                <Col lg="3" md="3" sm="12" xs="12">
                  <TextInput formik={formik} field="cnpjosc" label="CNPJ:" readOnly={readOnly} maxLength={14} />
                </Col>

                <Col lg="9" md="9" sm="12" xs="12">
                  <TextInput formik={formik} field="razaosocialosc" label="Razão Social:" readOnly={readOnly} />
                </Col>

                <Col lg="5" md="5" sm="11" xs="11">
                  <TextInput formik={formik} field="ceposc" label="CEP:" readOnly={readOnly} maxLength={8} />
                </Col>

                 

                <Col lg="5" md="5" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    field="logradouroosc"
                    label="Logradouro:"
                    maxLength={120}
                    readOnly={readOnly}
                  />
                </Col>

                <Col lg="2" md="2" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    ref={numeroInputRef}
                    field="numeroosc"
                    label="Número:"
                    readOnly={readOnly}
                  />
                </Col>

                <Col lg="12" md="12" sm="12" xs="12">
                  <TextInput formik={formik} field="complementoosc" label="Complemento:" readOnly={readOnly} />
                </Col>

                <Col lg="6" md="6" sm="12" xs="12">
                  <TextInput formik={formik} field="bairroosc" label="Bairro:" readOnly={readOnly} />
                </Col>

                <Col lg="6" md="6" sm="12" xs="12">
                  <SelectInput formik={formik} field="cidadeosc" label="Cidade:" readOnly={readOnly}>
                      <MunicipiosAsOptions />
                  </SelectInput>
                </Col>

                <Col lg="6" md="6" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    field="tel1osc"
                    label="Telefone Principal:"
                    readOnly={readOnly}
                    maxLength={11}
                  />
                </Col>

                <Col lg="6" md="6" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    field="tel2osc"
                    label="Telefone Secundário:"
                    readOnly={readOnly}
                    maxLength={11}
                  />
                </Col>

                <Col lg="12" md="12" sm="12" xs="12">
                  <TextInput formik={formik} field="emailosc" label="E-mail" readOnly={readOnly} />
                </Col>
                
                <Row>
                  <Col lg="12" md="12" sm="12" xs="12" className="mt-5">
                  <SelectInput
                    formik={formik}
                    field="statusAndamento"
                      label="Andamento:"
                              >
                       <option value="0">Selecione um status</option>
                      <option value="2">Aceitar</option>
                      <option value="3">Rejeitar</option>
                  

                    </SelectInput>
                      </Col>
                  </Row>
                    
            
                  <Row>
                    <Col lg="12" md="12" sm="12" xs="12" className="mt-4">
                      <TextareaInput
                        formik={formik}
                        field="justificativaAndamento"
                        label="Justificativa do andamento:"
                      />
                    </Col>
                  </Row>


                  <Row>
                <Col className="d-flex justify-content-center mt-5">
                    <Button
                      className="mt-3"
                      disabled={readOnly}
                      onClick={formik.handleSubmit}
                    >
                      <SpinnerLabel
                        loading={formik.isSubmitting}
                        label="Salvar"
                      />
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                      className="mt-3"
                      onClick={() => history.goBack()}
                    >
                      Voltar
                    </Button>
                  </Col>
                </Row>
            
            </Row>
        </Container>
    </>
    )
}

export default  PaginaCredenciamentoOsc;
